// SOCIETE
const SET_SOCIETY = "app/procedure/SET_SOCIETY"
const SET_PROCEDURES = "app/procedure/SET_PROCEDURES"
const SET_ARCHIVED_PROCEDURES = "app/procedure/SET_ARCHIVED_PROCEDURES"
const SET_USED_PROCEDURES = "app/procedures/SET_USED_PROCEDURES"
const SET_RECENTLY_UPDATED_PROCEDURES =
	"app/procedures/SET_RECENTLY_UPDATED_PROCEDURES"
const ADD_PROCEDURE = "app/procedure/ADD_PROCEDURE"
const ADD_ARCHIVED_PROCEDURE = "app/procedure/ADD_ARCHIVED_PROCEDURE"
const ADD_USED_PROCEDURE = "app/procedure/ADD_USED_PROCEDURE"
const ADD_RECENTLY_UPDATED_PROCEDURE =
	"app/procedure/ADD_RECENTLY_UPDATED_PROCEDURE"
const UPDATE_PROCEDURE = "app/procedure/UPDATE_PROCEDURE"
const REMOVE_PROCEDURE = "app/procedure/REMOVE_PROCEDURE"
const REMOVE_ARCHIVED_PROCEDURE = "app/procedure/REMOVE_ARCHIVED_PROCEDURE"
const REMOVE_USED_PROCEDURE = "app/procedure/REMOVE_USED_PROCEDURE"
const REMOVE_RECENTLY_UPDATED_PROCEDURE =
	"app/procedure/REMOVE_RECENTLY_UPDATED_PROCEDURE"
const SET_CURRENT_YEAR_SIGNATURES = 'app/procedure/SET_CURRENT_YEAR_SIGNATURES';

// PROCEDURE
const OPEN_PROCEDURE_DIALOG = "app/procedure/OPEN_PROCEDURE_DIALOG"
const CLOSE_PROCEDURE_DIALOG = "app/procedure/CLOSE_PROCEDURE_DIALOG"
const SET_DIALOG_MODE = "app/procedure/SET_DIALOG_MODE"
const SET_SELECTED_PROCEDURE = "app/procedure/SET_SELECTED_PROCEDURE"
const SET_PROCEDURE_DOCUMENTS = "app/procedure/SET_PROCEDURE_DOCUMENTS"

// DEPOT DE FICHIER
const OPEN_UPLOAD_DOCUMENT_DIALOG = "app/file/OPEN_UPLOAD_DOCUMENT_DIALOG"
const CLOSE_UPLOAD_DOCUMENT_DIALOG = "app/file/CLOSE_UPLOAD_DOCUMENT_DIALOG"

export default {
	SET_SOCIETY,
	SET_PROCEDURES,
	SET_ARCHIVED_PROCEDURES,
	SET_USED_PROCEDURES,
	SET_RECENTLY_UPDATED_PROCEDURES,
	ADD_PROCEDURE,
	ADD_ARCHIVED_PROCEDURE,
	ADD_USED_PROCEDURE,
	ADD_RECENTLY_UPDATED_PROCEDURE,
	UPDATE_PROCEDURE,
	REMOVE_PROCEDURE,
	REMOVE_ARCHIVED_PROCEDURE,
	REMOVE_USED_PROCEDURE,
	REMOVE_RECENTLY_UPDATED_PROCEDURE,
	SET_CURRENT_YEAR_SIGNATURES,

	OPEN_PROCEDURE_DIALOG,
	CLOSE_PROCEDURE_DIALOG,
	SET_DIALOG_MODE,
	SET_SELECTED_PROCEDURE,
	SET_PROCEDURE_DOCUMENTS,

	OPEN_UPLOAD_DOCUMENT_DIALOG,
	CLOSE_UPLOAD_DOCUMENT_DIALOG,
};
