import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { styled, Box, Button, Typography, alpha } from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { contactSelectors, contactOperations } from '../../../../modules/contact';
import { procedureSelectors } from '../../../../modules/procedure';
import { generalSelectors } from '../../../../modules/general';

import PersonsList from './signatoriesContent/PersonsList';

const ContentWrapper = styled('div')(({ theme: { spacing } }) => ({
	flex: 1,
	width: '100%',

	display: 'flex',
	flexDirection: 'column',
	gap: spacing(2),
}));

const Wrapper = styled('div')({
	flex: 1,
	width: '100%',

	display: 'flex',

	'& > div': {
		flex: 1,
	},
});

const InfoContainer = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: spacing(1),
	backgroundColor: alpha(palette.info.main, 0.2),
	borderRadius: shape.borderRadius,
	padding: spacing(2),
}));

const StyledKeyboardArrowRight = styled(KeyboardArrowRightIcon)({
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-50%)',

	'& *': {
		color: 'rgba(0, 0, 0, .87)',
	},

	fontSize: 60,
});

const SignatoriesContent = ({ procedure, editProcedure }) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const socket = useSelector(state => generalSelectors.getSocket(state.general));
	const society = useSelector(state => procedureSelectors.getSociety(state.procedure));
	const contacts = useSelector(state => contactSelectors.getContacts(state.contact));

	const removeSignatory = ({ currentTarget }) => {
		const selectedPersonId = +currentTarget.getAttribute('name');

		const blobs = procedure.documents.map(({ blob }) => blob);
		const tmpProcedure = { ...procedure };

		// Retrait des signatures de documents correspondants au signataires à retirer
		tmpProcedure.documents.forEach(document =>
			document.signatures.forEach(
				({ id_contact, index }) => id_contact === selectedPersonId && document.signatures.splice(index, 1)
			)
		);
		// Retrait du signataire
		tmpProcedure.signataires.splice(
			tmpProcedure.signataires.findIndex(({ id }) => id === selectedPersonId),
			1
		);

		const signatoriesData = tmpProcedure.signataires_data;

		tmpProcedure.signataires_data = signatoriesData?.splice(
			signatoriesData.findIndex(({ id_contact }) => id_contact === selectedPersonId),
			1
		);

		tmpProcedure.documents.forEach((document, index) => (document.blob = blobs[index]));

		editProcedure('', tmpProcedure);
	};

	const addSignatory = ({ currentTarget }) => {
		const selectedPersonId = +currentTarget.getAttribute('name');

		editProcedure('signataires', [...procedure.signataires, contacts.find(({ id }) => id === selectedPersonId)]);
	};

	const handleContactCreation = () => {
		dispatch(
			contactOperations.openContactDialog({
				contact: {
					nom: '',
					prenom: '',
					prive: '0',
					email: '',
					telephone: '',
					n_dossier: society.id,
				},
				mode: 'create',
				creationMode: 'procedure',
			})
		);
	};

	// Récupération des contacts liés au dossier
	useEffect(() => {
		socket?.authenticated
			? dispatch(contactOperations.fetchContacts([society]))
			: enqueueSnackbar('Problème de connexion au serveur', {
					variant: 'error',
			  });
	}, [dispatch, enqueueSnackbar, socket, society]);

	return (
		<ContentWrapper>
			{procedure.ordered && (
				<InfoContainer>
					<InfoIcon />
					<Typography>Merci de renseigner les signataires dans leur ordre de signature</Typography>
				</InfoContainer>
			)}

			<Wrapper>
				<PersonsList
					title="Contacts"
					persons={contacts.filter(
						contact =>
							!!procedure.signataires &&
							!procedure.signataires.some(signatory => +signatory?.id === +contact.id) &&
							contact.n_dossier === society.id
					)}
					rowClickAction={addSignatory}
				/>
				<Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
					<Button variant="contained" color="primary" onClick={handleContactCreation}>
						Créer un contact
					</Button>
					<StyledKeyboardArrowRight />
				</Box>
				<PersonsList title="Signataires" persons={procedure.signataires} rowClickAction={removeSignatory} />
			</Wrapper>
		</ContentWrapper>
	);
};

export default SignatoriesContent;
