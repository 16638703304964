/**
 * @author	Romain Garcia
 * @version	2020/08/13
 * @see		"./types"
 */

import produce from "immer"
import { combineReducers } from "redux"

import types from "./types"

const convertStringArrayToCamelCase = ({ stringArray }) => {
	let result = ""

	stringArray.forEach(part => {
		if (result.length === 0) {
			result += part.toLowerCase()
		} else {
			result += part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
		}
	})

	return result
}

// CONNEXION
const connexionInitialState = {
	keycloakInstance: undefined,
	accessToken: sessionStorage.getItem("accessToken") || "",
	refreshToken: sessionStorage.getItem("refreshToken") || ""
}

const connexionReducer = (
	state = { ...connexionInitialState },
	{ type, payload } = {}
) =>
	type === types.SET_KEYCLOAK_INSTANCE
		? produce(state, draft => {
				draft.keycloakInstance = payload
		  })
		: state

// UTILISATEUR
const userInitialState = {
	lastname: "",
	firstname: "",
	societies: []
}

const userReducer = (
	state = { ...userInitialState },
	{ type, payload } = {}
) => {
	const finalType = type.split("/")[2]

	if (!Object.prototype.hasOwnProperty.call(types, finalType)) {
		return state
	}

	const typeParts = finalType.split("_")
	typeParts.shift()

	const convertedType = convertStringArrayToCamelCase({
		stringArray: typeParts
	})

	return produce(state, draft => {
		if (JSON.stringify(draft[convertedType]) !== JSON.stringify(payload)) {
			draft[convertedType] = payload
		}
	})
}

export default combineReducers({
	connexion: connexionReducer,
	user: userReducer
})
