/**
 * @author 	Romain Garcia
 * @version 2020/09/02
 * @see		"./types"
 */

import types from "./types"

// SOCIETE
/**
 * Inscrit la société dans le Store
 *
 * @param {object} 	society => société à inscrire
 */
const setSociety = society => ({
	type: types.SET_SOCIETY,
	payload: society
})
/**
 * Inscrit les procédures dans le Store
 *
 * @param {array}	procedures => liste des procédures à inscrire
 */
const setProcedures = procedures => ({
	type: types.SET_PROCEDURES,
	payload: procedures
})
/**
 * Inscrit les procédures archivées dans le Store
 *
 * @param {array}	procedures => liste des procédures à inscrire
 */
const setArchivedProcedures = procedures => ({
	type: types.SET_ARCHIVED_PROCEDURES,
	payload: procedures
})
/**
 * Inscrit les procédures en cours d'utilisation dans le Store
 *
 * @param {array}	procedures => liste des procédures en cours d'utilisation
 */
const setUsedProcedures = procedures => ({
	type: types.SET_USED_PROCEDURES,
	payload: procedures
})
/**
 * Ajoute une procédure à la liste des procédures du Store
 *
 * @param {object}	procedure => procédure à ajouter
 */
const addProcedure = procedure => ({
	type: types.ADD_PROCEDURE,
	payload: procedure
})
/**
 * Ajoute une procédure à la liste des procédures archivées du Store
 *
 * @param {object}	procedure => procédure à ajouter
 */
const addArchivedProcedure = procedure => ({
	type: types.ADD_ARCHIVED_PROCEDURE,
	payload: procedure
})
/**
 * Ajoute une procédure à la liste des procédures en cours d'utilisation du Store
 *
 * @param {object}	procedureId => identifiant de la procédure à ajouter
 */
const addUsedProcedure = procedureId => ({
	type: types.ADD_USED_PROCEDURE,
	payload: procedureId
})
/**
 * Ajoute une procédure à la liste des procédures récemment mises à jour
 *
 * @param {string} procedureId => identifiant de la procédure à ajouter
 */
const addRecentlyUpdatedProcedure = procedureId => ({
	type: types.ADD_RECENTLY_UPDATED_PROCEDURE,
	payload: procedureId
})
/**
 * Met à jour la procédure stockée dans le Store
 *
 * @param {object} procedure => nouvelle version de la procédure à mettre à jour
 */
const updateProcedure = procedure => ({
	type: types.UPDATE_PROCEDURE,
	payload: procedure
})
/**
 * Supprime une procédure de la liste des procédures du Store
 *
 * @param {string}	procedureId => identifiant de la procédure à supprimer
 */
const removeProcedure = procedureId => ({
	type: types.REMOVE_PROCEDURE,
	payload: procedureId
})
/**
 * Supprime une procédure de la liste des procédures archivées du Store
 *
 * @param {string}	procedureId => identifiant de la procédure à supprimer
 */
const removeArchivedProcedure = procedureId => ({
	type: types.REMOVE_ARCHIVED_PROCEDURE,
	payload: procedureId
})
/**
 * Supprime une procédure de la liste des procédures en cours d'utilisation du Store
 *
 * @param {string}	procedureId => identifiant de la procédure à supprimer
 */
const removeUsedProcedure = procedureId => ({
	type: types.REMOVE_USED_PROCEDURE,
	payload: procedureId
})
/**
 * Supprimer une procédure de la liste des procédures récemment mises à jour
 *
 * @param {string} procedureId => identifiant de la procédure à supprimer
 */
const removeRecentlyUpdatedProcedure = procedureId => ({
	type: types.REMOVE_RECENTLY_UPDATED_PROCEDURE,
	payload: procedureId
})
/**
 * Inscrit le nombre de signature du dossier dans le Store
 * 
 * @param {number} currentYearSignatures => nombre de signature du dossier sur l'année en cours
 */
const setCurrentYearSignatures = currentYearSignatures => ({
	type: types.SET_CURRENT_YEAR_SIGNATURES,
	payload: currentYearSignatures
})

// PROCEDURE
/**
 * Ouvre la dialog de visualisation/modification de procédure
 *
 * @param {object}	props =>
 * 						{object}	procedure : procédure à visualiser/modifier
 * 						{string}	mode : mode de la dialog ("create", "edit", "view")
 */
const openProcedureDialog = props => ({
	type: types.OPEN_PROCEDURE_DIALOG,
	payload: { ...props }
})
/**
 * Ferme la dialog de visualisation/modification de procédure
 */
const closeProcedureDialog = () => ({
	type: types.CLOSE_PROCEDURE_DIALOG
})
/**
 * Applique un mode à la dialog de visualisation/modification de contact
 *
 * @param {string}	mode => mode à appliquer ("create", "edit", "view")
 */
const setDialogMode = mode => ({
	type: types.SET_DIALOG_MODE,
	payload: mode
})
/**
 * Applique la procédure passée en paramètre à la procédure affichée dans la dialog
 *
 * @param {object}	procedure => procédure à afficher
 */
const setSelectedProcedure = procedure => ({
	type: types.SET_SELECTED_PROCEDURE,
	payload: procedure
})
/**
 * Applique une liste de documents à la procédure actuelle
 *
 * @param {array}	documents => liste des nouveaux documents
 */
const setProcedureDocuments = documents => ({
	type: types.SET_PROCEDURE_DOCUMENTS,
	payload: documents
})

// DEPOT DE FICHIER
/**
 * Ouvre la dialog de dépôt de document
 */
const openUploadDocumentDialog = () => ({
	type: types.OPEN_UPLOAD_DOCUMENT_DIALOG
})
/**
 * Ferme la dialog de dépot de document
 */
const closeUploadDocumentDialog = () => ({
	type: types.CLOSE_UPLOAD_DOCUMENT_DIALOG
})

export default {
	setSociety,
	setProcedures,
	setArchivedProcedures,
	setUsedProcedures,
	addProcedure,
	addArchivedProcedure,
	addUsedProcedure,
	addRecentlyUpdatedProcedure,
	updateProcedure,
	removeProcedure,
	removeArchivedProcedure,
	removeUsedProcedure,
	removeRecentlyUpdatedProcedure,
	setCurrentYearSignatures,

	openProcedureDialog,
	closeProcedureDialog,
	setDialogMode,
	setSelectedProcedure,
	setProcedureDocuments,

	openUploadDocumentDialog,
	closeUploadDocumentDialog,
};
