import React, { Fragment } from "react";

import { Typography, styled } from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";
import BusinessIcon from "@material-ui/icons/Business";

const InfoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 1),

  "& > svg": {
    fill: theme.palette.type === "light" ? theme.palette.grey[700] : "#fff",
    marginRight: theme.spacing(5),
  },
}));

const ViewContent = ({ contact }) => {
  return (
    <Fragment>
      <InfoWrapper>
        <EmailIcon />

        <Typography>{contact.email}</Typography>
      </InfoWrapper>
      <InfoWrapper>
        <CallIcon />

        <Typography>{contact.telephone.replace(/(.{2})/g, "$1 ")}</Typography>
      </InfoWrapper>
      <InfoWrapper>
        <BusinessIcon />

        <Typography>{contact.n_dossier}</Typography>
      </InfoWrapper>
    </Fragment>
  );
};

export default ViewContent;
