import { StylesProvider } from '@material-ui/core';
import Keycloak from 'keycloak-js';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import store from './store';

import './utils/style/common.css';

const instanciateKeycloak = ({ url, realm, clientId }) => {
	const keycloak = new Keycloak({ url, realm, clientId });

	keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false }).then(authenticated => {
		!authenticated && keycloak.login();

		const { AppContainer } = process.env.NODE_ENV === 'development' ? require('react-hot-loader') : {};

		const renderContent = () => (
			<BrowserRouter>
				<StylesProvider injectFirst>
					<Provider store={store}>
						<SnackbarProvider maxSnack={10} preventDuplicate>
							<App keycloak={keycloak} />
						</SnackbarProvider>
					</Provider>
				</StylesProvider>
			</BrowserRouter>
		);

		const render = () => {
			ReactDOM.render(
				process.env.NODE_ENV === 'development' ? (
					<AppContainer>{renderContent()}</AppContainer>
				) : (
					renderContent()
				),
				document.getElementById('root')
			);
		};

		render();

		module.hot && module.hot.accept('./App', render);
	});
};

fetch('/config.json')
	.then(res => res.json())
	.then(({ keycloak }) => instanciateKeycloak({ ...keycloak }));
