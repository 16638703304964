import React, { Fragment, useState, useCallback } from "react";

import {
	Box,
	styled,
	Table,
	TableFooter,
	TableRow,
	TableCell,
	Button,
	IconButton,
	Paper,
	Tooltip,
	Checkbox,
	Typography,
	Avatar,
} from "@material-ui/core";

import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from "@material-ui/icons/Delete";

import VirtualizedTable from "../../../../template/components/VirtualizedTable";
import fileHandler from "../../../../utils/fileHandler";
import UploadDocumentDialog from "./UploadDocumentDialog";
import { useDispatch } from "react-redux";
import { procedureOperations } from "../../../../modules/procedure";
import { AvatarGroup } from "@material-ui/lab";
import { generalOperations } from "../../../../modules/general";
import { useSnackbar } from "notistack";
import PlaceSignaturesDialog from "./placeSignaturesDialog/PlaceSignaturesDialog";
import DrawPenIcon from "../../../../assets/svg/DrawPenIcon";

const Container = styled(Paper)({
	width: "100%",
});

const CenteredColumn = styled("div")({
	paddingLeft: "0 !important",
	justifyContent: "center",
});

const ActionButton = styled(IconButton)(({ theme }) => ({
	padding: theme.spacing(1),
	margin: theme.spacing(0.25),

	"& svg": {
		fill: "#fff",
		fontSize: "18px",
	},

	"&.Mui-disabled": {
		backgroundColor: `${theme.palette.grey[200]} !important`,
	},
}));

const OrderButton = styled(ActionButton)(({ theme }) => ({
	backgroundColor: theme.palette.grey[400],

	"&:hover": {
		backgroundColor: theme.palette.grey[700],
	},

	"&.Mui-disabled": {
		backgroundColor:
			theme.palette.type === "light"
				? "rgba(0, 0, 0, .12) !important"
				: "rgba(255, 255, 255, .12) !important",
	},
}));

const NameCell = styled("div")(({ theme }) => ({
	position: "relative",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	maxWidth: 304.95,

	"& > p": {
		overflowX: "hidden",
		textOverflow: "ellipsis",
	},

	"& > div": {
		width: "100%",

		"& > div": {
			"& > input": {
				padding: theme.spacing(1),
				fontSize: "11pt",
			},

			"& > div > button": {
				"& svg": {
					fill: theme.palette.grey[300],
				},

				"&:hover svg": {
					fill: theme.palette.grey[500],
				},
			},
		},
	},

	"&:hover > button": {
		opacity: 1,
	},
}));

const SignIconButton = styled(ActionButton)(({ theme: { palette } }) => ({
	backgroundColor: palette.primary.main,

	"&:hover": {
		backgroundColor: palette.primary.dark,
	},
}));

const DeleteIconButton = styled(ActionButton)({
	backgroundColor: "#F44336",

	"&:hover": {
		backgroundColor: "#D32F2F",
	},
});

const DeleteButton = styled(Button)(({ theme }) => ({
	backgroundColor: "#F44336",
	color: theme.palette.getContrastText("#F44336"),

	"&:hover": {
		backgroundColor: "#D32F2F",
	},
}));

const TableActions = styled(TableCell)(({ theme }) => ({
	"& > button:first-child": {
		marginRight: theme.spacing(1),
	},
}));

const TableFooterContainer = styled(Table)(({ theme }) => ({
	position: "sticky",
	left: 0,
	right: 0,

	borderBottomLeftRadius: theme.shape.borderRadius,
	borderBottomRightRadius: theme.shape.borderRadius,

	backgroundColor: theme.palette.background.paper,
	boxShadow:
		"rgb(0 0 0 / 20%) 0px -2px 1px -1px, rgb(0 0 0 / 14%) 0px -1px 1px 0px, rgb(0 0 0 / 12%) 0px -1px 3px 0px",

	padding: 0,
	margin: 0,

	"& td": {
		height: 52,
		border: 0,

		padding: theme.spacing(0, 0, 0, 1),
	},
}));

const DocumentsWithSignaturesPlacedInfos = styled(Typography)(({ theme: { spacing } }) => ({
	marginRight: spacing(2)
}));

const DocumentsContent = ({ procedure, editProcedure }) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [documentToSign, setDocumentToSign] = useState();

	const numDocuments = procedure?.documents?.length || 0;
	const numDocumentsWithSignaturesPlaced = procedure?.documents.filter(({signatures}) => (signatures?.length || 0) > 0)?.length || 0;


	const changeDocumentsState = ({ target }) => {
		const documents = [...procedure.documents];
		const selectedDocumentId = target.name;
		const documentsToEdit = documents.filter(({ id }) =>
			!!selectedDocumentId ? +id === +selectedDocumentId : true
		);

		let tmpDocuments = [...documents];
		let newValue =
			documentsToEdit.length === 1 && !documentsToEdit[0].checked;

		if (!selectedDocumentId) {
			const checkedCount = documents.filter(
				({ checked }) => checked
			).length;

			newValue =
				checkedCount === 0 ||
				(checkedCount !== documents.length &&
					checkedCount >= documents.length / 2);
		}

		documentsToEdit.forEach((document) => {
			let tmpDocument = { ...document };

			tmpDocuments.splice(
				tmpDocuments.findIndex((item) => item.id === document.id),
				1,
				{
					...tmpDocument,
					checked: newValue,
				}
			);
		});

		editProcedure("documents", tmpDocuments);
	};

	const columns = [
		{
			width: 40,
			label: (
				<Checkbox
					color="primary"
					checked={
						procedure.documents.length > 0 &&
						procedure.documents.filter(({ checked }) => checked)
							.length === procedure.documents.length
					}
					indeterminate={
						procedure.documents.filter(({ checked }) => checked)
							.length > 0 &&
						procedure.documents.filter(({ checked }) => checked)
							.length !== procedure.documents.length
					}
					onClick={changeDocumentsState}
				/>
			),
			dataKey: "",
			disableSort: true,
			centered: true,
		},
		{
			width: 100,
			label: "Ordre",
			dataKey: "order",
			disableSort: true,
		},
		{
			width: 340,
			label: "Nom",
			dataKey: "name",
			disableSort: true,
		},
		{
			width: 340,
			label: "Signataires",
			dataKey: "signatories",
			disableSort: true,
		},
		{
			width: 100,
			label: "Poids",
			dataKey: "size",
			disableSort: true,
		},
		{
			width: 110,
			label: "Actions",
			dataKey: "actions",
			disableSort: true,
			centered: true,
		},
	];

	const TableWrapper = styled("div")({
		minHeight: "calc(47px * 7.8)",
		height: `calc(47px * ${procedure.documents.length})`,
		maxHeight: "calc(47px * 7.8)",
		width: "100%",
	});

	const refreshOrder = (documents) =>
		documents.map(({ ...document }, index) => ({
			...document,
			ordre: index + 1
		}));

	const changeOrder = (documentId, shift) => {
		let documents = [...procedure.documents].sort(
			(a, b) => a.ordre - b.ordre
		);

		const document = documents.find(({ id }) => id === documentId);
		const documentIndex = documents.findIndex(
			({ id }) => id === documentId
		);

		documents[documentIndex] = documents[documentIndex + shift];
		documents[documentIndex + shift] = document;

		editProcedure("documents", refreshOrder(documents));
	};

	const deleteDocument = (documentId) => {
		let documents = [...procedure.documents].sort(
			(a, b) => a.ordre - b.ordre
		);

		documents.splice(
			documents.findIndex((document) => document.id === documentId),
			1
		);

		editProcedure("documents", refreshOrder(documents));
	};

	const addDocuments = (documents) => {
		const tmpDocuments = documents.filter(
			({ name }) =>
				!procedure.documents.some((document) => document.nom === name)
		);

		if (tmpDocuments.length !== documents.length) {
			enqueueSnackbar(
				"Un ou plusieurs documents sont déjà présents dans la procédure",
				{
					variant: "warning",
				}
			);
			return null;
		}

		editProcedure("documents", [
			...procedure.documents,
			...tmpDocuments.map((document, index) => ({
				id:
					procedure.documents.length > 0
						? +procedure.documents[procedure.documents.length - 1]
								.id + 1
						: index,
				nom: document.name,
				ordre: procedure.documents.length + index + 1,
				signatures: [],
				blob: document,
			})),
		]);
	};

	const openUploadDocumentDialog = () =>
		dispatch(procedureOperations.openUploadDocumentDialog());

	const downloadFiles = () =>
		fileHandler.downloadZip(
			procedure.documents.filter((document) => document.checked),
			procedure.nom
		);

	const deleteDocuments = () => {
		let documents = [...procedure.documents].sort(
			(a, b) => a.ordre - b.ordre
		);

		const documentsToDelete = documents.filter(
			(document) => document.checked
		);

		documentsToDelete.forEach((document) =>
			documents.splice(
				documents.findIndex((item) => item.id === document.id),
				1
			)
		);

		editProcedure("documents", refreshOrder(documents));
	};

	const openConfirmationDialog = (props) =>
		dispatch(generalOperations.openConfirmationDialog(props));

	const handleDocumentDeletion = ({ currentTarget }) => {
		const selectedDocumentId = currentTarget.name;

		openConfirmationDialog({
			content: "Êtes-vous sûr de vouloir supprimer ce document ?",
			action: () => {
				deleteDocument(selectedDocumentId);
			},
		});
	};

	const cellRenderer = ({ rowData, columnIndex }) => {
		const signatories = [
			...new Set(
				rowData.signatures?.map((signature) =>
					procedure.signataires.find(
						(signatory) =>
							signatory.id.toString() ===
							signature.id_contact.toString()
					)
				)
			),
		];

		const sortedDocuments = [...procedure.documents].sort(
			(a, b) => a.ordre - b.ordre
		);

		switch (columnIndex) {
			case 0:
				return (
					<CenteredColumn>
						<Checkbox
							name={`${rowData.id}`}
							color="primary"
							onClick={changeDocumentsState}
							checked={rowData.checked}
						/>
					</CenteredColumn>
				);

			case 1:
				return (
					<CenteredColumn>
						<Tooltip title="Monter">
							<span>
								<OrderButton
									name={`${rowData.id}/-1`}
									onClick={() => changeOrder(rowData.id, -1)}
									disabled={
										sortedDocuments[0].id === rowData.id
									}
								>
									<KeyboardArrowUp />
								</OrderButton>
							</span>
						</Tooltip>
						<Tooltip title="Descendre">
							<span>
								<OrderButton
									name={`${rowData.id}/1`}
									onClick={() => changeOrder(rowData.id, 1)}
									disabled={
										sortedDocuments[
											sortedDocuments.length - 1
										].id === rowData.id
									}
								>
									<KeyboardArrowDown />
								</OrderButton>
							</span>
						</Tooltip>
					</CenteredColumn>
				);

			case 2:
				return (
					<NameCell>
						<Typography variant="body2">{rowData.nom}</Typography>
					</NameCell>
				);

			case 3:
				return (
					<div>
						<Tooltip
							title={signatories.map((contact) => (
								<div key={contact.id}>
									<span>
										{contact.prenom} {contact.nom}
									</span>
								</div>
							))}
						>
							<AvatarGroup max={5}>
								{signatories.map((contact) => (
									<Avatar key={contact.id}>
										{contact.prenom.toUpperCase().charAt(0)}
										{contact.nom.toUpperCase().charAt(0)}
									</Avatar>
								))}
							</AvatarGroup>
						</Tooltip>
					</div>
				);

			case 4:
				return (
					<div>{fileHandler.convertFileSize(rowData.blob?.size)}</div>
				);

			case 5:
				const hasProcedureSignataires = (procedure?.signataires?.length || 0) > 0;


				return (
					<CenteredColumn>
						<Tooltip title={hasProcedureSignataires ? "Placer des signatures" : "Vous devez renseigner des signataires"}>
							<div>
								<SignIconButton
									disabled={!hasProcedureSignataires}
									name={rowData.id}
									onClick={() => setDocumentToSign(rowData)}
								>
									<DrawPenIcon height="18px" width="18px" />
								</SignIconButton>
							</div>
						</Tooltip>

						<Tooltip title="Supprimer">
							<DeleteIconButton
								name={rowData.id}
								onClick={handleDocumentDeletion}
							>
								<DeleteIcon />
							</DeleteIconButton>
						</Tooltip>
					</CenteredColumn>
				);

			case 6:
				return null;

			default:
				break;
		}
	};

	const getDocumentAtIndex = ({ index }) =>
		[...procedure.documents].sort((a, b) => a.ordre - b.ordre)[index];

	const handleSignaturesPlacingValidation = useCallback((placedDocumentSignatures) => 
		dispatch(
			procedureOperations.setSelectedProcedure({
				...procedure,
				documents: [
					...procedure.documents.filter(({id}) => id !== placedDocumentSignatures.document.id), 
					{ ...placedDocumentSignatures.document, signatures: placedDocumentSignatures.signatures }
				]
			})
		),
	[dispatch, procedure])

	return (
		<Fragment>
			<Container>
				<TableWrapper>
					<VirtualizedTable
						isLoading={false}
						columns={columns}
						rowCount={procedure.documents.length}
						rowGetter={getDocumentAtIndex}
						cellRenderer={cellRenderer}
					/>
				</TableWrapper>

				<TableFooterContainer>
					<TableFooter>
						<TableRow>
							<TableActions>
								{procedure.documents.some(
									(document) => document.checked
								) ? (
									<Fragment>
										<Button
											variant="contained"
											color="secondary"
											onClick={downloadFiles}
										>
											Télécharger
										</Button>
										<DeleteButton
											variant="contained"
											onClick={deleteDocuments}
										>
											Supprimer
										</DeleteButton>
									</Fragment>
								) : (
									<Box display="flex" justifyContent="space-between" alignItems="center">
										<Button
											variant="contained"
											color="primary"
											onClick={openUploadDocumentDialog}
										>
											Ajouter un document
										</Button>

										<DocumentsWithSignaturesPlacedInfos 
											color={numDocumentsWithSignaturesPlaced < numDocuments ? "error" : "inherit"}
											variant="body2"
										>
											{`Documents avec signatures placées: ${numDocumentsWithSignaturesPlaced} / ${numDocuments}`}
										</DocumentsWithSignaturesPlacedInfos>
									</Box>
								)}
							</TableActions>
						</TableRow>
					</TableFooter>
				</TableFooterContainer>
			</Container>

			<UploadDocumentDialog addDocuments={addDocuments} />

			<PlaceSignaturesDialog
				document={documentToSign}
				open={!!documentToSign}
				onClose={() => setDocumentToSign(undefined)}
				signatories={procedure?.signataires?.map(
					({ id, prenom, nom }) => ({
						id,
						name: `${prenom ?? ""} ${nom}`.trim(),
					})
				)}
				onValidation={handleSignaturesPlacingValidation}
			/>
		</Fragment>
	);
};

export default DocumentsContent;
