import React from "react"

import { LinearProgress, styled, Tooltip } from "@material-ui/core"

const StepIndicator = ({ step }) => {
	const StepLinearProgress = styled(LinearProgress)(({ theme }) => ({
		backgroundColor:
			theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
		height: 20,
		width: "calc(100% - 16px)",
		borderRadius: theme.shape.borderRadius,

		"& > div": {
			backgroundColor: step.color
		}
	}))

	return (
		<Tooltip title={step.label}>
			<StepLinearProgress variant="determinate" value={step.value} />
		</Tooltip>
	)
}

export default StepIndicator
