/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 * @see		"./types"
 */

import produce from "immer"
import { combineReducers } from "redux"

import types from "./types"

// LISTE DE CONTACTS
const contactListInitialState = {
	contacts: []
}

const contactListReducer = (state = { ...contactListInitialState }, {type, payload} = {}) => {
	switch (type) {
		case types.SET_CONTACTS:
			return produce(state, draft => {
				draft.contacts = payload
			})

		case types.REMOVE_CONTACT:
			return produce(state, draft => {
				let tmpContacts = [...draft.contacts]
				tmpContacts.splice(tmpContacts.findIndex(contact => contact.id === payload), 1)
				draft.contacts = tmpContacts
			})
	
		default:
			return state
	}
}

// CONTACT
const contactInitialState = {
	isDialogOpen: false,
	dialogMode: "",
	creationMode: "",
	contact: {}
}

const contactReducer = (state = { ...contactInitialState }, {type, payload} = {}) => {
	switch (type) {
		case types.OPEN_CONTACT_DIALOG:
			return produce(state, draft => {
				draft.isDialogOpen = true
				draft.dialogMode = payload.mode
				draft.creationMode = payload.creationMode || ""
				draft.contact = payload.contact 
			})

		case types.CLOSE_CONTACT_DIALOG:
			return produce(state, draft => {
				draft.isDialogOpen = false
			})

		case types.SET_DIALOG_MODE:
			return produce(state, draft => {
				draft.dialogMode = payload
			})

		case types.SET_CONTACT:
			return produce(state, draft => {
				draft.contact = payload
			})

		default:
			return state
	}
}

export default combineReducers({
	contactList: contactListReducer,
	contact: contactReducer
})