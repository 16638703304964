export default {
	palette: {
		type: 'light',
		error: {
			main: '#B00020'
		}
	},
	typography: {
		useNextVariants: true
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: 'none',
				fontFamily: '"Product Sans", "Roboto", Arial, Helvetica, sans-serif',
				fontWeight: 700
			}
		},
		MuiTypography: {
			h1: {
				fontFamily: '"Product Sans", Roboto, Arial, Helvetica, sans-serif',
				fontWeight: 400,
				fontSize: '2.5rem',
				margin: '0 0 .5rem 0',
				lineHeight: '1.2'
			},
			h2: {
				fontFamily: '"Product Sans", Roboto, Arial, Helvetica, sans-serif',
				fontWeight: 400,
				fontSize: '2rem',
				margin: '0 0 .5rem 0',
				lineHeight: '1.2'
			},
			h3: {
				fontFamily: '"Product Sans", Roboto, Arial, Helvetica, sans-serif',
				fontWeight: 400,
				fontSize: '1.75rem',
				margin: '0 0 .5rem 0',
				lineHeight: '1.2'
			},
			h4: {
				fontFamily: '"Product Sans", Roboto, Arial, Helvetica, sans-serif',
				fontWeight: 400,
				fontSize: '1.5rem',
				margin: '0 0 .5rem 0',
				lineHeight: '1.2'
			},
			h5: {
				fontFamily: '"Product Sans", Roboto, Arial, Helvetica, sans-serif',
				fontWeight: 400,
				fontSize: '1.25rem',
				margin: '0 0 .5rem 0',
				lineHeight: '1.2'
			},
			h6: {
				fontFamily: '"Product Sans", Roboto, Arial, Helvetica, sans-serif',
				fontWeight: 400,
				fontSize: '1rem',
				margin: '0 0 .5rem 0',
				lineHeight: '1.2'
			},
			body1: {
				'&.appBarText': {
					color: '#757575'
				}
			},
			button: {
				textTransform: 'none',
				fontFamily: '"Product Sans", "Roboto", Arial, Helvetica, sans-serif'
			}
		},
		MuiStepper: {
			root: {
				paddingLeft: 0
			}
		},
		MuiStepConnector: {
			root: {
				maxWidth: 50
			}
		}
	}
}
