/**
 * @author	Romain Garcia
 * @version 2020/08/13
 * @see		"./reducer"
 */

// THEME
/**
 * Récupération du thème du site
 *
 * @param {object}	state => partie du Store concernée
 */
const getTheme = state => state.theme.selected

// MENU DE PROFIL
/**
 * Récupération de l'état d'ouverture du menu de profile
 *
 * @param {object}	state => partie du Store concernée
 */
const isProfileMenuOpen = state => state.profileMenu.isOpen
/**
 * Récupération de l'élément auquel est raccroché le menu de profil
 *
 * @param {object}	state => partie du Store concernée
 */
const getProfileMenuAnchorElement = state => state.profileMenu.anchorEl

// DIALOG DE PARAMETRES
/**
 * Récupération de l'état d'ouverture de la dialog de paramètres
 *
 * @param {object}	state => partie du Store concernée
 */
const isSettingsDialogOpen = state => state.settingsDialog.isOpen

// DIALOG DE CONFIRMATION
/**
 * Récupération de l'état d'ouverture de la dialog de confirmation
 *
 * @param {object}	state => partie du Store concernée
 */
const isConfirmationDialogOpen = state => state.confirmationDialog.isOpen
/**
 * Récupération du contenu de la dialog de confirmation
 *
 * @param {object}	state => partie du Store concernée
 */
const getConfirmationDialogContent = state => state.confirmationDialog.content
/**
 * Récupération de l'action de validation de la dialog de confirmation
 *
 * @param {object}	state => partie du Store concernée
 */
const getConfirmationDialogAction = state => state.confirmationDialog.action

// SOCKET
/**
 * Récupération de la socket
 *
 * @param {object}	state => partie du Store concernée
 */
const getSocket = state => state.socket.socket

export default {
	getTheme,

	isProfileMenuOpen,
	getProfileMenuAnchorElement,

	isSettingsDialogOpen,

	isConfirmationDialogOpen,
	getConfirmationDialogContent,
	getConfirmationDialogAction,

	getSocket
}
