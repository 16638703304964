import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { generalOperations, generalSelectors } from "../../modules/general"
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Box,
	Button
} from "@material-ui/core"

const ConfirmationDialog = () => {
	const dispatch = useDispatch()

	const isOpen = useSelector((state) =>
		generalSelectors.isConfirmationDialogOpen(state.general)
	)
	const content = useSelector((state) =>
		generalSelectors.getConfirmationDialogContent(state.general)
	)
	const action = useSelector((state) =>
		generalSelectors.getConfirmationDialogAction(state.general)
	)

	// Fonction pour fermer la dialog
	const closeDialog = () => {
		dispatch(generalOperations.closeConfirmationDialog())
	}

	// Fonction validant l'action à confirmer
	const validate = () => {
		action()
		closeDialog()
	}

	return (
		<Dialog open={isOpen} onClose={closeDialog} maxWidth="xs" fullWidth>
			<DialogTitle>Confirmer</DialogTitle>
			<DialogContent>
				<DialogContentText>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flex="1">
					<Button onClick={closeDialog}>Annuler</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={validate}>
						Valider
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationDialog
