import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	AppBar,
	Avatar,
	Box,
	Button,
	IconButton,
	InputBase,
	Link,
	MenuItem,
	Select,
	Toolbar,
	Typography,
} from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';

import ProfileMenu from './ProfileMenu';

// import logoAppbarLight from "../../assets/images/logo_talenz_horizontal_light.svg"
// import logoAppbarDark from "../../assets/images/logo_talenz_horizontal_dark.svg"

import { generalOperations } from '../../modules/general';
import { procedureOperations, procedureSelectors } from '../../modules/procedure';
import { userSelectors } from '../../modules/user';

import { getCookie } from '../../utils/cookies';
import pages from '../../utils/pagesConstants';

const Container = styled(AppBar)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	padding: '0 .5rem',
	fontFamily: "'Product Sans', Roboto, Arial, Helvetica, sans-serif",
	display: 'flex',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	flex: '1',
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(0, 1),

	'&.flexEnd': {
		justifyContent: 'flex-end',
	},
}));

const Logo = styled('img')({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	height: '64px',
	width: 'auto',
	cursor: 'pointer',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
}));

const HeaderRight = styled('div')({
	display: 'flex',
	alignItems: 'center',
});

const AppbarText = styled(Typography)(({ theme }) => ({
	color: theme.palette.getContrastText(theme.palette.background.default, theme.palette),
}));

const Username = styled(AppbarText)({
	marginRight: '.5rem',
});

const SupportButton = styled(Button)(({ theme: { palette, spacing } }) => ({
	marginRight: spacing(4),
}));

const Appbar = ({ page }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const firstname = useSelector(state => userSelectors.getFirstname(state.user));
	const lastname = useSelector(state => userSelectors.getLastname(state.user));
	const societies = useSelector(state => userSelectors.getSocieties(state.user));
	const society = useSelector(state => procedureSelectors.getSociety(state.procedure));

	let logoHorizontal = '/theme/logos/logo.png';

	const openProfileMenu = ({ currentTarget }) => dispatch(generalOperations.toggleProfileMenu(currentTarget));

	const redirectToHome = () => {
		history.push(pages.HOME.endpoint);

		dispatch(procedureOperations.disconnectFromSocietyNotifications(society.id));
	};

	const selectSociety = ({ target }) =>
		dispatch(procedureOperations.setSociety(societies.find(item => item.id === target.value)));

	const renderSocietiesComponent = () =>
		societies.length > 1 ? (
			<Select
				value={society?.id || ''}
				onChange={selectSociety}
				input={<InputBase name="society" id="society-select" />}
			>
				{societies.map(item => (
					<MenuItem key={item.id} value={item.id}>
						<Typography>
							{item.id} - {item.label}
						</Typography>
					</MenuItem>
				))}
			</Select>
		) : (
			societies.length > 0 && <AppbarText>{society.id + ' - ' + society.label}</AppbarText>
		);

	// Récupération de la société en cookie
	useEffect(() => {
		const societyIdFromCookies = getCookie('societyId');
		const societyIdFromCookiesHasValue = !!societyIdFromCookies;

		if (societyIdFromCookiesHasValue) {
			const selectedSociety = societies.find(item => item.id === societyIdFromCookies);
			const selectedSocietyHasValue = !!selectedSociety;

			selectedSocietyHasValue && dispatch(procedureOperations.setSociety(selectedSociety));
		} else {
			history.push('/accueil')
		}
	}, [dispatch, societies]);

	return (
		<Box>
			<Container position="fixed">
				<StyledToolbar className={clsx({ flexEnd: page === 'home' })}>
					<Logo
						// src={theme.palette.type === 'dark' ? logoAppbarDark : logoAppbarLight}
						src={logoHorizontal}
						alt="Logo du cabinet"
						onClick={redirectToHome}
					/>

					{page !== 'home' && (
						<Box display="flex" alignItems="center">
							{page === 'contact' ? (
								<IconButton onClick={redirectToHome}>
									<HomeIcon />
								</IconButton>
							) : (
								renderSocietiesComponent()
							)}
						</Box>
					)}

					<HeaderRight>
						<Link href="https://elips-solution.desk365.io/support/sp-home" target="_blank">
							<SupportButton color="secondary" variant="contained">
								Assistance technique
							</SupportButton>
						</Link>

						<Username variant="body1">{firstname + ' ' + lastname}</Username>

						<IconButton aria-label="button-profile-menu" onClick={openProfileMenu}>
							<StyledAvatar>{firstname.charAt(0) + lastname.charAt(0)}</StyledAvatar>
						</IconButton>
					</HeaderRight>
				</StyledToolbar>
			</Container>
			<ProfileMenu />
		</Box>
	);
};

export default Appbar;
