import React, { Fragment } from 'react'

import { Stepper, Step, StepButton, Box, styled } from '@material-ui/core'

import useStateWithLabel from '../../../utils/useStateWithLabel'
import InformationContent from './edition/InformationsContent'
import DocumentsContent from './edition/DocumentsContent'
import SignatoriesContent from './edition/SignatoriesContent'

const DialogStepper = styled(Stepper)(({ theme }) => ({
	padding: theme.spacing(2.8, 0)
}))

const DialogStepButton = styled(StepButton)(({ theme }) => ({
	'& > span > span:first-of-type > circle': {
		backgroundColor: theme.palette.secondary.main
	}
}))

const steps = ['Informations', 'Signataires', 'Documents']

const EditionContent = ({ mode, procedure, setProcedure }) => {
	const [activeStep, setActiveStep] = useStateWithLabel(0, 'activeStep')

	const editProcedure = (field, value) => {
		if (field === '') {
			setProcedure(value)
		} else {
			let tmpProcedure = { ...procedure }

			tmpProcedure[field] = value

			setProcedure(tmpProcedure)
		}
	}

	const getStepContent = () => {
		switch (activeStep) {
			case 0:
				return <InformationContent mode={mode} procedure={procedure} editProcedure={editProcedure} />

			case 1:
				return <SignatoriesContent procedure={procedure} editProcedure={editProcedure} />

			case 2:
				return <DocumentsContent procedure={procedure} editProcedure={editProcedure} />

			default:
				break
		}
	}

	return (
		<Fragment>
			<DialogStepper nonLinear activeStep={activeStep}>
				{steps.map((label, index) => (
					<Step key={label}>
						<DialogStepButton
							onClick={() => {
								setActiveStep(index)
							}}
						>
							{label}
						</DialogStepButton>
					</Step>
				))}
			</DialogStepper>
			<Box display="flex" flexDirection="column" alignItems="flex-start" flex={1}>
				{getStepContent()}
			</Box>
		</Fragment>
	)
}

export default EditionContent
