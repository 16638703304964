import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Menu, MenuItem, SvgIcon, Typography, styled } from "@material-ui/core";

import ContactsIcon from "@material-ui/icons/Contacts";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

import { useHistory } from "react-router";
import { generalOperations, generalSelectors } from "../../modules/general";
import { userOperations, userSelectors } from "../../modules/user";
import pages from "../../utils/pagesConstants";
import { getCookie } from "../../utils/cookies";

const MenuIcon = styled(SvgIcon)({
	marginRight: ".5rem",
});

const ProfileMenu = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const anchorEl = useSelector((state) =>
		generalSelectors.getProfileMenuAnchorElement(state.general)
	);
	const isOpen = useSelector((state) =>
		generalSelectors.isProfileMenuOpen(state.general)
	);

	const keycloak = useSelector((state) =>
		userSelectors.getKeycloakInstance(state.user)
	);

	const closeMenu = () => dispatch(generalOperations.toggleProfileMenu());

	/* const openSettingsDialog = () => {
		dispatch(generalOperations.toggleSettingsDialog())
		closeMenu()
	} */

	const disconnectUser = () =>
		dispatch(userOperations.disconnectUser(keycloak));

	const navigateToContact = () => {
		history.push(pages.CONTACT.endpoint);
		closeMenu();
	};

	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isOpen}
			onClose={closeMenu}
			disableAutoFocusItem
		>
			{/* <MenuItem
				onClick={openSettingsDialog}
				aria-label="menu-item-settings">
				<MenuIcon component={SettingsIcon} />
				<Typography>Préférences</Typography>
			</MenuItem> */}
			{!!getCookie("societyId") && (
				<MenuItem
					onClick={navigateToContact}
					aria-label="menu-item-contacts"
				>
					<MenuIcon component={ContactsIcon} />
					<Typography>Contacts</Typography>
				</MenuItem>
			)}
			<MenuItem onClick={disconnectUser} aria-label="menu-item-logout">
				<MenuIcon component={PowerSettingsNewIcon} />
				<Typography>Déconnexion</Typography>
			</MenuItem>
		</Menu>
	);
};

export default ProfileMenu;
