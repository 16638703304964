/**
 * @author	Romain Garcia
 * @version	2021/09/01
 */

import io from 'socket.io-client'
import { generalOperations } from '../modules/general'

export let socket

/**
 * Authentifie la connexion auprrès du back
 *
 * @param {string}	token => le token Keycloak de l'utilisateur
 *
 * @return {Promise}	=>
 * 						(resolve)
 * 						(reject)	=> le message d'erreur renvoyé par la socket
 */
const authenticate = (dispatch, token) => {
	process.env.NODE_ENV === 'development' && console.log('-- SOCKET : authentication')

	return new Promise((resolve, reject) => {
		socket.on('authenticated', () => {
			socket.authenticated = true
			dispatch(generalOperations.setSocket(socket))
			resolve()
		})

		socket.on('unauthorized', msg => reject(msg.message))

		socket.emit('authentication', token)
	})
}

/**
 * Connecte la socket
 *
 * @param {function}	dispatch => le dispatcher pour le Store
 */
const connect = ({ url, endpoint }) => {
	process.env.NODE_ENV === 'development' && console.log('-- SOCKET : connection')

	return new Promise(resolve => {
		socket = io(url, {
			path: endpoint
		})

		setTimeout(() => resolve(socket.connected), 500)
	})
}

/**
 * Effectue une requête auprès de la socket
 *
 * @param {string}	requestName => le nom de la requête à effectuer
 * @param {object}	data => les paramètres de la requête
 *
 * @return {Promise}	=>
 * 						(resolve)	=> les datas de la réponse de la socket
 * 						(reject)	=> le message d'erreur renvoyé par la socket
 */
const request = (requestName, data) => {
	process.env.NODE_ENV === 'development' && console.log('-- SOCKET : request -> requestName, data', requestName, data)

	return new Promise((resolve, reject) => {
		socket.emit(requestName, data, msg => {
			process.env.NODE_ENV === 'development' && console.log(msg)

			if (!msg.Error) {
				resolve(msg.Data)
			} else {
				process.env.NODE_ENV === 'development' && console.error('ERREUR SUR ' + requestName + ' :')
				process.env.NODE_ENV === 'development' && console.error(msg.Message)
				reject(msg.Message)
			}
		})
	})
}

/**
 * Ecoute la socket
 *
 * @param {string}		listenerName => nom de la requête à écouter
 * @param {function} 	action => action à effectuer à la réception
 */
const listen = (listenerName, action) => {
	process.env.NODE_ENV === 'development' && console.log('-- SOCKET : listening on ' + listenerName)

	socket.on(listenerName, msg => {
		process.env.NODE_ENV === 'development' && console.log(listenerName + ' => ' + msg)
		action(msg)
	})
}

export default {
	connect,
	authenticate,
	request,
	listen
}
