import React from "react"

import {
	Box,
	Button,
	Chip,
	DialogTitle,
	Typography,
	styled
} from "@material-ui/core"

import clsx from "clsx"

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import CommentIcon from "@material-ui/icons/Comment"
import useStateWithLabel from "../../../utils/useStateWithLabel"
import procedureStates from "../procedureStates"
import { useDispatch } from "react-redux"
import { procedureOperations } from "../../../modules/procedure"
import { generalOperations } from "../../../modules/general"

const DialogHeader = styled(DialogTitle)(({ theme }) => ({
	userSelect: "none",
	backgroundColor: theme.palette.background.default,
	zIndex: 10,
	boxShadow: theme.shadows[1],

	"&.absolute": {
		position: "absolute",
		left: 0,
		right: 0
	},

	"&.expended": {
		boxShadow: theme.shadows[5]
	},

	"& h6": {
		marginBottom: 0
	}
}))

const HeaderInfosWrapper = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",

	"&.clickable": {
		cursor: "pointer"
	},

	"& > h6": {
		margin: theme.spacing(0, 1)
	},

	"& > svg:first-child": {
		transform: "rotate(-90deg)",
		transition: "rotate .5s ease-in-out"
	},

	"&.expended > svg:first-child": {
		transform: "rotate(0)"
	},

	"& > svg:last-child": {
		transform: "rotateY(180deg)"
	}
}))

const Comment = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(1)
}))

const DeleteButton = styled(Button)(({ theme }) => ({
	backgroundColor: "#F44336",
	color: theme.palette.getContrastText("#F44336"),

	"&:hover": {
		backgroundColor: "#D32F2F"
	}
}))

const StepChip = styled(Chip)(({ theme }) => ({
	"&.draft": {
		backgroundColor: "#757575",
		color: theme.palette.getContrastText("#757575")
	},
	"&.ongoing": {
		backgroundColor: "#5C6486",
		color: theme.palette.getContrastText("#5C6486")
	},
	"&.refused": {
		backgroundColor: "#F44336",
		color: theme.palette.getContrastText("#F44336")
	},
	"&.valid": {
		backgroundColor: "#4CAF50",
		color: theme.palette.getContrastText("#4CAF50")
	}
}))

const ProcedureDialogHeader = ({ mode, id, title, comment, state }) => {
	const dispatch = useDispatch()

	const [isHeaderInfosExpended, setIsHeaderInfosExpended] = useStateWithLabel(
		false,
		"isHeaderInfosExpended"
	)

	const toggleHeaderInfoExpanded = () =>
		setIsHeaderInfosExpended(!isHeaderInfosExpended)

	const deleteAndCloseDialog = () =>
		dispatch(procedureOperations.freeProcedure(id)).then(
			dispatch(procedureOperations.deleteProcedure(id)).then(
				dispatch(procedureOperations.closeProcedureDialog())
			)
		)

	const deleteProcedure = () => {
		dispatch(
			generalOperations.openConfirmationDialog({
				content: "Êtes-vous sûr de vouloir supprimer cette procédure ?",
				action: deleteAndCloseDialog
			})
		)
	}

	return (
		<DialogHeader
			className={clsx({
				absolute: mode === "view",
				expended: isHeaderInfosExpended
			})}
			disableTypography>
			{mode === "view" && (
				<>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center">
						<HeaderInfosWrapper
							className={clsx({
								clickable: !!comment,
								expended: isHeaderInfosExpended
							})}
							onClick={toggleHeaderInfoExpanded}>
							{!!comment && <ArrowDropDownIcon />}
							<Typography variant="h6">{title}</Typography>
							{!!comment && <CommentIcon />}
						</HeaderInfosWrapper>
						<StepChip label={procedureStates[state]?.label} />
					</Box>
					{isHeaderInfosExpended && <Comment>{comment}</Comment>}
				</>
			)}
			{mode !== "view" && (
				<>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center">
						<Typography variant="h6">
							{mode === "create" ? "Créer" : "Modifier"} une
							procédure
						</Typography>
						{mode === "edit" && state === 0 && (
							<DeleteButton onClick={deleteProcedure}>
								Supprimer
							</DeleteButton>
						)}
					</Box>
				</>
			)}
		</DialogHeader>
	)
}

export default ProcedureDialogHeader
