import React, { useEffect } from "react";

import SocietiesTable from "./home/SocietiesTable";
import { getCookie, removeCookie } from "../utils/cookies";

const Home = () => {
	useEffect(() => {
		if (!!getCookie("societyId")) {
			removeCookie("societyId");
		}
	}, []);

	return <SocietiesTable />;
};

export default Home;
