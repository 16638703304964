import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { v4 as uuid } from "uuid";
import { styled, Typography, IconButton, Chip, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CheckIcon from "@material-ui/icons/Check";

import ResizeBottomRightIcon from "../../../../../assets/svg/ResizeBottomRightIcon";
import YouSign from "../../../../../assets/svg/YouSign";
import { measureTextWidth } from "../../../../../utils/textMeasure";

const ASPECT_RATIO = 2.33;

const SignaturesContainer = styled("div")(({ theme: { spacing } }) => ({
	display: "flex",
	flexDirection: "column",
	minHeight: "34px",
	gap: spacing(1)
})); 

const SignatureInfosContainer = styled("div")(({ theme: { spacing } }) => ({
	display: "flex",
	gap: spacing(1), 
	overflow: "auto"
}));

const SignatureAloneContainer = styled("div")(({ theme: { spacing, palette, shape, shadows } }) => ({
	boxShadow: shadows[1],
	borderRadius: shape.borderRadius,
	border: `1px solid ${palette.divider}`,
	backgroundColor: palette.background.paper,
	padding: spacing(0.5, 1, 0.5, 0),
	width: 'max-content',
	maxWidth: "250px",
	display: "flex",
	flexDirection: "row",
	gap: spacing(0.5),
	cursor: 'move',
})); 

const SignatureDragIcon = styled("div")({
	display:"flex",
	justifyContent:"center",
	alignItems:"center",
	height:"100%"
});

const SignatureAndMentionContainer = styled("div")(({ theme: { shadows, shape } }) => ({
	boxShadow: shadows[1],
	borderRadius: shape.borderRadius,
	display: "flex", 
	flexDirection: "column",
	cursor: "move",
	minWidth: '135px',
	maxWidth: '2000px',
	minHeight: '80px',
	maxHeight: '3045px',

	"&:active": {
		boxShadow: "none",

		"& > button:not(:hover)": {
			display: "none",
		},

		"& > div:nth-of-type(2n)": {
			"& > div:nth-of-type(2n):not(:active)": {
				display: "none",
			}
		},
	},
}));

const MentionContainer = styled("div")(({ theme: { palette, shape } }) => ({
	display:"flex",
	alignItems: "center",
	width: "100%",
	borderTopLeftRadius: shape.borderRadius,
	borderTopRightRadius: shape.borderRadius,
	border: `1px dashed ${palette.divider}`,
	borderBottom: "none",
	backgroundColor: `${palette.grey[200]}E6`
}));

const MentionLabel = styled(Typography)(({ theme: { spacing } }) => ({
 	fontSize: "10px !important", 
	fontFamily: "Inconsolata",
	overflowX: "hidden",
	maxWidth: "100%",
	padding: spacing(0, 0.5),
}));

const SignatureItem = styled("div")(({ theme: { palette, shape, spacing } }) => ({
	borderBottomLeftRadius: shape.borderRadius,
	borderBottomRightRadius: shape.borderRadius,
	borderTop: "none",
	border: `1px solid ${palette.divider}`,
	backgroundColor: "rgba(255, 255, 255, 0.9)",
	width: "100%",
	height: "100%"
}));

const SignatureContent = styled("div")({
	display: "flex",
	height: "100%",
	width: "100%",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
});

const SignatureLabel = styled(Typography)(({ theme: { spacing } }) => ({
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	overflowX: "hidden",
	width: "100%",
	textAlign: "center",

	'&.insideDocument': {
		fontFamily: "Caveat",
		padding: spacing(0, 0.5)
	}
}));

const CertifiedByYouSign = styled("div")(({ theme: { palette } }) => ({
	display: "flex", 
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "row", 
	borderRadius: 50, 
	backgroundColor: palette.grey[200],
	height: "auto",
}));

const DeleteIconButton = styled(IconButton)(
	({ theme: { palette, shadows } }) => ({
		boxShadow: shadows[1],
		position: "fixed",
		top: "-10px",
		right: "-10px",
		padding: 0,
		backgroundColor: "#F44336",
		color: palette.getContrastText("#F44336"),

		"&:hover": {
			backgroundColor: "#D32F2F",
		},
	})
);

const ResizeIcon = styled("div")(({ theme: { spacing } }) => ({
	position: "fixed",
	right: spacing(0.5),
	bottom: "0",
	cursor: "nwse-resize",
}));

const Signature = ({
	id,
	signatory,
	position,
	dndItems,
	pagesInfos,
	onPageNumberChange,
	onSignatureDelete,
	onSignatureResize,
}) => {
	const { width: pageWidth = 0, height: pageHeight = 0 } = pagesInfos || {};

	const mentionLabel = 'Lu et approuvé - Signé le JJ-MM-AAAA';

	const signatureRef = useRef(null);
	const mentionLabelRef = useRef(null);
	const mentionHeightRef = useRef(24);

	const [certifiedFontSize, setCertifiedFontSize] = useState(1);
	const [fontSize, setFontSize] = useState(1);
	const [iconSize, setIconSize] = useState(1);
	const [gapSize, setGapSize] = useState(1);

	const [{ isDragging }, drag] = useDrag(() => ({
		type: "signature",
		item: (monitor) => {
			const initialOffset = monitor.getInitialClientOffset();
			const initialSourceOffset = monitor.getInitialSourceClientOffset();
			const rect = signatureRef.current?.getBoundingClientRect();
			const width = (rect?.width || 0) + (!id ? 24 : 0);
			const height = rect?.height || 0;
			const relativeOffset =
				initialOffset && initialSourceOffset
					? {
							x: initialOffset.x - initialSourceOffset.x,
							y: initialOffset.y - initialSourceOffset.y,
					  }
					: { x: 0, y: 0 };

			return {
				id,
				signatory,
				position: {
					width,
					height,
					...relativeOffset,
				},
			};
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		end: (_, monitor) => {
			const isDropped = monitor.didDrop();

			if (!isDropped && id && onSignatureDelete) {
				onSignatureDelete(id);
			}
		},
	}));

	const signatureOpacity = useMemo(() => {
		if (isDragging) {
			return position ? 0 : 0.5;
		}

		return 1;
	}, [isDragging, position]);

	const signatureStyle = useMemo(() => {
		const { height, width, x, y } = position || {};

		return {
			...(position && {
				top: y,
				left: x,
				width,
				height,
			}),
			position: position ? "absolute" : "relative",
			opacity: signatureOpacity,
			transform: "translate(0, 0)",
		};
	}, [position, signatureOpacity]);

	const handleMouseDown = useCallback(
		(event) => {
			event.stopPropagation();
			event.preventDefault();
	
			const startX = event.clientX;
			const startY = event.clientY;
			const initialWidth = signatureRef.current?.offsetWidth || 0;
			const initialHeight = signatureRef.current?.offsetHeight || 0;
	
			const handleMouseMove = (moveEvent) => {
				const deltaX = moveEvent.clientX - startX;
				const deltaY = moveEvent.clientY - startY;
	
				let newWidth = initialWidth + deltaX;
				let newHeight = initialHeight + deltaY;

				const signatureHeightWithoutMention = newHeight - mentionHeightRef.current;
			
				if (newWidth / signatureHeightWithoutMention > ASPECT_RATIO) {
					newHeight = (newWidth / ASPECT_RATIO) + mentionHeightRef.current;
				} else {
					newWidth = signatureHeightWithoutMention * ASPECT_RATIO;
				}
	
				const signatureX =
					dndItems.find(({ id: dndItemId }) => dndItemId === id)
						?.position?.x || 0;
				const signatureY =
					dndItems.find(({ id: dndItemId }) => dndItemId === id)
						?.position?.y || 0;
	
				if (
					newWidth >= 135 &&
					newWidth <= 2000 &&
					newHeight >= 80 &&
					newHeight <= 3045 &&
					signatureX + newWidth + 14 <= pageWidth &&
					signatureY + newHeight + 4 <= pageHeight &&
					!!onSignatureResize
				) {
					onSignatureResize(id, newWidth, newHeight);
				}
			};

			const handleMouseUp = () => {
				document.removeEventListener("mousemove", handleMouseMove);
				document.removeEventListener("mouseup", handleMouseUp);
			};

			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseup", handleMouseUp);
		},
		[dndItems, onSignatureResize, pageHeight, pageWidth, id]
	);

	const signatureWithMentionRenderer = useMemo(
		() => (
			<SignatureAndMentionContainer style={signatureStyle} ref={signatureRef}>
				{id && onSignatureDelete && (
					<DeleteIconButton
						onClick={() => onSignatureDelete(id)}
						size="small"
						variant="contained"
					>
						<CloseIcon size="small" />
					</DeleteIconButton>
				)}

				<MentionContainer style={{ height: `${mentionHeightRef.current}px`, minHeight: `${mentionHeightRef.current}px` }}>
					<MentionLabel ref={mentionLabelRef}>{mentionLabel}</MentionLabel>
				</MentionContainer>

				<SignatureItem>
					<SignatureContent style={{ gap: `${gapSize * 4}px` }}>
						<SignatureLabel className="insideDocument" style={{ fontSize: `${fontSize}px` }}>{signatory?.name}</SignatureLabel>
						
						<CertifiedByYouSign style={{ gap: `${gapSize * 4}px`, padding: `${gapSize}px ${gapSize * 4}px` }}>
							<CheckIcon style={{ fontSize: `${iconSize}em` }} />
							<Typography style={{ fontSize: `${certifiedFontSize}em` }}>Certified by</Typography>
							<Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '75%' }}>
								<YouSign height="100%" />
							</Box>
						</CertifiedByYouSign>
					</SignatureContent>

					{id && onSignatureResize && (
						<ResizeIcon onMouseDown={handleMouseDown}>
							<ResizeBottomRightIcon />
						</ResizeIcon>
					)}
				</SignatureItem>
			</SignatureAndMentionContainer>
		),
		[
			handleMouseDown,
			id,
			onSignatureDelete,
			onSignatureResize,
			signatory,
			signatureStyle,
			fontSize,
			certifiedFontSize,
			gapSize,
			iconSize,
		]
	);

	const signatureAloneRenderer = useMemo(() => (
		<SignatureAloneContainer style={signatureStyle} ref={signatureRef}>
			<SignatureDragIcon>
				<DragIndicatorIcon />
			</SignatureDragIcon>
			
			<SignatureLabel>{signatory?.name}</SignatureLabel>
		</SignatureAloneContainer>
	), [signatory, signatureStyle]);

	const signaturePagesInfoRenderer = useMemo(() => 
		dndItems?.flatMap(
			({ signatory: { id: currentSignatoryId }, pageNumber }) =>
				currentSignatoryId === signatory?.id && onPageNumberChange && (
					<Chip
						key={uuid()}
						label={`P.${pageNumber}`}
						onClick={() => onPageNumberChange(pageNumber || 0)}
					/>
				)
	), [dndItems, onPageNumberChange, signatory]);

	useEffect(() => {
		if (signatureRef.current) {
			drag(signatureRef);
		}
	}, [drag]);

	useEffect(() => {
		if (signatory?.name && signatureRef.current) {
			const containerWidth = signatureRef.current.clientWidth;
			const containerHeight = signatureRef.current.clientHeight - mentionHeightRef.current;
			const initialFontSize = containerHeight * 0.33;
			const initialTextWidth = measureTextWidth(signatory?.name, initialFontSize, 'Caveat');
			const scaleFactor = containerWidth / initialTextWidth;
			const adjustedFontSize = initialFontSize * Math.min(scaleFactor, 1) * 0.85;
			const newSizeRatio = containerHeight / 184;

			setFontSize(adjustedFontSize);
			setCertifiedFontSize(newSizeRatio);
			setIconSize(newSizeRatio * 1.25);
			setGapSize(newSizeRatio * 2);
		}
	}, [position, signatory]);

	useEffect(() => {
		if (mentionLabelRef.current) {
			const labelWidth = measureTextWidth(mentionLabel, 10, 'Inconsolata') + 12;

			if (labelWidth > (position?.width || 0)) {
				mentionHeightRef.current = 45;
				mentionLabelRef.current.style.whiteSpace = 'normal';
			} else {
				mentionHeightRef.current = 24;
				mentionLabelRef.current.style.whiteSpace = 'nowrap';
			}
		}
	}, [position]);

	return !id ? (
		<SignaturesContainer>
			{signatureAloneRenderer}

			<SignatureInfosContainer>
				{signaturePagesInfoRenderer}
			</SignatureInfosContainer>
		</SignaturesContainer>
	) : (
		signatureWithMentionRenderer
	);
};

export default Signature;
