import React, { useEffect } from "react"

import {
	Box,
	Button,
	DialogActions,
	Typography,
	styled
} from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useDispatch, useSelector } from "react-redux"
import {
	procedureOperations,
	procedureSelectors
} from "../../../modules/procedure"

import useStateWithLabel from "../../../utils/useStateWithLabel"

const ProcedureActions = styled(DialogActions)(({ theme }) => ({
	"& > div > div:last-child > button:first-child": {
		marginRight: theme.spacing(1)
	}
}))

const FooterText = styled("div")(({ theme }) => ({
	marginRight: theme.spacing(2)
}))

const ProcedureDialogActions = ({
	handleClose,
	mode,
	procedure,
	setEditedProcedure,
	setIsLoading
}) => {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	const society = useSelector(state =>
		procedureSelectors.getSociety(state.procedure)
	)
	const isProcedureUsed = useSelector(state =>
		procedureSelectors.isProcedureUsed(state.procedure, {
			procedureId: procedure.id
		})
	)

	const [isSignaturePlacementDone, setSignaturePlacementDone] =
		useStateWithLabel(false, "isSignaturePlacementDone")
	const [isSaveDisabled, setSaveDisabled] = useStateWithLabel(
		true,
		"isSaveDisabled"
	)
	const [isValidationDisabled, setValidationDisabled] = useStateWithLabel(
		true,
		"isValidationDisabled"
	)

	const createProcedure = () =>
		new Promise((resolve, reject) =>
			{
				dispatch(
					procedureOperations.createProcedure({
						...procedure,
						societyId: society.id,
						signataires: procedure.signataires.map((signatory, index) => ({
							...signatory,
							order: index + 1,
						})),
					})
				)
					.then(createdProcedure => {
						dispatch(procedureOperations.setDialogMode('edit'));
						dispatch(procedureOperations.setSelectedProcedure(createdProcedure));
						resolve(createdProcedure);
					})
					.catch(err => {
						enqueueSnackbar(typeof err === 'string' ? err : 'Erreur à la création de la procédure', {
							variant: 'error',
						});
						reject(err);
					});
			}
		);

	const saveEdition = () =>
		new Promise((resolve, reject) => {
			dispatch(procedureOperations.updateProcedure({ procedure }))
				.then(() => {
					dispatch(procedureOperations.fetchProcedures(society.id)).then(newProcedures => {
						dispatch(
							procedureOperations.setSelectedProcedure(
								newProcedures.find(({ id }) => id === procedure.id)
							)
						);
					});
					resolve();
				})
				.catch(err => {
					enqueueSnackbar(typeof err === 'string' ? err : 'Erreur à la sauvegarde de la procédure', {
						variant: 'error',
					});
					reject(err);
				});
		});

	const sendProcedure = procedureToSend => {
		const procedureToUse = procedureToSend || procedure;
		let tmpProcedure = { ...procedureToUse };

		dispatch(procedureOperations.setDialogMode('view')).then(() => {
			dispatch(procedureOperations.sendProcedure(tmpProcedure.id))
				.then(() => {
					tmpProcedure.etat = 1;

					dispatch(
						procedureOperations.updateProcedure({
							procedure: tmpProcedure,
							mode: 'storeOnly',
						})
					);
					dispatch(procedureOperations.setSelectedProcedure(tmpProcedure));

					dispatch(procedureOperations.setDialogMode('view'));
					setIsLoading(false);
				})
				.catch(err => {
					setIsLoading(false);
					dispatch(procedureOperations.setDialogMode('edit'));

					enqueueSnackbar(typeof err === 'string' ? err : 'Erreur au lancement de la procédure', {
						variant: 'error',
					});
				});
		});
	};

	const handleSave = () =>
		mode === "create" ? createProcedure() : saveEdition()

	const handleValidation = () => {
		setIsLoading(true)

		mode === "create"
			? createProcedure().then(createdProcedure =>
					sendProcedure(createdProcedure)
			  )
			: saveEdition().then(sendProcedure)
	}

	const renderModificationDate = () =>
		!!procedure.date_modification &&
		procedure.date_modififcation !== procedure.date_creation && (
			<Typography variant="body2">
				Modifié le{" "}
				{new Date(procedure.date_modification).toLocaleString("fr-FR")}
			</Typography>
		)

	const switchToEditMode = () =>
		dispatch(procedureOperations.setDialogMode("edit"))

	const cancelEdition = () => {
		dispatch(procedureOperations.setDialogMode("view"))
		setEditedProcedure(procedure)
	}

	useEffect(() => {
		setSaveDisabled(procedure?.nom.length === 0)
	}, [setSaveDisabled, procedure.nom])

	useEffect(() => {
		setSignaturePlacementDone(
			procedure?.documents?.length > 0 &&
				!procedure?.documents?.some(
					({ signatures }) => signatures?.length === 0
				)
		)
	}, [setSignaturePlacementDone, procedure.documents])

	useEffect(() => {
		setValidationDisabled(isSaveDisabled || !isSignaturePlacementDone)
	}, [setValidationDisabled, isSignaturePlacementDone, isSaveDisabled])

	return (
		<ProcedureActions>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				flex="1">
				{mode === "view" && (
					<>
						<Button onClick={handleClose}>Fermer</Button>
						<FooterText>
							<Typography variant="body2">
								Créé le{" "}
								{new Date(
									procedure.date_creation
								).toLocaleString("fr-FR")}{" "}
								par {procedure.createur_nom}
							</Typography>
							{renderModificationDate()}
						</FooterText>

						{procedure.etat === 0 && !procedure.archived && (
							<Button
								variant="contained"
								color="primary"
								onClick={switchToEditMode}
								disabled={isProcedureUsed}>
								Modifier
							</Button>
						)}
					</>
				)}
				{mode !== "view" && (
					<>
						{mode === "create" ? (
							<Button onClick={handleClose}>Fermer</Button>
						) : (
							<Button onClick={cancelEdition}>Annuler</Button>
						)}
						<div>
							<Button
								variant="outlined"
								color="secondary"
								disabled={isSaveDisabled}
								onClick={handleSave}>
								Sauvegarder
							</Button>
							<Button
								variant="contained"
								color="primary"
								disabled={isValidationDisabled}
								onClick={handleValidation}>
								Valider
							</Button>
						</div>
					</>
				)}
			</Box>
		</ProcedureActions>
	)
}

export default ProcedureDialogActions
