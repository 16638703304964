export default [
	{
		id: "draft",
		label: "Brouillon",
		color: "#757575",
		value: 25
	},
	{
		id: "ongoing",
		label: "En cours",
		color: "#5C6486",
		value: 65
	},
	{
		id: "refused",
		label: "Refusée",
		color: "#F44336",
		value: 100
	}, {
		id: "valid",
		label: "Validé",
		color: "#4CAF50",
		value: 100
	}
]