import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled } from '@material-ui/core';

import { procedureOperations, procedureSelectors } from '../../../../modules/procedure';

import FileUploader from '../../../../template/components/FileUploader';

import useStateWithLabel from '../../../../utils/useStateWithLabel';

const Container = styled(Dialog)({
	'& > div > div': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		minHeight: 280,

		'& > header + div': {
			flex: 1,
		},
	},
});

const UploadContent = styled(DialogContent)(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: spacing(0.5),

	'& > div': {
		flex: 1,
	},
}));

const UploadActions = styled(DialogActions)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

const UploadDocumentDialog = ({ addDocuments }) => {
	const dispatch = useDispatch();

	const isOpen = useSelector(state => procedureSelectors.isUploadDocumentDialogOpen(state.procedure));

	const [documents, setDocuments] = useStateWithLabel([], 'documents');

	const closeDialog = () => {
		dispatch(procedureOperations.closeUploadDocumentDialog());
		setDocuments([]);
	};

	const handleDocumentValidation = () => {
		addDocuments(documents);
		closeDialog();
	};

	return (
		<Container open={isOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
			<DialogTitle>Déposer un document</DialogTitle>
			<UploadContent>
				<FileUploader files={documents} setFiles={setDocuments} />
				<Typography variant="caption">Format attendu : A4, orientation portrait, 10Mo max.</Typography>
			</UploadContent>
			<UploadActions>
				<Button onClick={closeDialog}>Fermer</Button>
				<Button variant="contained" color="primary" onClick={handleDocumentValidation}>
					Valider
				</Button>
			</UploadActions>
		</Container>
	);
};

export default UploadDocumentDialog;
