/**
 * @author	Romain Garcia
 * @version 2020/08/13
 * @see		"./types"
 */

import types from "./types"

// CONNEXION
/**
 * Inscrits l'instance Keycloak dans le Store
 *
 * @param {object}	keycloak => instance Keycloak à inscrire dans le Store
 */
const setKeycloakInstance = keycloak => ({
	type: types.SET_KEYCLOAK_INSTANCE,
	payload: keycloak
})

// UTILISATEUR
/**
 * Inscrits les informations de l'utilisateur dans le Store
 *
 * @param {object}	param =>
 * 						{string}	firstname => prénom de l'utilisateur
 * 						{string}	lastname => nom de famille de l'utilisateur
 */
const setUserInfos =
	({ firstname, lastname }) =>
	dispatch => {
		dispatch({ type: types.SET_LASTNAME, payload: lastname })
		dispatch({ type: types.SET_FIRSTNAME, payload: firstname })
	}
/**
 * Inscrits les sociétés auxquelles l'utilisateur à accés
 *
 * @param {array}	societies => liste des sociétés à inscrire dans le Store
 */
const setUserSocieties = societies => ({
	type: types.SET_SOCIETIES,
	payload: societies
})

export default {
	setKeycloakInstance,

	setUserInfos,
	setUserSocieties
}
