/**
 * @author	Romain Garcia
 * @version	2020/08/13
 */

import adblockDetector from "./adblockDetector"
import { saveAs } from "file-saver"
import JSZip from "jszip"

/**
 * Ouvre un fichier blob dans un nouvel onglet ou affiche un message d'erreur en cas de bloqueur de pub actif détecté
 * (L'ouverture de blob est bloquée par les bloqueurs de pub)
 *
 * @param {blob}	fileBlob => le blob à ouvrir
 */
const open = fileBlob =>
	new Promise((resolve, reject) => {
		if (adblockDetector.detect()) {
			reject(
				"Merci de désactiver votre bloqueur de pub pour accéder à l'aperçu des documents"
			)
		} else {
			window.open(URL.createObjectURL(fileBlob), "_blank")
			resolve()
		}
	})

/**
 * Télécharge un document
 *
 * @param {ObjectURL}	fileUrl => le document à télécharger
 * @param {string}		fileName => le nom à donner au document
 */
const download = (fileUrl, fileName) => saveAs(fileUrl, fileName)

/**
 * Télécharge une archive au format zip
 *
 * @param {array}	documents => les documents à intégrer dans l'archive
 * @param {string}	fileName => le nom à donner à l'archive
 */
const downloadZip = (documents, fileName) => {
	let zip = new JSZip()

	documents.map(document =>
		zip.file(document.nom || document.name, document.blob || document)
	)

	zip.generateAsync({ type: "blob" }).then(blob => download(blob, fileName))
}

/**
 * Convertit le poids d'un document afin qu'il soit lisible pour un humain
 *
 * @param {int}	bytes => le poids du document en bytes
 * @param {int} dp =>
 *
 * @return le poids du document lisible par un humain
 */
const convertFileSize = (bytes, dp = 1) => {
	const thresh = 1000

	if (Math.abs(bytes) < thresh) {
		return bytes + " B"
	}

	const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
	let u = -1
	const r = 10 ** dp

	do {
		bytes /= thresh
		++u
	} while (
		Math.round(Math.abs(bytes) * r) / r >= thresh &&
		u < units.length - 1
	)

	return bytes.toFixed(dp) + " " + units[u]
}

export default {
	open,
	download,
	downloadZip,
	convertFileSize
}
