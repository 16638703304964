import React, { useEffect } from "react"

import {
	Paper,
	Box,
	Typography,
	styled,
	TextField,
	InputAdornment,
	IconButton,
	Checkbox,
	Tooltip,
	Table,
	TableFooter,
	TableRow,
	TableCell,
	Button,
	Avatar,
	CircularProgress
} from "@material-ui/core"
import { useSnackbar } from "notistack"

import SearchIcon from "@material-ui/icons/Search"
import GetAppIcon from "@material-ui/icons/GetApp"

import useStateWithLabel from "../../../utils/useStateWithLabel"
import fileHandler from "../../../utils/fileHandler"

import VirtualizedTable from "../../../template/components/VirtualizedTable"
import { AvatarGroup } from "@material-ui/lab"

const TableHeader = styled(Box)(({ theme }) => ({
	padding: theme.spacing(1),
	borderBottom: `1px solid ${
		theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
	}`,

	"& > h6": {
		marginBottom: 0
	}
}))

const Searchbar = styled(TextField)(({ theme }) => ({
	boxShadow: theme.shadows[1],
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(0.2, 1),

	"& > div": {
		"& svg": {
			fill: theme.palette.grey[500]
		},

		"&::before, &::after": {
			display: "none"
		}
	}
}))

const CenteredColumn = styled("div")({
	paddingLeft: "0 !important",
	justifyContent: "center"
})

const ActionButton = styled(IconButton)(({ theme }) => ({
	padding: theme.spacing(1),
	margin: theme.spacing(0.25),

	"& svg": {
		fill: "#fff",
		fontSize: "18px"
	}
}))

const DownloadButton = styled(ActionButton)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main,

	"&:hover": {
		backgroundColor: theme.palette.secondary.dark
	}
}))

const TableFooterContainer = styled(Table)(({ theme }) => ({
	position: "sticky",
	left: 0,
	right: 0,

	borderBottomLeftRadius: theme.shape.borderRadius,
	borderBottomRightRadius: theme.shape.borderRadius,

	backgroundColor: theme.palette.background.paper,
	boxShadow:
		"rgb(0 0 0 / 20%) 0px -2px 1px -1px, rgb(0 0 0 / 14%) 0px -1px 1px 0px, rgb(0 0 0 / 12%) 0px -1px 3px 0px",

	padding: 0,
	margin: 0,

	"& td": {
		height: 52,
		border: 0,

		padding: theme.spacing(0, 0, 0, 1)
	}
}))

const ViewContent = ({ procedure }) => {
	const { enqueueSnackbar } = useSnackbar()

	const [search, setSearch] = useStateWithLabel("", "search")
	const [sortBy, setSortBy] = useStateWithLabel("order", "sortBy")
	const [sortDirection, setSortDirection] = useStateWithLabel(
		"ASC",
		"sortDirection"
	)
	const [documents, setDocuments] = useStateWithLabel(
		procedure.documents,
		"documents"
	)

	const changeDocumentsState = event => {
		event.stopPropagation()

		const selectedDocumentId = event.target.name
		const documentsToEdit = documents.filter(({ id }) =>
			!!selectedDocumentId ? +id === +selectedDocumentId : true
		)

		let tmpDocuments = [...documents]
		let newValue =
			documentsToEdit.length === 1 && !documentsToEdit[0].checked

		if (!selectedDocumentId) {
			const checkedCount = documents.filter(
				({ checked }) => checked
			).length

			newValue =
				checkedCount === 0 ||
				(checkedCount !== documents.length &&
					checkedCount >= documents.length / 2)
		}

		documentsToEdit.forEach(document => {
			let tmpDocument = { ...document }

			tmpDocuments.splice(
				tmpDocuments.findIndex(item => item.id === document.id),
				1,
				{
					...tmpDocument,
					checked: newValue
				}
			)
		})

		setDocuments(tmpDocuments)
	}

	const columns = [
		{
			width: 40,
			label: (
				<Checkbox
					color="primary"
					checked={
						documents.length > 0 &&
						!documents.some(document => !document.checked)
					}
					indeterminate={
						documents.length > 0 &&
						documents.some(document => document.checked) &&
						documents.some(document => !document.checked)
					}
					onClick={changeDocumentsState}
				/>
			),
			dataKey: "",
			disableSort: true,
			centered: true
		},
		{
			width: 340,
			label: "Nom",
			dataKey: "name"
		},
		{
			width: 340,
			label: "Signataires",
			dataKey: "signatories",
			disableSort: true
		},
		{
			width: 100,
			label: "Poids",
			dataKey: "size"
		},
		{
			width: 90,
			label: "Actions",
			dataKey: "actions",
			disableSort: true,
			centered: true
		}
	]

	const TableWrapper = styled("div")({
		minHeight: "calc(47px * 8)",
		height: `calc(47px * ${documents.length})`,
		maxHeight: "calc(47px * 8)",
		width: "100%"
	})

	const downloadDocument = event => {
		event.stopPropagation()

		const selectedDocumentId = event.currentTarget.name
		const selectedDocument = documents.find(
			({ id }) => id === selectedDocumentId
		)
		fileHandler.download(selectedDocument.blob, selectedDocument.nom)
	}

	const cellRenderer = ({ rowData, columnIndex }) => {
		const signatories = [
			...new Set(
				rowData.signatures.map(signature =>
					procedure.signataires.find(
						signatory => signatory.id === signature.id_contact
					)
				)
			)
		]

		switch (columnIndex) {
			case 0:
				return (
					<CenteredColumn>
						<Checkbox
							name={rowData.id}
							color="primary"
							onClick={changeDocumentsState}
							checked={rowData.checked}
						/>
					</CenteredColumn>
				)

			case 1:
				return <div>{rowData.nom || ""}</div>

			case 2:
				return (
					<div>
						<Tooltip
							title={signatories.map(contact => (
								<div key={contact.signatory_id}>
									<span>
										{contact.prenom} {contact.nom}
									</span>
								</div>
							))}>
							<AvatarGroup max={5}>
								{signatories.map(contact => (
									<Avatar key={contact.signatory_id}>
										{contact.prenom.toUpperCase().charAt(0)}
										{contact.nom.toUpperCase().charAt(0)}
									</Avatar>
								))}
							</AvatarGroup>
						</Tooltip>
					</div>
				)

			case 3:
				return (
					<div>
						{!!rowData.blob ? (
							fileHandler.convertFileSize(rowData.blob.size)
						) : (
							<CircularProgress size={30} />
						)}
					</div>
				)

			case 4:
				return (
					<CenteredColumn>
						<Tooltip title="Télécharger">
							<DownloadButton
								name={rowData.id}
								onClick={downloadDocument}>
								<GetAppIcon />
							</DownloadButton>
						</Tooltip>
					</CenteredColumn>
				)

			default:
				return <div></div>
		}
	}

	const downloadCheckedDocuments = () =>
		fileHandler.downloadZip(
			documents.filter(document => document.checked),
			procedure.nom
		)

	// Fonction permettant de trier le tableau
	const sortTable = table => {
		let tmpDocuments = [...table]

		switch (sortBy) {
			case "name":
				return tmpDocuments.sort((a, b) => {
					return sortDirection === "ASC"
						? a.nom.localeCompare(b.nom)
						: b.nom.localeCompare(a.nom)
				})

			case "size":
				return tmpDocuments.sort((a, b) => {
					return sortDirection === "ASC"
						? a.blob.size - b.blob.size
						: b.blob.size - a.blob.size
				})

			default:
				return tmpDocuments
		}
	}

	const getSearchedDocuments = () =>
		documents.filter(({ nom }) =>
			nom.toLowerCase().includes(search.toLowerCase())
		)

	const handleSearch = ({ target }) => setSearch(target.value)

	const handleRowClick = ({ rowData }) =>
		!!rowData.blob &&
		fileHandler.open(rowData.blob).catch(err =>
			{
				enqueueSnackbar(typeof err === 'string' ? err : "Erreur à l'ouverture du document", {
					variant: 'warning',
				});
			}
		)

	const getDocumentAtIndex = ({ index }) =>
		sortTable(getSearchedDocuments())[index]

	useEffect(() => {
		setDocuments(procedure.documents)
	}, [procedure, setDocuments])

	return (
		<Paper>
			<TableHeader
				display="flex"
				justifyContent="space-between"
				alignItems="center">
				<Typography variant="h6" color="secondary">
					Documents
				</Typography>
				<Searchbar
					placeholder="Rechercher..."
					value={search}
					onChange={handleSearch}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)
					}}
				/>
			</TableHeader>
			<TableWrapper>
				<VirtualizedTable
					isLoading={false}
					columns={columns}
					rowCount={getSearchedDocuments().length}
					rowGetter={getDocumentAtIndex}
					cellRenderer={cellRenderer}
					onRowClick={handleRowClick}
					sortBy={sortBy}
					setSortBy={setSortBy}
					sortDirection={sortDirection}
					setSortDirection={setSortDirection}
				/>
			</TableWrapper>
			<TableFooterContainer>
				<TableFooter>
					<TableRow>
						<TableCell>
							<Button
								variant="contained"
								color="secondary"
								onClick={downloadCheckedDocuments}
								disabled={
									!documents.some(
										document => document.checked
									)
								}>
								Télécharger
							</Button>
						</TableCell>
					</TableRow>
				</TableFooter>
			</TableFooterContainer>
		</Paper>
	)
}

export default ViewContent
