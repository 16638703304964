/**
 * @author 	Romain Garcia
 * @version	2020/09/02
 * @see		"./reducer"
 */

// SOCIETE
/**
 * Récupération de la société
 *
 * @param {object}	state => partie du Store concernée
 */
const getSociety = state => state.society.society
/**
 * Récupération des procédures
 *
 * @param {object}	state => partie du Store concernée
 */
const getProcedures = state => state.society.procedures
/**
 * Récupération des procédures archivées
 *
 * @param {object}	state => partie du Store concernée
 */
const getArchivedProcedures = state => state.society.archivedProcedures
/**
 * Récupération des procédures en cours d'utilisations
 *
 * @param {object}	state => partie du Store concernée
 */
const getUsedProcedures = state => state.society.usedProcedures
/**
 * Récupération des procédures récemment mises à jour
 *
 * @param {object}	state => partie du Store concernée
 */
const getRecentlyUpdatedProcedures = state =>
	state.society.recentlyUpdatedProcedures
/**
 * Récupération de l'état d'utilisation de la procédure fournie
 *
 * @param {object} state 	=> partie du Store concernée
 * @param {object} {
 * 	{string}	procedureId	=> identifiant unique de la procédure
 * }
 */
const isProcedureUsed = (state, { procedureId }) =>
	state.society.usedProcedures.some(id => id === procedureId)
/**
 * Récupération du nombre de signature sur l'année en cours
*/
const getCurrentYearSignatures = state => state.society.currentYearSignatures

// PROCEDURE
/**
 * Récupération de l'état d'ouverture de la dialog de visualisation/modification d'une procédure
 *
 * @param {object}	state => partie du Store concernée
 */
const isDialogOpen = state => state.procedure.isDialogOpen
/**
 * Récupération du mode de la dialog de visualisation/modification d'une procédure
 *
 * @param {object}	state => partie du Store concernée
 */
const getDialogMode = state => state.procedure.dialogMode
/**
 * Récupération de la procédure
 *
 * @param {object}	state => partie du Store concernée
 */
const getProcedure = state => state.procedure.procedure

// DEPOT DE FICHIER
/**
 * Récupération de l'état d'ouverture de la dialog de dépôt de documents
 *
 * @param {object}	state => partie du Store concernée
 */
const isUploadDocumentDialogOpen = state => state.uploadDocument.isDialogOpen

export default {
	getSociety,
	getProcedures,
	getArchivedProcedures,
	getUsedProcedures,
	getRecentlyUpdatedProcedures,
	isProcedureUsed,
	getCurrentYearSignatures,

	isDialogOpen,
	getDialogMode,
	getProcedure,

	isUploadDocumentDialogOpen,
};
