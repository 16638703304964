export const measureTextWidth = (text, fontSize, fontFamily) => {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	if (context) {
		context.font = `${fontSize}px ${fontFamily}`;
		return context.measureText(text).width;
	}

	return 0;
};

export default {};
