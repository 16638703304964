import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDrop } from "react-dnd";
import { styled } from "@material-ui/core";

import Signature from "./Signature";
import DocumentViewer from "./DocumentViewer";

const DocumentContainer = styled("div")({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	flex: 1,
	position: "relative",
});

const SignatureCanvas = ({
	document,
	dndItems,
	pagesInfos,
	onPagesInfosChange,
	onDrop,
	onSignatureDelete,
	onSignatureResize,
}) => {
	const { pageNumber } = pagesInfos;

	const documentRef = useRef(null);
	const pagesInfosRef = useRef(pagesInfos);

	const adjustPositionToBounds = useCallback((position) => {
		let { x, y } = position;
		const { width, height } = position;

		if (x < 2) {
			x = 2;
		}
		if (y - 12 <= 0) {
			y = 12;
		}
		if (x + width + 14 >= pagesInfosRef.current.width) {
			x = pagesInfosRef.current.width - width - 14;
		}
		if (y + height + 4 >= pagesInfosRef.current.height) {
			y =	pagesInfosRef.current.height - height - 4;
		}

		return { ...position, x, y };
	}, []);

	const [, drop] = useDrop(() => ({
		accept: "signature",
		drop: ({ id, signatory, position }, monitor) => {
			const { x = 0, y = 0, width = 0, height = 0 } = position || {};
			const offset = monitor.getClientOffset();
			const initialSourceOffset = monitor.getInitialSourceClientOffset();

			if (documentRef.current && offset && initialSourceOffset) {
				const documentRect =
					documentRef.current.getBoundingClientRect();
				const newX = offset.x - documentRect.left - x;
				const newY =
					offset.y -
					documentRect.top +
					documentRef.current.scrollTop -
					y;
				const newPosition = adjustPositionToBounds({
					x: newX,
					y: newY,
					width,
					height,
				});

				onDrop({ id, signatory, position: newPosition });
			}
		},
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
			canDrop: !!monitor.canDrop(),
		}),
	}));

	const signaturesRenderer = useMemo(
		() =>
			dndItems.reduce((acc, item) => {
				const {
					position,
					pageNumber: signaturePageNumber,
				} = item;

				if (!!position && pageNumber === signaturePageNumber) {
					return [
						...acc,
						<Signature
							{...item}
							key={item.id}
							dndItems={dndItems}
							pagesInfos={pagesInfos}
							onSignatureDelete={onSignatureDelete}
							onSignatureResize={onSignatureResize}
						/>
					];
				}

				return acc;
			}, []),
		[
			dndItems,
			onSignatureDelete,
			onSignatureResize,
			pageNumber,
			pagesInfos,
		]
	);

	useEffect(() => {
		if (documentRef.current) {
			drop(documentRef);
		}
	}, [drop]);

	useEffect(() => {
		if (pagesInfosRef.current) {
			pagesInfosRef.current = pagesInfos;
		}
	}, [pagesInfos]);

	return (
		<DocumentContainer>
			<DocumentViewer
				document={document}
				onPagesInfosChange={onPagesInfosChange}
				ref={documentRef}
				pageNumber={pageNumber}
			>
				{signaturesRenderer}
			</DocumentViewer>
		</DocumentContainer>
	);
};

export default SignatureCanvas;
