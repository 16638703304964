/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 */

// LISTE DE CONTACTS
const SET_CONTACTS = "app/contact/SET_CONTACTS"
const REMOVE_CONTACT = "app/contact/REMOVE_CONTACT"

// CONTACT
const OPEN_CONTACT_DIALOG = "app/contact/OPEN_CONTACT_DIALOG"
const CLOSE_CONTACT_DIALOG = "app/contact/CLOSE_CONTACT_DIALOG"
const SET_DIALOG_MODE = "app/contact/SET_DIALOG_MODE"
const SET_CONTACT = "app/contact/SET_CONTACT"

export default {
	SET_CONTACTS,
	REMOVE_CONTACT,

	OPEN_CONTACT_DIALOG,
	CLOSE_CONTACT_DIALOG,
	SET_DIALOG_MODE,
	SET_CONTACT
}