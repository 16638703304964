/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 * @see		"./types"
 */

import types from "./types"

// THEME
/**
 * Change le thème du site ("light", "dark")
 *
 * @param {string}	theme => thème a appliquer ("light", "dark")
 */
const changeTheme = theme => ({
	type: types.CHANGE_THEME,
	payload: theme
})

// MENU DE PROFIL
/**
 * Ouvre/Ferme le menu de profil
 *
 * @param {DOMElement}	anchorEl
 */
const toggleProfileMenu = anchorEl => ({
	type: types.TOGGLE_PROFILE_MENU,
	payload: anchorEl
})

// DIALOG DE PARAMETRES
/**
 * Ouvre/Ferme la dialog des paramètres
 */
const toggleSettingsDialog = () => ({
	type: types.TOGGLE_SETTINGS_DIALOG
})

// DIALOG DE CONFIRMATION
/**
 * Ouvre la dialog de confirmation
 *
 * @param {object}	props =>
 * 						{string}	content: texte du contenu de la dialog,
 * 					   	{function} 	action: action à effectuer à la validation
 */
const openConfirmationDialog = props => ({
	type: types.OPEN_CONFIRMATION_DIALOG,
	payload: props
})
/**
 * Ferme la dialog de confirmation
 */
const closeConfirmationDialog = () => ({
	type: types.CLOSE_CONFIRMATION_DIALOG
})

// SOCKET
/**
 * Inscrit la socket dans le Store
 *
 * @param {object}	socket
 */
const setSocket = socket => ({
	type: types.SET_SOCKET,
	payload: socket
})

export default {
	changeTheme,

	toggleProfileMenu,

	toggleSettingsDialog,

	openConfirmationDialog,
	closeConfirmationDialog,

	setSocket
}
