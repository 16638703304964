/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 * @see		"./actions"
 */

import actions from "./actions"

// THEME
/**
 * Appelle l'action qui change le thème du site ("light", "dark")
 *
 * @param {string}	theme => thème a appliquer ("light", "dark")
 */
const changeTheme = theme => dispatch => dispatch(actions.changeTheme(theme))

// MENU DE PROFIL
/**
 * Appelle l'action qui ouvre/ferme le menu de profil
 *
 * @param {DOMElement}	anchorEl
 */
const toggleProfileMenu = anchorEl => dispatch =>
	dispatch(actions.toggleProfileMenu(anchorEl))

// DIALOG DE PARAMETRES
/**
 * Appelle l'action qui ouvre/ferme la dialog des paramètres
 */
const toggleSettingsDialog = () => dispatch =>
	dispatch(actions.toggleSettingsDialog())

// DIALOG DE CONFIRMATION
/**
 * Appelle l'action qui ouvre la dialog de confirmation
 *
 * @param {object}	props =>
 * 						{string}	content: texte du contenu de la dialog,
 * 					   	{function} 	action: action à effectuer à la validation
 */
const openConfirmationDialog = props => dispatch =>
	dispatch(actions.openConfirmationDialog(props))
/**
 * Appelle l'action qui ferme la dialog de confirmation
 */
const closeConfirmationDialog = () => dispatch =>
	dispatch(actions.closeConfirmationDialog())

// SOCKET
/**
 * Appelle l'action qui inscrit la socket dans le Store
 *
 * @param {object}	socket
 */
const setSocket = socket => dispatch => dispatch(actions.setSocket(socket))

export default {
	changeTheme,

	toggleProfileMenu,

	toggleSettingsDialog,

	openConfirmationDialog,
	closeConfirmationDialog,

	setSocket
}
