/**
 * @author	Romain Garcia
 * @version	2020/07/23
 */

/**
 * Détecte la présence d'un bloqueur de pub actif
 *
 * @return
 * 		true	=> un bloqueur de pub actif a été détécté
 * 		false 	=> aucun bloqueur de pub actif détecté
 */
const detect = () => {
	let adElement = document.getElementsByClassName("adBanner")[0]
	let adElementStyle = getComputedStyle(adElement, null)

	return adElementStyle.display.includes("none")
}

export default {
	detect
}
