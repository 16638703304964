import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { CssBaseline, ThemeProvider, createTheme, responsiveFontSizes } from '@material-ui/core';

import pages from './utils/pagesConstants';
import { default as socketActions } from './utils/socket';
import lightTheme from './utils/style/light_theme';

import Layout from './template/Layout';

import { generalSelectors } from './modules/general';
import { userOperations } from './modules/user';

const App = ({ keycloak }) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const socket = useSelector(state => generalSelectors.getSocket(state.general));

	const [theme, setTheme] = useState(createTheme(lightTheme));

	const renderHomeRedirection = () => <Redirect to={pages.HOME.endpoint} />;

	const instanciateSocket = useCallback(
		({ url, endpoint }) => {
			const socketConnectionInfo = enqueueSnackbar('Connexion au serveur en cours...', {
				variant: 'info',
				persist: true,
			});

			socketActions.connect({ url, endpoint }).then(connected => {
				if (connected) {
					socketActions
						.authenticate(dispatch, keycloak.token)
						.then(() => {
							closeSnackbar(socketConnectionInfo);

							dispatch(userOperations.fetchUserInfos(keycloak));
							socketActions.listen('disconnect', () =>
								enqueueSnackbar(
									'La connexion avec le serveur a été interrompue. Veuillez rafraîchir la page.',
									{
										variant: 'warning',
										persist: true,
									}
								)
							);
						})
						.catch(err => {
							closeSnackbar(socketConnectionInfo);

							enqueueSnackbar(err, {
								variant: 'error',
							});
						});
				} else {
					enqueueSnackbar('La connexion au serveur a échoué. Veuillez rafraîchir la page.', {
						variant: 'error',
						persist: true,
					});
				}
			});
		},
		[closeSnackbar, dispatch, enqueueSnackbar, keycloak]
	);

	// Stockage de l'instance Keycloak dans le Store
	useEffect(() => {
		dispatch(userOperations.setKeycloakInstance(keycloak));
	}, [dispatch, keycloak]);

	// Gestion de la socket
	useEffect(() => {
		if (!!socket) {
			socket.removeAllListeners();
		} else {
			fetch('/config.json')
				.then(res => res.json())
				.then(({ api }) => instanciateSocket({ ...api }));
		}
	}, [dispatch, socket, keycloak, enqueueSnackbar, closeSnackbar, instanciateSocket]);

	useEffect(() => {
		(async () => {
			const colors = await fetch('/theme/colors.json').then(res => res.json());

			setTheme(
				createTheme({
					...lightTheme,
					palette: {
						primary: {
							main: colors.primary,
						},
						secondary: {
							main: colors.secondary,
						},
					},
				})
			);
		})();
	}, []);

	return (
		<ThemeProvider theme={responsiveFontSizes(theme)}>
			<CssBaseline />

			<Switch>
				{Object.entries(pages).map(entry => {
					const { name, endpoint } = entry[1];

					return <Route key={name} path={endpoint} render={() => <Layout page={name} withAppbar />} />;
				})}
				<Route path="" render={renderHomeRedirection} />
			</Switch>
		</ThemeProvider>
	);
};

export default App;
