import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, styled } from '@material-ui/core';

import ProcedureDialog from './procedure/ProcedureDialog';
import ProcedureTable from './procedure/ProcedureTable';
import DrawPenIcon from '../assets/svg/DrawPenIcon';

import { generalSelectors } from '../modules/general';
import { procedureOperations, procedureSelectors } from '../modules/procedure';

import { default as socketActions } from '../utils/socket';

const Wrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'flex-end',
	flexWrap: 'wrap',

	gap: spacing(1),
	marginLeft: spacing(2),
}));

const KpiContainer = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	height: 75,
	minWidth: 215,

	display: 'flex',
	alignItems: 'center',
	gap: spacing(2),

	borderRadius: shape.borderRadius * 2,
	border: `1px solid ${palette.divider}`,

	padding: spacing(1),

	boxSizing: 'border-box',

	'&.error': {
		backgroundColor: `${palette.error.main}1A`,
		color: palette.error.main,
		borderColor: palette.error.main,

		'& > div:first-of-type': {
			backgroundColor: 'transparent',
			color: 'inherit',
		},
	},
}));

const IconContainer = styled('div', {
	shouldForwardProp: props => props !== 'color',
})(({ color, theme: { palette, shape } }) => {
	const backgroundColor = palette[color].main;

	return {
		height: '100%',
		aspectRatio: '1/1',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: shape.borderRadius,

		backgroundColor,
		color: color ? palette.getContrastText(backgroundColor) : 'inherit',
	};
});

const TODAY_DATE = new Date();

const Procedure = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const socket = useSelector(state => generalSelectors.getSocket(state.general));
	const society = useSelector(state => procedureSelectors.getSociety(state.procedure));
	const procedure = useSelector(state => procedureSelectors.getProcedure(state.procedure));
	const currentYearSignatures = useSelector(state => procedureSelectors.getCurrentYearSignatures(state.procedure));

	const updateVisualisedProcedure = useCallback(
		({ isVisualisedProcedure, updatedProcedureId, newProcedures }) =>
			isVisualisedProcedure
				? dispatch(
						procedureOperations.setSelectedProcedure(
							newProcedures.find(({ id }) => id === updatedProcedureId)
						)
				  )
				: dispatch(procedureOperations.addRecentlyUpdatedProcedure(updatedProcedureId)),
		[dispatch]
	);

	const formatUpdateSnackbar = ({ isVisualisedProcedure, updateType }) => {
		let snackbarVariant = 'info';
		let message = '';

		if (isVisualisedProcedure) {
			message += 'La procédure que vous visualisez a été ';
		} else {
			message += 'Une procédure a été ';
		}

		switch (updateType) {
			case 'created':
				message += 'ajouté';

				break;

			case 'updated':
				if (isVisualisedProcedure) {
					snackbarVariant = 'warning';
				}
				message += 'mise à jour';

				break;

			case 'deleted':
				if (isVisualisedProcedure) {
					snackbarVariant = 'warning';
				}
				message += 'supprimée';

				break;

			case 'archived':
				message += 'archivée';

				break;

			case 'restored':
				message += 'restaurée';

				break;

			default:
				break;
		}

		return { snackbarVariant, message };
	};

	// Gestion des mises à jours des procédures
	useEffect(() => {
		if (socket?.authenticated && society.id) {
			socketActions.listen('procedureUpdated', res => {
				const isVisualisedProcedure = procedure?.id.toString() === res.id.toString();

				const { snackbarVariant, message } = formatUpdateSnackbar({
					isVisualisedProcedure,
					updateType: res.type,
				});

				enqueueSnackbar(message, {
					variant: snackbarVariant,
				});

				if (res.type === 'archived') {
					dispatch(procedureOperations.fetchArchivedProcedures(society.id)).then(newArchivedProcedures => {
						dispatch(procedureOperations.fetchProcedures(society.id));

						updateVisualisedProcedure({
							isVisualisedProcedure,
							updatedProcedureId: res.id,
							newProcedures: newArchivedProcedures,
						});
					});
				} else {
					dispatch(procedureOperations.fetchProcedures(society.id)).then(newProcedures =>
						updateVisualisedProcedure({
							isVisualisedProcedure,
							updatedProcedureId: res.id,
							newProcedures,
						})
					);
				}
			});

			socketActions.listen('usedProceduresUpdated', () =>
				dispatch(procedureOperations.fetchUsedProcedures(society.id))
			);
		}
	}, [dispatch, enqueueSnackbar, updateVisualisedProcedure, socket, society.id, procedure?.id]);

	useEffect(() => {
		if (socket?.authenticated && society.id) {
			dispatch(procedureOperations.fetchCurrentYearSignatures(society.id));
		}
	}, [dispatch, society]);

	return (
		<Wrapper>
			<KpiContainer>
				<IconContainer color="secondary">
					<DrawPenIcon />
				</IconContainer>
				<Box display="flex" flexDirection="column" justifyContent="center">
					<Typography fontWeight={300} variant="subtitle2">
						Signatures en {TODAY_DATE.getFullYear()}
					</Typography>
					<Typography component="span" variant="h5">
						{currentYearSignatures}
					</Typography>
				</Box>
			</KpiContainer>
			<ProcedureTable />
			{!!procedure && <ProcedureDialog />}
		</Wrapper>
	);
};

export default Procedure;
