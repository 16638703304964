import React from "react"

import { AutoSizer, Table, Column, SortIndicator } from "react-virtualized"

import useStateWithLabel from "../../utils/useStateWithLabel"
import {
	makeStyles,
	styled,
	Box,
	Typography,
	CircularProgress
} from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
	table: {
		"& > div:first-child": {
			display: "flex",
			cursor: "default",
			backgroundColor:
				theme.palette.grey[theme.palette.type === "light" ? 100 : 800],
			boxShadow: theme.shadows[1]
		}
	},
	row: {
		display: "flex",
		cursor: "pointer",
		transition: "background-color .2s ease-in-out",
		borderBottom: `1px solid ${
			theme.palette.grey[theme.palette.type === "light" ? 300 : 700]
		}`,

		"& .ReactVirtualized__Table__rowColumn": {
			display: "flex",
			flex: "1",

			"& > div": {
				flex: "1",
				paddingLeft: theme.spacing(2),
				display: "flex",
				alignItems: "center"
			}
		},

		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.04)"
		}
	},
	headerCell: {}
}))

const TableBody = styled(Box)({
	height: "100%"
})

const VirtualizedTable = ({
	isLoading,
	sortBy,
	sortDirection,
	setSortBy,
	setSortDirection,
	rowCount,
	cellRenderer,
	onRowClick,
	columns,
	...tableProps
}) => {
	const classes = useStyles()

	const [headerHeight] = useStateWithLabel(38, "headerHeight")
	const [rowHeight] = useStateWithLabel(47, "rowHeight")

	const HeaderCell = styled(Box)(({ theme }) => ({
		height: headerHeight,
		padding: theme.spacing(0, 2),

		"&.sortable": {
			cursor: "pointer"
		},

		"&.centered": {
			justifyContent: "center",
			padding: 0
		}
	}))

	// Fonction permettant de trier le tableau
	const sort = ({ sortBy, sortDirection }) => {
		setSortBy(sortBy)
		setSortDirection(sortDirection)
	}

	// Fonction générant le rendu des cellules en-tête du tableau
	const headerRenderer = ({ dataKey, label }) => {
		return (
			<HeaderCell
				display="flex"
				alignItems="center"
				className={clsx({
					sortable: !columns.find(column => column.dataKey === dataKey).disableSort,
					centered: columns.find(column => column.dataKey === dataKey).centered,
				})}
			>
				<Typography variant="subtitle2">{label}</Typography>
				{sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
			</HeaderCell>
		);
	}

	// Fonction générant le rendu du contenu du tableau vide
	const noRowsRenderer = () => {
		return (
			<TableBody
				display="flex"
				justifyContent="center"
				alignItems="center">
				{isLoading ? <CircularProgress size={40} /> : "Aucunes données"}
			</TableBody>
		)
	}

	return (
		<AutoSizer>
			{({ height, width }) => (
				<Table
					height={height}
					width={width}
					headerHeight={headerHeight}
					rowHeight={rowHeight}
					rowCount={rowCount}
					className={classes.table}
					rowClassName={classes.row}
					onRowClick={onRowClick}
					noRowsRenderer={noRowsRenderer}
					sort={sort}
					sortBy={sortBy}
					sortDirection={sortDirection}
					gridStyle={{
						direction: "inherit"
					}}
					{...tableProps}>
					{columns.map(({ dataKey, ...other }, index) => (
						<Column
							key={dataKey}
							headerRenderer={headerProps =>
								headerRenderer({
									...headerProps,
									columnIndex: index
								})
							}
							cellRenderer={cellRenderer}
							dataKey={dataKey}
							{...other}
						/>
					))}
				</Table>
			)}
		</AutoSizer>
	)
}

export default VirtualizedTable
