/**
 * @author	Romain Garcia
 * @version	2020/08/07
 */

import Cookies from "universal-cookie"

import { encrypt, decrypt } from "./encryption"

const cookies = new Cookies()

/**
 * Récupère la valeur du cookie correspondant au nom renseigné
 * 
 * @param {string}	name => nom du cookie à récupéré 
 * 
 * @return {string} la valeur du cookie decrypté
 */
export const getCookie = name => {
	const cookie = cookies.get(name)

	return !!cookie && decrypt(cookies.get(name))
}

/**
 * Stocke une valeur en cookie avec le nom renseigné
 * 
 * @param {string}	name => nom du cookie à stocker
 * @param {*}		value => valeur à stocker
 */
export const setCookie = (name, value) => {
	cookies.set(name, encrypt(value), {
		path: "/",
		sameSite: true
	})
}

/**
 * Supprime un cookie correspondant au nom renseigné
 * 
 * @param {string}	name => nom du cookie à supprimer 
 */
export const removeCookie = name => {
	cookies.remove(name)
}