import React, { useState } from 'react';

import { IconButton, styled, Tooltip, SvgIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',

	gap: theme.spacing(1),

	paddingLeft: '0 !important',
}));

const ActionButton = styled(IconButton)(({ theme: { palette, spacing } }) => ({
	padding: spacing(1),
	margin: spacing(0.25),

	'& svg': {
		fill: '#fff',
		fontSize: '18px',
	},

	'&.Mui-disabled': {
		backgroundColor: palette.type === 'light' ? 'rgba(0, 0, 0, .12)' : 'rgba(255, 255, 255, .12)',

		'& svg': {
			fill: palette.type === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
		},
	},

	'&.delete': {
		backgroundColor: '#F44336',

		'&:hover': {
			backgroundColor: '#D32F2F',
		},
	},

	'&.archive': {
		backgroundColor: '#9E9E9E',

		'&:hover': {
			backgroundColor: '#616161',
		},
	},

	'&.unarchive': {
		backgroundColor: palette.primary.main,

		'&:hover': {
			backgroundColor: palette.primary.dark,
		},
	},

	'&.remind': {
		backgroundColor: palette.primary.main,

		'&:hover': {
			backgroundColor: palette.primary.dark,
		},
	},
}));

const MenuIcon = styled(SvgIcon)({
	marginRight: '.5rem',
});

const ActionCell = ({ procedureId, actions, isProcedureUsed }) => {
	const [firstAction, ...menuActions] = actions;

	const [anchorEl, setAnchorEl] = useState(null);

	const openActionMenu = event => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const closeActionMenu = () => setAnchorEl(null);

	return (
		<Wrapper>
			<Tooltip
				title={
					isProcedureUsed ? 'Cette procédure est déjà utilisée par un autre utilisateur' : firstAction.label
				}
			>
				<span>
					<ActionButton
						className={firstAction.id}
						name={procedureId}
						disabled={firstAction.needsUniqueUsage ? isProcedureUsed : false}
						onClick={firstAction.callback}
					>
						<SvgIcon component={firstAction.icon} />
					</ActionButton>
				</span>
			</Tooltip>
			{menuActions.length > 0 && (
				<>
					<IconButton onClick={openActionMenu} size="small">
						<MoreHorizIcon />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={!!anchorEl}
						onClose={closeActionMenu}
						disableAutoFocusItem
					>
						{menuActions.map(({ id, callback, icon, label, needsUniqueUsage }) => (
							<Tooltip
								key={id}
								title={
									isProcedureUsed ? 'Cette procédure est déjà utilisée par un autre utilisateur' : ''
								}
							>
								<span>
									<MenuItem
										name={procedureId}
										disabled={needsUniqueUsage && isProcedureUsed}
										onClick={callback}
									>
										<MenuIcon component={icon} />
										<Typography>{label}</Typography>
									</MenuItem>
								</span>
							</Tooltip>
						))}
					</Menu>
				</>
			)}
		</Wrapper>
	);
};

export default ActionCell;
