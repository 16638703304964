import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Box, Button, TextField, styled } from "@material-ui/core";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Autocomplete } from "@material-ui/lab";

import BusinessIcon from "@material-ui/icons/Business";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";

import { contactSelectors } from "../../../modules/contact";
import { userSelectors } from "../../../modules/user";

const PrivacyButton = styled(Button)({
	width: 100,
});

const PrivateButton = styled(PrivacyButton)({
	borderTopRightRadius: 0,
	borderBottomRightRadius: 0,
});

const PublicButton = styled(PrivacyButton)({
	borderTopLeftRadius: 0,
	borderBottomLeftRadius: 0,
});

const ContactAvatar = styled(Avatar)(({ theme }) => ({
	height: theme.spacing(7),
	width: theme.spacing(7),
	fontSize: "x-large",
}));

const FormRow = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	marginBottom: theme.spacing(2),

	"& > div:not(:last-child)": {
		marginRight: theme.spacing(1),
	},

	"& > svg": {
		margin: theme.spacing(0, 3, 0, 2),
		fill: theme.palette.type === "light" ? theme.palette.grey[700] : "#fff",
	},
}));

let phoneFormatCheckTimer;

const EditionContent = ({ mode, contact, setContact, onError }) => {
	const phoneUtil = PhoneNumberUtil.getInstance();

	const [phoneNumberInfos, setPhoneNumberInfos] = useState({
		error: false,
		helperText: "",
	});

	const societies = useSelector((state) =>
		userSelectors.getSocieties(state.user)
	);

	const creationMode = useSelector((state) =>
		contactSelectors.getCreationMode(state.contact)
	);

	const editContact = (field, value) => {
		if (field === "") {
			setContact(value);
		} else {
			let tmpProcedure = { ...contact };

			tmpProcedure[field] = value;

			setContact(tmpProcedure);

			if (field === "telephone") {
				clearTimeout(phoneFormatCheckTimer);
				let number = null;

				try {
					number = phoneUtil.parse(value, "FR");
				} catch (error) {
					return;
				}

				if (!number || (number && !phoneUtil.isValidNumber(number))) {
					phoneFormatCheckTimer = setTimeout(() => {
						onError(true);
						setPhoneNumberInfos({
							error: true,
							helperText: "Numéro de téléphone invalide",
						});
					}, 1000);
				} else {
					onError(false);
					setPhoneNumberInfos({ error: false, helperText: "" });
				}
			}
		}
	};

	const switchToPrivate = () => editContact("prive", "1");

	const switchToPublic = () => editContact("prive", "0");

	const formatSocietyOptionLabel = (option) =>
		option.id + " - " + option.label;

	const handleTextFieldEdition = ({ target }) =>
		editContact(target.name, target.value);

	const renderSocietyInput = (params) => (
		<TextField {...params} label="Dossier" variant="outlined" />
	);

	const handleSocietySelection = (event, value) =>
		!!value && editContact("n_dossier", value.id);

	useEffect(() => {
		societies.length === 1 && editContact("n_dossier", societies[0].id);
	}, [societies]);

	return (
		contact && (
			<Fragment>
				<FormRow justifyContent="center">
					<PrivateButton
						variant={
							contact.prive === "1" ? "contained" : "outlined"
						}
						color="primary"
						onClick={switchToPrivate}
					>
						Privé
					</PrivateButton>
					<PublicButton
						variant={
							!contact.prive || contact.prive === "0"
								? "contained"
								: "outlined"
						}
						color="primary"
						onClick={switchToPublic}
					>
						Publique
					</PublicButton>
				</FormRow>
				<FormRow>
					<ContactAvatar>
						{contact.prenom.charAt(0)}
						{contact.nom.charAt(0)}
					</ContactAvatar>
					<TextField
						name="prenom"
						label="Prénom"
						value={contact.prenom}
						onChange={handleTextFieldEdition}
						variant="outlined"
					/>
					<TextField
						name="nom"
						label="Nom"
						value={contact.nom}
						onChange={handleTextFieldEdition}
						variant="outlined"
					/>
				</FormRow>
				<FormRow>
					<EmailIcon />
					<TextField
						name="email"
						label="Adresse mail"
						value={contact.email}
						onChange={handleTextFieldEdition}
						variant="outlined"
						fullWidth
					/>
				</FormRow>
				<FormRow>
					<CallIcon />
					<TextField
						name="telephone"
						label="Numéro de téléphone"
						value={contact.telephone}
						onChange={handleTextFieldEdition}
						variant="outlined"
						error={!!phoneNumberInfos.error}
						helperText={phoneNumberInfos.helperText}
						fullWidth
					/>
				</FormRow>
				{mode === "create" &&
					creationMode === "general" &&
					societies.length > 1 && (
						<FormRow>
							<BusinessIcon />
							<Autocomplete
								options={societies}
								getOptionLabel={formatSocietyOptionLabel}
								renderInput={renderSocietyInput}
								onChange={handleSocietySelection}
								fullWidth
								blurOnSelect
								autoHighlight
							/>
						</FormRow>
					)}
			</Fragment>
		)
	);
};

export default EditionContent;
