import React, { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import clsx from "clsx"

import {
	Dialog,
	styled,
	DialogContent,
	CircularProgress
} from "@material-ui/core"

import {
	procedureSelectors,
	procedureOperations
} from "../../modules/procedure"
import { generalOperations } from "../../modules/general"

import ViewContent from "./procedureDialog/ViewContent"
import EditionContent from "./procedureDialog/EditionContent"

import useStateWithLabel from "../../utils/useStateWithLabel"
import ProcedureDialogHeader from "./procedureDialog/ProcedureDialogHeader"
import ProcedureDialogActions from "./procedureDialog/ProcedureDialogActions"

const Container = styled(Dialog)({
	"& > div > div": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		minHeight: 617,
		maxHeight: 617,

		"& > header + div": {
			flex: 1
		}
	}
})

const ProcedureContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	overflowY: "hidden",

	"&.paddingTop": {
		paddingTop: theme.spacing(10)
	}
}))

const LoadingWrapper = styled("div")({
	backgroundColor: "rgba(0, 0, 0, .3)",

	position: "absolute",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: 9999,

	display: "flex",
	justifyContent: "center",
	alignItems: "center"
})

const ProcedureDialog = () => {
	const dispatch = useDispatch()

	const procedure = useSelector(state =>
		procedureSelectors.getProcedure(state.procedure)
	)
	const isOpen = useSelector(state =>
		procedureSelectors.isDialogOpen(state.procedure)
	)
	const mode = useSelector(state =>
		procedureSelectors.getDialogMode(state.procedure)
	)
	const isProcedureUsed = useSelector(state =>
		procedureSelectors.isProcedureUsed(state.procedure, {
			procedureId: procedure?.id
		})
	)

	const [isLoading, setIsLoading] = useStateWithLabel(false, "isLoading")
	const [editedProcedure, setEditedProcedure] = useStateWithLabel(
		null,
		"editedProcedure"
	)

	const closeDialog = () => {
		dispatch(procedureOperations.closeProcedureDialog());

		isProcedureUsed && dispatch(procedureOperations.freeProcedure(procedure.id));

		dispatch(procedureOperations.setDialogMode('view'));

		setEditedProcedure(null);

		setIsLoading(false);
	};

	const handleClose = () =>
		dispatch(
			generalOperations.openConfirmationDialog({
				content: 'Êtes-vous sûr de vouloir quitter cette procédure ?',
				action: closeDialog,
			})
		);

	const renderDialogContent = () =>
		mode === 'view' ? (
			<ViewContent procedure={procedure} />
		) : (
			<EditionContent mode={mode} procedure={editedProcedure} setProcedure={setEditedProcedure} />
		);

	const getDocumentsBlobs = useCallback(
		documentsWithoutBlob => {
			if (documentsWithoutBlob.length === 0) {
				return null;
			}

			let promises = [];

			documentsWithoutBlob.forEach(document =>
				promises.push(dispatch(procedureOperations.fetchDocumentBlob(document)))
			);

			Promise.allSettled(promises).then(res => {
				let tmpDocuments = [...procedure.documents];

				res.filter(({ status }) => status === 'fulfilled').forEach(
					({ value }, index) =>
						(tmpDocuments[index] = {
							...tmpDocuments[index],
							blob: value,
						})
				);

				dispatch(
					procedureOperations.updateProcedure({
						procedure: { ...procedure, documents: tmpDocuments },
						mode: 'storeOnly',
					})
				);
				dispatch(
					procedureOperations.setSelectedProcedure({
						...procedure,
						documents: tmpDocuments,
					})
				);
			});
		},
		[dispatch, procedure]
	);

	// Récupération des blobs des documents
	useEffect(() => {
		setEditedProcedure({ ...procedure });

		getDocumentsBlobs(procedure.documents.filter(({ blob }) => !blob || blob.toString().includes('ArrayBuffer')));
	}, [setEditedProcedure, getDocumentsBlobs, procedure]);

	// Gestion de l'état d'utilisation de la procédure en fonction du mode de la dialog
	useEffect(() => {
		if (mode === "view" && isProcedureUsed) {
			dispatch(procedureOperations.freeProcedure(procedure.id))
		}

		if (mode === "edit" && !isProcedureUsed) {
			dispatch(procedureOperations.useProcedure(procedure.id))
		}
	}, [dispatch, mode, isProcedureUsed, procedure?.id])

	return (
		!!procedure && (
			<Container
				open={isOpen}
				onClose={handleClose}
				maxWidth="md"
				fullWidth>
				<ProcedureDialogHeader
					mode={mode}
					id={procedure.id}
					title={procedure.nom}
					comment={procedure.commentaire}
					state={procedure.etat}
				/>
				<ProcedureContent
					className={clsx({
						paddingTop: mode === "view"
					})}>
					{isLoading && (
						<LoadingWrapper
							onClick={event => event.stopPropagation()}>
							<CircularProgress size={60} />
						</LoadingWrapper>
					)}
					{renderDialogContent()}
				</ProcedureContent>
				<ProcedureDialogActions
					handleClose={handleClose}
					mode={mode}
					procedure={mode === "view" ? procedure : editedProcedure}
					setEditedProcedure={setEditedProcedure}
					setIsLoading={setIsLoading}
				/>
			</Container>
		)
	)
}

export default ProcedureDialog
