/**
 * @author 	Romain Garcia
 * @version 2020/08/14
 * @see		"./reducer"
 */

// LISTE DE CONTACTS
/**
 * Récupération des contacts
 * 
 * @param {object}	state => partie du Store concernée
 */
const getContacts = state => state.contactList.contacts

// CONTACT
/**
 * Récupération de l'état d'ouverture de la dialog de visualisation/modification de contact
 * 
 * @param {object}	state => partie du Store concernée
 */
const isDialogOpen = state => state.contact.isDialogOpen
/**
 * Récupération du mode de la dialog de visualisation/modification de contact
 * 
 * @param {object}	state => partie du Store concernée
 */
const getDialogMode = state => state.contact.dialogMode
/**
 * Récupération du mode de création de la dialog de visualisation/modification de contact
 * 
 * @param {object}	state => partie du Store concernée
 */
const getCreationMode = state => state.contact.creationMode
/**
 * Récupération du contact lié à la dialog de visualisation/modification de contact
 * 
 * @param {object}	state => partie du Store concernée
 */
const getContact = state => state.contact.contact

export default {
	getContacts,

	isDialogOpen,
	getDialogMode,
	getCreationMode,
	getContact
}