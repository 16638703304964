import React, { Fragment } from 'react';

import { styled, TextField, Button, Box, Checkbox } from '@material-ui/core';

const FormRow = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	marginBottom: theme.spacing(2),

	'& > div:not(:last-child)': {
		marginRight: theme.spacing(1),
	},

	'& > svg': {
		margin: theme.spacing(0, 3, 0, 2),
		fill: theme.palette.grey[700],
	},
}));

const PrivacyButton = styled(Button)({
	width: 100,
});

const PrivateButton = styled(PrivacyButton)({
	borderTopRightRadius: 0,
	borderBottomRightRadius: 0,
});

const PublicButton = styled(PrivacyButton)({
	borderTopLeftRadius: 0,
	borderBottomLeftRadius: 0,
});

const InformationContent = ({ mode, procedure, editProcedure }) => {
	return (
		!!procedure && (
			<Fragment>
				<FormRow justifyContent="space-between">
					<TextField
						label="Nom de la procédure"
						value={procedure.nom}
						onChange={event => {
							editProcedure('nom', event.target.value);
						}}
						variant="outlined"
						required
					/>
					{mode === 'create' && (
						<div>
							<PrivateButton
								variant={procedure.prive === '1' ? 'contained' : 'outlined'}
								color="primary"
								onClick={() => {
									editProcedure('prive', '1');
								}}
							>
								Privé
							</PrivateButton>
							<PublicButton
								variant={!procedure.prive || procedure.prive === '0' ? 'contained' : 'outlined'}
								color="primary"
								onClick={() => {
									editProcedure('prive', '0');
								}}
							>
								Publique
							</PublicButton>
						</div>
					)}
				</FormRow>
				<FormRow>
					<TextField
						label="Commentaire"
						value={procedure.commentaire || ''}
						onChange={event => {
							editProcedure('commentaire', event.target.value);
						}}
						variant="outlined"
						minRows={8}
						multiline
						fullWidth
					/>
				</FormRow>
				<FormRow>
					<Checkbox
						color="primary"
						checked={procedure.ordered ?? false}
						onClick={() => {
							editProcedure('ordered', procedure.ordered ? false : true);
						}}
					/>
					Ordonnancement des signatures
				</FormRow>
			</Fragment>
		)
	);
};

export default InformationContent;
