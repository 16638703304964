/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 */

import reducer from "./reducers"

export { default as contactSelectors } from "./selectors"
export { default as contactOperations } from "./operations"

export default reducer