/**
 * @author	Romain Garcia
 * @version	2020/08/13
 * @see		"./reducer"
 */

// CONNEXION
/**
 * Récupération de l'instance Keycloak
 *
 * @param {object}	state => partie du Store concernée
 */
const getKeycloakInstance = state => state.connexion.keycloakInstance

// UTILISATEUR
/**
 * Récupération du nom de famille de l'utilisateur
 *
 * @param {object}	state => partie du Store concernée
 */
const getLastname = state => state.user.lastname
/**
 * Récupération du prénom de l'utilisateur
 *
 * @param {object}	state => partie du Store concernée
 */
const getFirstname = state => state.user.firstname
/**
 * Récupération des numéro de dossiers auxquels à accès l'utilisateur
 *
 * @param {object}	state => partie du Store concernée
 */
const getSocieties = state => state.user.societies

export default {
	getKeycloakInstance,

	getLastname,
	getFirstname,
	getSocieties
}
