/**
 * @author	Romain Garcia
 * @version	2020/08/13
 */

import { combineReducers } from "redux"

import generalReducer from "./general/index"
import userReducer from "./user/index"
import procedureReducer from "./procedure/index"
import contactReducer from "./contact/index"

const appReducer = combineReducers({
	general: generalReducer,
	user: userReducer,
	procedure: procedureReducer,
	contact: contactReducer
})

const rootReducer = (state, action) => {
	if (action.type === "USER_LOGOUT") {
		state = undefined
	}

	return appReducer(state, action)
}

export default rootReducer
