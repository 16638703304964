/**
 * @author	Romain Garcia
 * @version	2020/03/02
 */

import CryptoJS from "crypto-js"

const hashKey = "l1V6b9jc0CY!3gGGHx4gDb*OqGpdc5zv"

/**
 * Encrypte la chaîne de caractères renseignée
 * 
 * @param {string}	item => la chaîne de caractères à encrypter
 */
export function encrypt(item) {
	return CryptoJS.AES.encrypt(item, hashKey).toString()
}

/**
 * Décrypte la chaîne de caractères renseignée
 * 
 * @param {string}	item => la chaîne de caractères à décrypter 
 */
export function decrypt(item) {
	return CryptoJS.AES.decrypt(item, hashKey).toString(CryptoJS.enc.Utf8)
}