import React from "react"

import { List, AutoSizer } from "react-virtualized"

import { ListItem, ListItemText } from "@material-ui/core"

const VirtualizedList = ({ items, rowRenderer }) => {
	// Fonction générant le rendu du contenu d'une liste vide
	const noRowsRenderer = () => {
		return (
			<ListItem>
				<ListItemText primary="Aucunes données" />
			</ListItem>
		)
	}

	return (
		<AutoSizer>
			{({ height, width }) => (
				<List
					height={height}
					width={width}
					rowCount={items.length}
					rowHeight={47}
					rowRenderer={rowRenderer}
					noRowsRenderer={noRowsRenderer}
				/>
			)}
		</AutoSizer>
	)
}

export default VirtualizedList
