/**
 * @author 	Romain Garcia
 * @version 2020/09/02
 * @see		"./actions"
 */

import actions from './actions';
import { default as socketActions } from '../../utils/socket';
import { setCookie } from '../../utils/cookies';

/**
 * Formate les documents pour passer d'un blob à une chaine "data" en base64
 *
 * @param {array}	documents => liste des documents à traiter
 *
 * @return {array}	documents => liste des documents traités
 */
const formatBlobToBase64 = blob =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onloadend = () => resolve(reader.result?.replace('data:application/pdf;base64,', ''));
		reader.readAsDataURL(blob);
	});

const fixDocumentsMissingDataAttributes = documents =>
	new Promise(resolve => {
		let promises = [];

		documents.filter(({ data }) => !data).forEach(document => promises.push(formatBlobToBase64(document.blob)));

		Promise.allSettled(promises).then(res => {
			let tmpDocuments = [...documents];
			res.filter(({ status }) => status === 'fulfilled').forEach(
				({ value }, index) =>
					(tmpDocuments[index] = {
						...documents[index],
						data: value,
					})
			);

			resolve(tmpDocuments);
		});
	});

// SOCIETE
/**
 * Appelle la fonction qui inscrit la société dans le Store, appelle la fonction pour stocker
 * l'identifiant de la société dans les cookies et envois une requête à la Socket pour récupérer les notifications.
 *
 * @param {object} 	society => société à inscrire
 */
const setSociety = society => dispatch => {
	socketActions.request('connectToDossier', {
		n_dossier: society.id,
	});

	dispatch(actions.setSociety(society));
	setCookie('societyId', society.id);
};
/**
 * Socket: Déconnecte l'utilisateur des notifications du dossier renseigné
 *
 * @param {string}	societyId => identifiant de la société
 */
const disconnectFromSocietyNotifications = societyId => () =>
	socketActions.request('leaveDossier', {
		n_dossier: societyId,
	});
/**
 * Appelle la fonction qui inscrit les procédures renseignées dans le Store
 *
 * @param {array}	procedures => les procédures à incrire dans le Store
 */
const setProcedures = procedures => dispatch => dispatch(actions.setProcedures(procedures));
/**
 * Socket: Récupère les procédures appartenant au dossier renseigné
 *
 * @param {string}	societyId => numéro de dossier pour lequel on veut récupérer les procédures
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject)	=> l'erreur renvoyée par la socket
 */
const fetchProcedures = societyId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('getProcedures', {
				n_dossier: societyId,
			})
			.then(res => {
				let procedureIndex = 0;

				const tmpProcedures = res.map(procedure => {
					let signatoryIndex = 0;

					let tmpProcedure = { ...procedure };
					let documents = [...tmpProcedure.documents];
					let signatories = [...tmpProcedure.signataires];

					signatories.forEach(signatory => (signatory['signatory_id'] = signatoryIndex++));

					tmpProcedure.ordered = procedure.ordered === 1;
					tmpProcedure['procedure_id'] = procedureIndex++;
					tmpProcedure.documents = documents;
					tmpProcedure.signataires = signatories;

					return tmpProcedure;
				});

				dispatch(actions.setProcedures(tmpProcedures));

				resolve(tmpProcedures);
			})
			.catch(reject);
	});
/**
 * Socket: Récupère les procédures en cours d'utilisation appartenant au dossier renseigné
 *
 * @param {string}	societyId => numéro de dossier pour lequel on veut récupérer les procédures
 */
const fetchUsedProcedures = societyId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('getUsedProceduresList', {
				n_dossier: societyId,
			})
			.then(res => {
				dispatch(actions.setUsedProcedures(res));

				resolve(res);
			})
			.catch(reject);
	});
/**
 * Socket: Récupère la procédure correspondant à l'id renseigné
 *
 * @param {string}	procedureId => identifiant de la procédure à récupérer
 */
const fetchProcedure = procedureId => () =>
	new Promise((resolve, reject) => {
		socketActions
			.request('getProcedure', {
				id: procedureId,
			})
			.then(resolve)
			.catch(reject);
	});
/**
 * Socket: Récupère les procédures archivées appartenant au dossier renseigné
 *
 * @param {string}	societyId => numéro de dossier pour lequel on veut récupérer les procédures
 */
const fetchArchivedProcedures = societyId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('getArchives', {
				n_dossier: societyId,
			})
			.then(res => {
				let procedureIndex = 0;

				dispatch(
					actions.setArchivedProcedures(
						res.map(procedure => {
							let signatoryIndex = 0;

							let tmpProcedure = { ...procedure };
							let documents = [...tmpProcedure.documents];
							let signatories = [...tmpProcedure.signataires];

							signatories.forEach(signatory => (signatory['signatory_id'] = signatoryIndex++));

							tmpProcedure['procedure_id'] = procedureIndex++;
							tmpProcedure.documents = documents;
							tmpProcedure.signataires = signatories;
							tmpProcedure.archived = true;

							return tmpProcedure;
						})
					)
				);

				resolve();
			})
			.catch(reject);
	});
/**
 * Socket: Supprime la procédure renseignée
 *
 * @param {string}	procedureId => identifiant de la procédure à supprimer
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject)	=> l'erreur renvoyée par la socket
 */
const deleteProcedure = procedureId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('deleteProcedure', {
				id: procedureId,
			})
			.then(() => {
				dispatch(actions.removeProcedure(procedureId));
				resolve();
			})
			.catch(reject);
	});
/**
 * Socket: Archive la procédure renseignée
 *
 * @param {string}	procedureId => identifiant de la procédure à archiver
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject)	=> l'erreur renvoyée par la socket
 */
const archiveProcedure = procedureId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('archiveProcedure', {
				id: procedureId,
			})
			.then(() => {
				dispatch(actions.removeProcedure(procedureId));
				resolve();
			})
			.catch(reject);
	});
/**
 * Socket: Restaure la procédure renseignée
 *
 * @param {string}	procedureId => identifiant de la procédure à restaurer
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject)	=> l'erreur renvoyée par la socket
 */
const restoreProcedure = procedureId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('restoreProcedure', {
				id: procedureId,
			})
			.then(() => {
				dispatch(actions.removeArchivedProcedure(procedureId));
				resolve();
			})
			.catch(reject);
	});
/**
 * Fait appel à l'action ajoutant une procédure à la liste des procédures récemment mises à jour
 *
 * @param {string} procedureId => identifiant de la procédure
 */
const addRecentlyUpdatedProcedure = procedureId => dispatch =>
	dispatch(actions.addRecentlyUpdatedProcedure(procedureId));
/**
 * Fait appel à l'action retirant une procédure à la liste des procédures récemment mises à jour
 *
 * @param {string} procedureId => identifiant de la procédure
 */
const removeRecentlyUpdatedProcedure = procedureId => dispatch =>
	dispatch(actions.removeRecentlyUpdatedProcedure(procedureId));
/**
 * Socket: récupère le nombre de signatures effectuées sur l'année
 *
 * @param {string} customerFileCode => identifiant du dossier
 */
const fetchCurrentYearSignatures = customerFileCode => dispatch => {
	socketActions
		.request('getCurrentYearSignatures', {
			customerFileCode,
		})
		.then(res => dispatch(actions.setCurrentYearSignatures(res)));
};

// PROCEDURE
/**
 * Socket: Créé la procédure renseignée
 *
 * @param {object}	param =>
 * 	{string} 	nom => nom de la procédure
 * 	{string} 	societyId => numéro de dossier auquel est rattaché la procédure
 * 	{string} 	commentaire => commentaire de la procédure
 * 	{string} 	prive => booléen indiquant si la procédure est privée
 * 	{array}		documents => documents appartenant à la procédure
 * 	{array}		signataires => signataires de la procédure
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject)	=> l'erreur renvoyée par la socket
 */
const createProcedure =
	({ nom, societyId, commentaire, prive, documents, signataires, ordered }) =>
	dispatch =>
		new Promise((resolve, reject) => {
			fixDocumentsMissingDataAttributes(documents).then(fixedDocuments =>
				socketActions
					.request('addProcedure', {
						n_dossier: societyId,
						documents: fixedDocuments,
						nom,
						commentaire,
						prive,
						ordered,
						signataires,
					})
					.then(res =>
						dispatch(fetchProcedure(res.id)).then(procedure => {
							dispatch(actions.addProcedure(procedure));
							dispatch(actions.setSelectedProcedure(procedure));

							resolve(procedure);
						})
					)
					.catch(reject)
			);
		});
/**
 * Appelle l'action d'ouverture de la dialog de visualisation/modification d'une procédure
 *
 * @param {object}	props =>
 * 	{object}	procedure : procédure à visualiser/modifier
 * 	{string}	mode : mode de la dialog ("create", "edit", "view")
 */
const openProcedureDialog = props => dispatch => dispatch(actions.openProcedureDialog(props));
/**
 * Appelle l'action de fermeture de la dialog de visualisation/modification d'une procédure
 */
const closeProcedureDialog = () => dispatch => dispatch(actions.closeProcedureDialog());
/**
 * Appelle l'action appliquant un mode à la dialog de visualisation/modification d'une procédure
 *
 * @param {string}	mode => mode à appliquer ("create", "edit", "view")
 */
const setDialogMode = mode => dispatch =>
	new Promise(resolve => {
		dispatch(actions.setDialogMode(mode));

		resolve();
	});
/**
 * Socket: Récupération des datas d'un document
 *
 * @param {object}	document => document duquel on veut récupérer les datas
 *
 * @return {Promise}	=>
 * 	(resolve)	=> le blob du document
 * 	(reject)	=> l'erreur renvoyée par la socket
 */
const fetchDocumentBlob = document => () =>
	new Promise((resolve, reject) => {
		socketActions
			.request('getDocData', {
				id: document.id,
			})
			.then(base64Data =>
				fetch(`data:application/pdf;base64,${base64Data}`)
					.then(file => file.blob())
					.then(resolve)
			)
			.catch(reject);
	});
/**
 * Appelle l'action appliquant la procédure passée en paramètre à la procédure affichée dans la dialog
 *
 * @param {object}	procedure => procédure à afficher
 */
const setSelectedProcedure = procedure => dispatch => dispatch(actions.setSelectedProcedure(procedure));
/**
 * Appelle l'action appliquant une liste de documents à la procédure actuelle
 *
 * @param {array}	documents => liste des nouveaux documents
 */
const setProcedureDocuments = documents => dispatch => dispatch(actions.setProcedureDocuments(documents));
/**
 * Socket: Modification d'une procédure
 *
 * @param {object}	procedure => la procédure à modifier
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject) 	=> l'erreur renvoyée par la socket
 */
const updateProcedure =
	({ procedure, mode = 'socket' }) =>
	dispatch =>
		new Promise((resolve, reject) => {
			fixDocumentsMissingDataAttributes(procedure.documents).then(fixedDocuments => {
				const newProcedure = {
					...procedure,
					documents: fixedDocuments,
				};

				if (mode === 'storeOnly') {
					dispatch(actions.updateProcedure(newProcedure));
					resolve();
				} else {
					socketActions
						.request('updateProcedure', newProcedure)
						.then(() => {
							dispatch(actions.updateProcedure(newProcedure));
							resolve();
						})
						.catch(reject);
				}
			});
		});
/**
 * Socket: Envois en signature d'une procédure
 *
 * @param {string}	procedureId => identifiant de la procédure à envoyer
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject) 	=> l'erreur renvoyée par la socket
 */
const sendProcedure = procedureId => () =>
	new Promise((resolve, reject) => {
		socketActions
			.request('startProcedure', {
				id: procedureId,
			})
			.then(resolve)
			.catch(reject);
	});
/**
 * Socket: Demande d'utilisation de la procédure renseignée
 *
 * @param {string}	procedureId => identifiant de la procédure à utiliser
 *
 * @return {Promise}	=>
 * 	(resolve)
 * 	(reject) 	=> l'erreur renvoyée par la socket
 */
const useProcedure = procedureId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('useProcedure', {
				id: procedureId,
			})
			.then(res => {
				if (res) {
					dispatch(actions.addUsedProcedure(procedureId));
					resolve();
				}
			})
			.catch(reject);
	});
/**
 * Socket: Libère l'utilisation de la procédure renseignée
 *
 * @param {string}	procedureId => identifiant de la procédure à utiliser
 */
const freeProcedure = procedureId => dispatch =>
	new Promise((resolve, reject) => {
		socketActions
			.request('freeProcedure', {
				id: procedureId,
			})
			.then(res => {
				if (res) {
					dispatch(actions.removeUsedProcedure(procedureId));
					resolve();
					return;
				}

				reject();
			});
	});
/**
 * Socket: Envoi un mail de relance de signature
 *
 * @param {string} contactId 	=> identifiant du contact concerné
 * @param {number} procedureId 	=> identifiant de la procédure concernée
 */
const sendReminder = (contactId, procedureId) => () =>
	new Promise((resolve, reject) => {
		socketActions
			.request('sendReminder', {
				id_procedure: procedureId,
				id_contact: contactId,
			})
			.then(res => {
				if (res) {
					resolve();
					return;
				}

				reject();
			});
	});

// DEPOT DE FICHIER
/**
 * Appelle l'action qui ouvre la dialog de dépôt de documents
 */
const openUploadDocumentDialog = () => dispatch => dispatch(actions.openUploadDocumentDialog())
/**
 * Appelle l'action qui ferme la dialog de dépôt de documents
 */
const closeUploadDocumentDialog = () => dispatch => dispatch(actions.closeUploadDocumentDialog())

export default {
	setSociety,
	disconnectFromSocietyNotifications,
	setProcedures,
	fetchProcedures,
	fetchUsedProcedures,
	fetchProcedure,
	fetchArchivedProcedures,
	addRecentlyUpdatedProcedure,
	removeRecentlyUpdatedProcedure,
	fetchCurrentYearSignatures,

	createProcedure,
	deleteProcedure,
	openProcedureDialog,
	closeProcedureDialog,
	setDialogMode,
	fetchDocumentBlob,
	setSelectedProcedure,
	setProcedureDocuments,
	updateProcedure,
	sendProcedure,
	archiveProcedure,
	restoreProcedure,
	useProcedure,
	freeProcedure,
	sendReminder,

	openUploadDocumentDialog,
	closeUploadDocumentDialog,
};
