/**
 * @author	Romain Garcia
 * @version 2020/08/13
 * @see		"./types"
 */

import types from "./types"

// LISTE DE CONTACTS
/**
 * Inscrits la liste des contacts dans le Store
 * 
 * @param {array}	contacts => liste des contacts à inscrire dans le Store
 */
const setContacts = contacts => ({
	type: types.SET_CONTACTS,
	payload: contacts
})
const removeContact = contactId => ({
	type: types.REMOVE_CONTACT,
	payload: contactId
})

// CONTACT
/**
 * Ouvre la dialog de visualisation/modification de contact
 * 
 * @param {object}	props =>
 * 						{object}	contact : contact à visualiser/modifier,
 *		 				{string}	mode: mode de la dialog ("create", "edit", "view")
 */
const openContactDialog = props => ({
	type: types.OPEN_CONTACT_DIALOG,
	payload: {...props}
})
/**
 * Ferme la dialog de visualisation/modification de contact
 */
const closeContactDialog = () => ({
	type: types.CLOSE_CONTACT_DIALOG
})
/**
 * Applique un mode à la dialog de visualisation/modification de contact
 * 
 * @param {string}	mode => mode à appliquer ("create", "edit", "view")
 */
const setDialogMode = mode => ({
	type: types.SET_DIALOG_MODE,
	payload: mode
})

export default {
	setContacts,
	removeContact,

	openContactDialog,
	closeContactDialog,
	setDialogMode
}