import React, { useCallback, useEffect } from 'react';

import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import {
	Avatar,
	Box,
	Button,
	InputAdornment,
	Paper,
	styled,
	SvgIcon,
	Tab,
	Table,
	TableCell,
	TableFooter,
	TableRow,
	Tabs,
	TextField,
	Tooltip,
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';

import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PublicIcon from '@material-ui/icons/Public';
import SearchIcon from '@material-ui/icons/Search';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import MailIcon from '@material-ui/icons/Mail';

import useStateWithLabel from '../../utils/useStateWithLabel';

import { procedureOperations, procedureSelectors } from '../../modules/procedure';

import categories from './procedureCategories';
import states from './procedureStates';
import StepIndicator from './procedureTable/StepIndicator';

import VirtualizedTable from '../../template/components/VirtualizedTable';

import { generalOperations, generalSelectors } from '../../modules/general';

import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import ActionCell from './procedureTable/ActionCell';

const TableHeader = styled(Box)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]}`,

	'& > div:first-child': {
		boxShadow: 'none',
		borderBottom: 0,
	},
}));

const CategoryTabs = styled(Tabs)(({ theme }) => ({
	borderBottom: theme.palette.type === 'light' ? '1px solid lightgrey' : '',
	boxShadow: theme.shadows[1],

	'&:first-child': {
		borderTopLeftRadius: theme.shape.borderRadius,
	},

	'& button': {
		transition: 'all .3s ease-in-out',

		'&.selected': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.getContrastText(theme.palette.secondary.main),
		},
	},
}));

const CenteredColumn = styled('div')({
	paddingLeft: '0 !important',
	justifyContent: 'center',
});

const PrivacyIcon = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0.5),
	borderRadius: 50,
	backgroundColor: theme.palette.grey[300],
	color: theme.palette.getContrastText(theme.palette.grey[300]),
}));

const SignatoryAvatar = styled(Avatar)(({ theme }) => ({
	'&.hasSigned': {
		backgroundColor: theme.palette.primary.main,
	},
}));

const CreatorAvatar = styled(Avatar)({
	height: 36,
	width: 36,
});

const Searchbar = styled(TextField)(({ theme }) => ({
	boxShadow: theme.shadows[1],
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(0.2, 1),
	marginRight: theme.spacing(0.75),

	'& > div': {
		'& svg': {
			fill: theme.palette.grey[500],
		},

		'&::before, &::after': {
			display: 'none',
		},
	},
}));

const TableFooterContainer = styled(Table)(({ theme }) => ({
	position: 'sticky',
	left: 0,
	right: 0,
	bottom: 0,

	borderBottomLeftRadius: theme.shape.borderRadius,
	borderBottomRightRadius: theme.shape.borderRadius,

	backgroundColor: theme.palette.background.paper,
	boxShadow:
		'rgb(0 0 0 / 20%) 0px -2px 1px -1px, rgb(0 0 0 / 14%) 0px -1px 1px 0px, rgb(0 0 0 / 12%) 0px -1px 3px 0px',

	padding: 0,
	margin: 0,

	'& td': {
		height: 52,
		border: 0,

		padding: theme.spacing(0, 0, 0, 1),
	},
}));

const UpdateIcon = styled(NewReleasesIcon)(({ theme }) => ({
	fill: theme.palette.primary.main,
	marginLeft: theme.spacing(1),
}));

const columns = [
	{
		width: 40,
		label: '',
		dataKey: 'privacy',
	},
	{
		width: 250,
		label: 'Nom',
		dataKey: 'name',
	},
	{
		width: 100,
		label: 'Etape',
		dataKey: 'step',
	},
	{
		width: 200,
		label: 'Signataires',
		dataKey: 'signatories',
		disableSort: true,
	},
	{
		width: 100,
		label: 'Créateur',
		dataKey: 'creator',
	},
	{
		width: 190,
		label: 'Dernière modification',
		dataKey: 'latestEdition',
	},
	{
		width: 90,
		label: 'Actions',
		dataKey: 'actions',
		disableSort: true,
		centered: true,
	},
];

const Container = styled(Paper)({
	width: columns.map(column => column.width).reduce((a, b) => a + b, 0),
});

const ProcedureTable = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const society = useSelector(state => procedureSelectors.getSociety(state.procedure));
	const procedures = useSelector(state => procedureSelectors.getProcedures(state.procedure));
	const usedProcedures = useSelector(state => procedureSelectors.getUsedProcedures(state.procedure));
	const archivedProcedures = useSelector(state => procedureSelectors.getArchivedProcedures(state.procedure));
	const recentlyUpdatedProcedures = useSelector(state =>
		procedureSelectors.getRecentlyUpdatedProcedures(state.procedure)
	);
	const socket = useSelector(state => generalSelectors.getSocket(state.general));

	const [category, setCategory] = useStateWithLabel('ongoing', 'category');
	const [tableProcedures, setTableProcedures] = useStateWithLabel([], 'tableProcedures');
	const [isLoading, setIsLoading] = useStateWithLabel(true, 'isLoading');
	const [sortBy, setSortBy] = useStateWithLabel('step', 'sortBy');
	const [sortDirection, setSortDirection] = useStateWithLabel('ASC', 'sortDirection');
	const [search, setSearch] = useStateWithLabel('', 'search');
	const [isSearchFocused, setIsSearchFocused] = useStateWithLabel(false, 'isSearchFocused');

	const TableWrapper = styled('div')({
		minHeight: 'calc(47px * 9)',
		height: `calc(47px * ${tableProcedures.length})`,
		maxHeight: '75vh',
		width: '100%',

		'&.ongoing': {
			maxHeight: '68vh',
		},
	});

	const selectCategory = useCallback((event, newCategory) => setCategory(newCategory), [setCategory]);

	const deleteProcedure = procedureId => dispatch(procedureOperations.deleteProcedure(procedureId));

	const archiveProcedure = procedureId => {
		dispatch(procedureOperations.archiveProcedure(procedureId));
		dispatch(procedureOperations.fetchArchivedProcedures(society.id));
	};

	const restoreProcedure = procedureId => {
		dispatch(procedureOperations.restoreProcedure(procedureId));
		dispatch(procedureOperations.fetchProcedures(society.id));
	};

	const sendProcedureReminders = procedureId => {
		const intProcedureId = +procedureId;

		const { ordered, signataires_data } = procedures.find(procedure => procedure.id === intProcedureId);

		if (!ordered) {
			signataires_data.forEach(({ id_contact }) =>
				dispatch(procedureOperations.sendReminder(id_contact, intProcedureId))
			);
		} else {
			const { id_contact } = signataires_data.find(({ date_signature }) => date_signature === '');

			dispatch(procedureOperations.sendReminder(id_contact, intProcedureId));
		}
	};

	const handleProcedureArchiving = event => {
		event.stopPropagation();
		const procedureToArchiveId = event.currentTarget.getAttribute('name');

		dispatch(
			generalOperations.openConfirmationDialog({
				content: 'Êtes-vous sûr de vouloir archiver cette procédure ?',
				action: () => {
					archiveProcedure(procedureToArchiveId);
				},
			})
		);
	};

	const handleProcedureDeletion = event => {
		event.stopPropagation();

		const procedureToDeleteId = event.currentTarget.getAttribute('name');

		dispatch(
			generalOperations.openConfirmationDialog({
				content: 'Êtes-vous sûr de vouloir supprimer cette procédure ?',
				action: () => {
					deleteProcedure(procedureToDeleteId);
				},
			})
		);
	};

	const handleProcedureUnarchive = event => {
		event.stopPropagation();
		const procedureToUnarchiveId = event.currentTarget.getAttribute('name');

		dispatch(
			generalOperations.openConfirmationDialog({
				content: 'Êtes-vous sûr de vouloir restaurer cette procédure ?',
				action: () => {
					restoreProcedure(procedureToUnarchiveId);
				},
			})
		);
	};

	const handleSignatureReminder = event => {
		event.stopPropagation();
		const procedureToRemindId = event.currentTarget.getAttribute('name');

		dispatch(
			generalOperations.openConfirmationDialog({
				content: 'Êtes-vous sûr de vouloir envoyer un rappel de signature aux signataires de cette procédure ?',
				action: () => {
					sendProcedureReminders(procedureToRemindId);
				},
			})
		);
	};

	// Fonction générant le rendu des cellules
	const cellRenderer = ({ rowData, columnIndex }) => {
		const isUsed = usedProcedures.some(procedureId => procedureId.toString() === rowData.id.toString());
		const creatorNameParts = rowData.createur_nom.split(' ');

		switch (columnIndex) {
			case 0:
				return (
					<CenteredColumn>
						<Tooltip title={rowData.prive ? 'Privé' : 'Publique'}>
							<PrivacyIcon display="flex" justifyContent="center" alignItems="center">
								<SvgIcon component={rowData.prive ? LockIcon : PublicIcon} fontSize="small" />
							</PrivacyIcon>
						</Tooltip>
					</CenteredColumn>
				);

			case 1:
				return (
					<div>
						{rowData.nom || ''}
						{recentlyUpdatedProcedures.some(id => id === rowData.id) && <UpdateIcon />}
					</div>
				);

			case 2:
				return (
					<div>
						<StepIndicator step={states[rowData.etat]} />
					</div>
				);

			case 3:
				return (
					<div>
						<Tooltip
							title={rowData.signataires.map(contact => (
								<div key={contact.id}>
									<span>
										{contact.prenom} {contact.nom}
										{rowData.signataires_data.some(
											({ id_contact, date_signature }) =>
												id_contact === contact.id && !!date_signature
										) &&
											` - ${format(
												parseISO(
													rowData.signataires_data.find(
														({ id_contact }) => id_contact === contact.id
													).date_signature
												),
												'dd/MM/yyyy HH:mm:ss'
											)}`}
									</span>
								</div>
							))}
						>
							<AvatarGroup max={5}>
								{rowData.signataires.map(contact => (
									<SignatoryAvatar
										key={contact.id}
										className={clsx({
											hasSigned: rowData.signataires_data.some(
												({ id_contact, date_signature }) =>
													id_contact === contact.id && !!date_signature
											),
										})}
									>
										{contact.prenom.toUpperCase().charAt(0)}
										{contact.nom.toUpperCase().charAt(0)}
									</SignatoryAvatar>
								))}
							</AvatarGroup>
						</Tooltip>
					</div>
				);

			case 4:
				return (
					<CenteredColumn>
						<Tooltip title={rowData.createur_nom}>
							<CreatorAvatar>
								{creatorNameParts[0].charAt(0)}
								{creatorNameParts[1].charAt(0)}
							</CreatorAvatar>
						</Tooltip>
					</CenteredColumn>
				);

			case 5:
				return <div>{new Date(rowData.date_modification).toLocaleString('fr-FR')}</div>;

			case 6:
				const hasMissingSignatures = rowData.signataires_data.some(({ date_signature }) => !date_signature);

				const actions = [];

				if (!rowData.archived) {
					if (rowData.etat === 0) {
						actions.push({
							id: 'delete',
							callback: handleProcedureDeletion,
							icon: DeleteIcon,
							label: 'Supprimer',
							needsUniqueUsage: true,
						});
					}

					if (rowData.etat === 1 && hasMissingSignatures) {
						actions.push({
							id: 'remind',
							icon: MailIcon,
							callback: handleSignatureReminder,
							label: 'Relancer',
						});
					}

					if ([1, 2, 3].includes(rowData.etat)) {
						actions.push({
							id: 'archive',
							callback: handleProcedureArchiving,
							icon: ArchiveIcon,
							label: 'Archiver',
							needsUniqueUsage: true,
						});
					}

					if (rowData.etat === 1) {
						actions.push({
							id: 'delete',
							callback: handleProcedureDeletion,
							icon: DeleteIcon,
							label: 'Supprimer',
							needsUniqueUsage: true,
						});
					}
				} else {
					actions.push({
						id: 'unarchive',
						callback: handleProcedureUnarchive,
						icon: UnarchiveIcon,
						label: 'Restaurer',
						needsUniqueUsage: true,
					});
				}

				return <ActionCell procedureId={rowData.id} actions={actions} isProcedureUsed={isUsed} />;

			default:
				return <div></div>;
		}
	};

	// Fonction permettant de trier le tableau
	const sortTable = table => {
		let tmpProcedures = [...table];

		const sortByHasValue = !!sortBy;
		if (sortByHasValue) {
			switch (sortBy) {
				case 'privacy':
					return tmpProcedures.sort((a, b) =>
						sortDirection === 'ASC' ? a.prive - b.prive : b.prive - a.prive
					);

				case 'name':
					return tmpProcedures.sort((a, b) =>
						sortDirection === 'ASC' ? a.nom.localeCompare(b.nom) : b.nom.localeCompare(a.nom)
					);

				case 'step':
					return tmpProcedures.sort((a, b) => (sortDirection === 'ASC' ? a.etat - b.etat : b.etat - a.etat));

				case 'creator':
					return tmpProcedures.sort((a, b) =>
						sortDirection === 'ASC'
							? a.createur_nom.localeCompare(b.createur_nom)
							: b.createur_nom.localeCompare(a.createur_nom)
					);

				case 'latestEdition':
					return tmpProcedures.sort((a, b) =>
						sortDirection === 'ASC'
							? new Date(a.date_modification) - new Date(b.date_modification)
							: new Date(b.date_modification) - new Date(a.date_modification)
					);

				default:
					return tmpProcedures;
			}
		}

		return tmpProcedures;
	};

	const getSearchedProcedures = () =>
		tableProcedures.filter(procedure => procedure?.nom?.toLowerCase().includes(search.toLowerCase()));

	const handleRowClick = ({ rowData }) =>
		dispatch(
			procedureOperations.openProcedureDialog({
				procedure: rowData,
				mode: 'view',
			})
		);

	const handleProcedureCreation = () =>
		dispatch(
			procedureOperations.openProcedureDialog({
				procedure: {
					nom: '',
					commentaire: '',
					documents: [],
					signataires: [],
					prive: false,
				},
				mode: 'create',
			})
		);

	const handleSearch = ({ target }) => setSearch(target.value);

	const toggleIsSearchFocused = ({ target }) =>
		target.ownerDocument.activeElement === target ? setIsSearchFocused(true) : setIsSearchFocused(false);

	const getProcedureAtIndex = ({ index }) => sortTable(getSearchedProcedures())[index];

	// Récupération des procédures
	useEffect(() => {
		const societyHasValue = !!society.id;

		if (societyHasValue) {
			setIsLoading(true);

			dispatch(procedureOperations.fetchUsedProcedures(society.id));

			if (category === 'ongoing') {
				dispatch(procedureOperations.fetchProcedures(society.id))
					.then(() => setIsLoading(false))
					.catch(err => {
						setIsLoading(false);
						enqueueSnackbar(
							typeof err === 'string' ? err : 'Erreur lors de la récupération des procédures',
							{
								variant: 'error',
							}
						);
					});
			} else if (category === 'archived') {
				dispatch(procedureOperations.fetchArchivedProcedures(society.id));
			}
		}
	}, [dispatch, enqueueSnackbar, socket, category, society, setIsLoading]);

	// Gestion de l'affichage des procédures en cours
	useEffect(() => {
		if (category === 'ongoing') {
			setTableProcedures(procedures);
			setSortBy('step');
			setSortDirection('ASC');
			setIsLoading(false);
		}
	}, [category, procedures, setTableProcedures, setIsLoading, setSortBy, setSortDirection]);

	// Gestion de l'affichage des procédures archivées
	useEffect(() => {
		if (category === 'archived') {
			setTableProcedures(archivedProcedures);
			setSortBy('latestEdition');
			setSortDirection('DESC');
			setIsLoading(false);
		}
	}, [category, archivedProcedures, setTableProcedures, setIsLoading, setSortBy, setSortDirection]);

	return (
		<Container>
			<TableHeader display="flex" justifyContent="space-between" alignItems="center">
				<CategoryTabs value={category} variant="scrollable" scrollButtons="auto" onChange={selectCategory}>
					{categories.map(item => (
						<Tab
							key={item.id}
							className={clsx({ selected: category === item.id })}
							value={item.id}
							label={item.label}
						/>
					))}
				</CategoryTabs>

				<Searchbar
					placeholder="Rechercher..."
					value={search}
					onChange={handleSearch}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					onFocus={toggleIsSearchFocused}
					onBlur={toggleIsSearchFocused}
					autoFocus={isSearchFocused}
				/>
			</TableHeader>
			<TableWrapper className={clsx({ ongoing: category === 'ongoing' })}>
				<VirtualizedTable
					isLoading={isLoading}
					columns={columns}
					rowCount={getSearchedProcedures().length}
					rowGetter={getProcedureAtIndex}
					cellRenderer={cellRenderer}
					onRowClick={handleRowClick}
					sortBy={sortBy}
					setSortBy={setSortBy}
					sortDirection={sortDirection}
					setSortDirection={setSortDirection}
				/>
			</TableWrapper>
			{category === 'ongoing' && (
				<TableFooterContainer>
					<TableFooter>
						<TableRow>
							<TableCell>
								<Button variant="contained" color="primary" onClick={handleProcedureCreation}>
									Créer une procédure
								</Button>
							</TableCell>
						</TableRow>
					</TableFooter>
				</TableFooterContainer>
			)}
		</Container>
	);
};

export default ProcedureTable;
