import React from "react"
import clsx from "clsx"

import {
	IconButton,
	InputAdornment,
	ListItem,
	ListItemIcon,
	Paper,
	TextField,
	Tooltip,
	Typography,
	styled
} from "@material-ui/core"

import LockIcon from "@material-ui/icons/Lock"
import PublicIcon from "@material-ui/icons/Public"
import SearchIcon from "@material-ui/icons/Search"
import CancelIcon from "@material-ui/icons/Cancel"

import VirtualizedList from "../../../../../template/components/VirtualizedList"

import useStateWithLabel from "../../../../../utils/useStateWithLabel"

const Container = styled(Paper)({
	display: "flex",
	flexDirection: "column",

	"& > div:last-child": {
		flex: 1
	}
})

const ListWrapper = styled("div")({
	height: "100%",
	width: "100%",
	flex: 1,

	"& > div > div > div > li": {
		listStyleType: "none"
	}
})

const ListHeader = styled("div")(({ theme }) => ({
	padding: theme.spacing(2, 1.6),
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	boxShadow: theme.shadows[1],

	"&.searchActive": {
		padding: theme.spacing(1.301, 1.6)
	}
}))

const Searchbar = styled(TextField)(({ theme }) => ({
	boxShadow: "none",
	borderRadius: theme.shape.borderRadius,
	padding: 0,
	width: "initial",

	"& > div": {
		"& > input": {
			display: "none"
		},

		"& svg": {
			fill: theme.palette.grey[500]
		},

		"&::before, &::after": {
			display: "none"
		}
	},

	"&.searchActive": {
		boxShadow: theme.shadows[1],
		padding: theme.spacing(0.2, 1),
		width: "100%",

		"& > div > input": {
			display: "initial"
		}
	}
}))

const ListItemContent = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	flex: 1,

	"& svg": {
		fill: theme.palette.grey[300]
	}
}))

const PersonsList = ({ title, persons, rowClickAction }) => {
	const [isSearchActive, setSearchActive] = useStateWithLabel(
		false,
		"isSearchActive"
	)
	const [search, setSearch] = useStateWithLabel("", "search")

	const editSearch = ({ target }) => setSearch(target.value)

	const editSearchActive = ({ currentTarget }) =>
		search.length === 0 &&
		setSearchActive(currentTarget.name === "searchIcon")

	const rowRenderer = ({ key, index, style }) => {
		const person = persons.filter(
			({ nom, prenom }) =>
				nom.toLowerCase().includes(search.toLowerCase()) ||
				prenom.toLowerCase().includes(search.toLowerCase())
		)[index]

		return (
			<ListItem
				name={person.id}
				key={key}
				style={style}
				onClick={rowClickAction}
				button
				divider>
				<ListItemIcon>
					{person.prive ? (
						<Tooltip title="Privé">
							<LockIcon />
						</Tooltip>
					) : (
						<Tooltip title="Publique">
							<PublicIcon />
						</Tooltip>
					)}
				</ListItemIcon>
				<ListItemContent>
					<Typography variant="body2">
						{person.prenom + " " + person.nom}
					</Typography>
					{title === "Signataires" && <CancelIcon />}
				</ListItemContent>
			</ListItem>
		)
	}

	return (
		<Container>
			<ListHeader className={clsx({ searchActive: isSearchActive })}>
				{!isSearchActive && <Typography>{title}</Typography>}
				<Searchbar
					className={clsx({ searchActive: isSearchActive })}
					name="searchField"
					placeholder="Rechercher..."
					value={search}
					onChange={editSearch}
					onBlur={editSearchActive}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{isSearchActive ? (
									<SearchIcon />
								) : (
									<IconButton
										name="searchIcon"
										onClick={editSearchActive}>
										<SearchIcon />
									</IconButton>
								)}
							</InputAdornment>
						)
					}}
					autoFocus
				/>
			</ListHeader>
			<ListWrapper>
				<VirtualizedList
					items={persons.filter(
						({ nom, prenom }) =>
							nom.toLowerCase().includes(search.toLowerCase()) ||
							prenom.toLowerCase().includes(search.toLowerCase())
					)}
					rowRenderer={rowRenderer}
				/>
			</ListWrapper>
		</Container>
	)
}

export default PersonsList
