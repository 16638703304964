import { useSnackbar } from 'notistack';
import React from 'react';

import { Box, IconButton, Typography, styled } from '@material-ui/core';

import BackupIcon from '@material-ui/icons/Backup';
import CancelIcon from '@material-ui/icons/Cancel';

import { useDropzone } from 'react-dropzone';
import fileHandler from '../../utils/fileHandler';
import VirtualizedList from './VirtualizedList';

const DropzoneContent = styled('div')(({ theme }) => ({
	borderRadius: '5px',
	border: `2px dashed ${theme.palette.primary.main}`,

	minHeight: '80%',
	minWidth: '90%',

	cursor: 'pointer',

	display: 'flex',

	'& > div': {
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
	},

	'&:hover > div > div:last-child': {
		backgroundColor: theme.palette.primary.main,
	},
}));

const DropzoneTextWrapper = styled('div')({
	position: 'relative',

	flex: '1',

	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',

	'& > div': {
		position: 'absolute',

		height: '100%',
		width: '100%',

		opacity: '10%',
	},

	'& > svg': {
		fontSize: '5rem',
	},
});

const FilePreviewWrapper = styled('div')({
	flex: '3',

	position: 'relative',

	padding: '.5rem',

	'& > div': {
		flex: 1,
	},
});

const FileContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',

	'& > div': {
		'& > p': {
			marginRight: theme.spacing(1),
		},

		'& > button': {
			'& svg': {
				fill: theme.palette.grey[300],
			},

			'&:hover svg': {
				fill: theme.palette.grey[500],
			},
		},
	},
}));

const CancelButton = styled(IconButton)(({ theme }) => ({
	marginLeft: theme.spacing(1),

	'& svg': {
		fill: theme.palette.grey[300],
	},

	'&:hover svg': {
		fill: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[100],
	},
}));

const FileUploader = ({ files, setFiles }) => {
	const { enqueueSnackbar } = useSnackbar();

	const onDrop = acceptedFiles => {
		setFiles(acceptedFiles.filter(file => file.type === 'application/pdf'));

		acceptedFiles.some(file => file.type !== 'application/pdf') &&
			enqueueSnackbar('Seul les fichiers PDF sont acceptés.', {
				variant: 'warning',
			});
	};
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: '.pdf',
		maxSize: 10 * 1048576,
		onDrop,
	});

	const removeFile = file => {
		let tmpFiles = [...files];
		tmpFiles.splice(
			tmpFiles.findIndex(item => item === file),
			1
		);

		setFiles(tmpFiles);
	};

	const rowRenderer = ({ key, index, style }) => {
		const file = files[index];
		return (
			<FileContainer key={key} style={style}>
				<Typography variant="body2">{file.name}</Typography>
				<Box display="flex" alignItems="center">
					<Typography variant="body2">{fileHandler.convertFileSize(file.size)}</Typography>
					<CancelButton
						size="small"
						onClick={event => {
							event.stopPropagation();
							removeFile(file);
						}}
					>
						<CancelIcon />
					</CancelButton>
				</Box>
			</FileContainer>
		);
	};

	const renderActionExplanationtext = () =>
		isDragActive ? 'Relachez les fichiers ici' : 'Glissez des fichiers ici, ou cliquez pour en sélectionner';

	return (
		<DropzoneContent
			{...getRootProps({
				onClick: event => {
					files.length > 0 && event.stopPropagation();
				},
			})}
		>
			<input {...getInputProps()} />
			{files.length === 0 ? (
				<>
					<DropzoneTextWrapper>
						<BackupIcon color="primary" />
						<Typography variant="h6" color="primary" align="center">
							{renderActionExplanationtext()}
						</Typography>
					</DropzoneTextWrapper>
				</>
			) : (
				<FilePreviewWrapper>
					<VirtualizedList items={files} rowRenderer={rowRenderer} />
				</FilePreviewWrapper>
			)}
		</DropzoneContent>
	);
};

export default FileUploader;
