/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 */

import reducer from "./reducers"

export { default as generalSelectors } from "./selectors"
export { default as generalOperations } from "./operations"

export default reducer