/**
 * @author	Romain Garcia
 * @version 2020/08/13
 * @see		"./actions"
 */

import { default as socketActions } from '../../utils/socket'
import actions from './actions'

// CONNEXION
/**
 * Appelle l'action qui inscrits l'instance Keycloak dans le Store
 *
 * @param {object}	keycloak => instance Keycloak à inscrire dans le Store
 */
const setKeycloakInstance = keycloak => dispatch => dispatch(actions.setKeycloakInstance(keycloak))

/**
 * Déconnecte l'utilisateur de Keycloak
 *
 * @param {object}	keycloak => instance Keycloak
 */
const disconnectUser = keycloak => () => new Promise((resolve, reject) => keycloak.logout().then(resolve).catch(reject))

// UTILISATEUR
/**
 * Récupération des informations de l'utilisateur depuis Keycloak et la socket
 * Appelle l'action qui inscrits les informations de l'utilisateur dans le Store
 * Effectue l'authentification auprès de la socket
 *
 * @param {object}	keycloak => instance Keycloak
 *
 * @return {Promise}	=>
 * 						(resolve)
 * 						(reject)	=> l'erreur renvoyée par Keycloak
 */
const fetchUserInfos = keycloak => dispatch =>
	new Promise((resolve, reject) =>
		keycloak
			.loadUserProfile()
			.then(user => {
				const userSocieties = user.attributes.sign_manage_customerFile[0].split('#')

				socketActions
					.request('getDossiersInfos', {
						n_dossiers: userSocieties.map(society => society)
					})
					.then(res =>
						dispatch(
							actions.setUserSocieties(
								userSocieties.map(society => {
									const resItem = res.find(item => item.n_dossier === society)

									return !!resItem
										? {
												id: resItem.n_dossier,
												label: resItem.libelle
										  }
										: {
												id: society
										  }
								})
							)
						)
					)

				dispatch(
					actions.setUserInfos({
						firstname: user.firstName,
						lastname: user.lastName
					})
				)

				resolve()
			})
			.catch(err => {
				reject(err)
			})
	)

export default {
	setKeycloakInstance,
	disconnectUser,

	fetchUserInfos
}
