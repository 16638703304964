import * as React from 'react';

const YouSign = ({ height }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Calque_1"
		x="0"
		y="0"
		height={height || "100%"}
		viewBox="0 0 100 29.2"
	>
		<style>{`.st0{fill:#002b44}`}</style>
		<path
			d="M32.5 10.4h2.6l2.6 6 2.7-6H43l-6.4 13.9H34l2.4-4.9-3.9-9zM53 15.2c0 2.8-2.2 5.1-5 5.1-2.9 0-5-2.2-5-5.1 0-2.8 2.2-5 5.1-5 2.7-.1 4.9 2.2 4.9 5zm-7.6 0c0 1.7 1 2.9 2.6 2.9 1.5 0 2.6-1.2 2.6-2.9s-1-2.8-2.6-2.8c-1.6-.1-2.6 1.1-2.6 2.8zM60.7 19.4c-.7.6-1.3.8-2.2.8-1.1 0-2.2-.5-2.9-1.3-.7-.8-1-1.8-1-3.4v-5.1H57v4.9c0 1.2.1 1.7.5 2.1.3.3.7.5 1.3.5.7 0 1.2-.2 1.6-.7.3-.4.4-1 .4-2.2v-4.7h2.4v9.4h-2.4v-.3zM69.8 13.3c-.5-.8-.9-1-1.4-1-.6 0-1 .3-1 .8 0 .4.1.5 1.2.9l.9.3c1.6.6 2.3 1.4 2.3 2.7 0 1.8-1.5 3.2-3.5 3.2-1.2 0-2.3-.5-2.9-1.4-.3-.4-.5-.7-.7-1.5l2-.8c.6 1.2 1 1.5 1.7 1.5.6 0 1.1-.4 1.1-1 0-.4-.2-.6-1.2-.9l-1-.4c-1.4-.5-2.1-1.4-2.1-2.6 0-1.7 1.4-3 3.3-3 1.5 0 2.5.7 3.3 2.2l-2 1zM76.1 7.2c0 .8-.7 1.5-1.5 1.5-.9 0-1.5-.7-1.5-1.5s.7-1.5 1.6-1.5c.8 0 1.4.7 1.4 1.5zm-.3 12.7h-2.4v-9.4h2.4v9.4zM84.9 19.3c-1 .7-1.6.9-2.6.9-2.7 0-4.9-2.3-4.9-5.1s2.2-5 4.9-5c1 0 1.6.2 2.6.9v-.6h2.4v9.1c0 3-2 5.1-5.1 5.1-1.3 0-2.5-.4-3.4-1.2-.4-.3-.5-.4-1.2-1.3l1.6-1.6.4.4c.9 1.1 1.5 1.4 2.7 1.4 1.7 0 2.7-1.1 2.7-2.9v-.1zm-5.1-4.1c0 1.7 1 2.9 2.6 2.9 1.5 0 2.5-1.2 2.5-2.9s-1-2.8-2.6-2.8c-1.4-.1-2.5 1.1-2.5 2.8zM91.9 10.9c.7-.6 1.3-.8 2.2-.8 1.1 0 2.2.5 2.9 1.3.7.8 1 1.8 1 3.4v5.1h-2.4V15c0-1.2-.1-1.7-.5-2.1-.3-.3-.7-.5-1.3-.5-.7 0-1.2.2-1.6.7-.3.4-.4 1-.4 2.2V20h-2.4v-9.4h2.4v.3zM22.1 1.7c.1 0 .1.1.2.1L10.5 27.4H4.8c-.1 0-.1-.1-.2-.1L16.4 1.7h5.7m0-1.2h-5.8c-.4 0-.7.2-.9.5L3.5 26.8c-.4.8.4 1.8 1.3 1.8h5.8c.4 0 .7-.2.9-.5L23.4 2.3c.3-.8-.4-1.8-1.3-1.8zM3 .5h7.1c.7 0 1.2.8.9 1.4L6 12.6c-.4.9-1.7.7-1.9-.2L2 1.7c-.2-.6.3-1.2 1-1.2z"
			className="st0"
		/>
	</svg>
);

export default YouSign;
