import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	styled, Dialog,
	Typography,
	Button,
	DialogContent,
	DialogActions,
	IconButton
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuid } from "uuid";

import Signature from "./Signature";
import SignatureCanvas from "./SignatureCanvas";
import { measureTextWidth } from "../../../../../utils/textMeasure";

const FullHeightDialog = styled(Dialog)({
	"& .MuiPaper-root": {
		height: "90%",
		maxHeight: "1024px",
	},

	"& .MuiDialogContent-root": {
		paddingTop: 0,
		paddingBottom: 0,
	},
});

const DialogHeader = styled("div")(({ theme: { spacing } }) => ({
	padding: spacing(1), 
	display:"flex",
	justifyContent:"space-between",
	alignItems:"center"
}));

const DialogTitle = styled(Typography)(({ theme: { spacing } }) => ({
	fontWeight: "bold",
	paddingLeft: spacing(1)
}));

const CloseIconButton = styled(IconButton)(({ theme: { spacing } }) => ({
	padding: spacing(1)
}));

const SignatureCanvasContainer = styled("div")(({ theme: { spacing } }) => ({
	gap: spacing(3),
	display:"flex",
	height:"100%"
}));

const Signatures = styled("div")(({ theme: { spacing } }) => ({
	maxWidth: "250px",
	overflow: "auto",
	paddingBottom: spacing(2),
	display:"flex",
	flexDirection:"column",
	height:"100%",
	width:"100%"
}));

const SignatureTitle = styled(Typography)(({ theme: { spacing } }) => ({
	fontWeight: "bold",
	marginBottom: spacing(2),
}));

const SignatureContainer = styled("div")(({ theme: { spacing } }) => ({
	gap: spacing(2),
	display:"flex",
	flexDirection:"column"
}));

const StyledDialogActions = styled(DialogActions)(({ theme: { spacing } }) => ({
	padding: spacing(2), 
	paddingTop: "0"
}));

const PlaceSignaturesDialog = ({
	document,
	signatories,
	open,
	onClose,
	onValidation,
}) => {
	const { blob, nom, signatures = [] } = document || {};

	const mention = 'Lu et approuvé - Signé le JJ-MM-AAAA';
	const mentionMeasure = measureTextWidth(mention, 10, 'Inconsolata') + 12;

	const [dndItems, setDndItems] = useState([]);
	const [pagesInfos, setPagesInfos] = useState({
		pageNumber: 0,
		numPages: 0,
		width: 0,
		height: 0,
	});

	const pageNumberRef = useRef(pagesInfos.pageNumber);

	const dialogTitle = useMemo(
		() =>
			`${nom || "Placez les signatures"} (${
				pagesInfos.pageNumber
			}/${pagesInfos.numPages})`,
		[nom, pagesInfos.numPages, pagesInfos.pageNumber]
	);

	const handlePageNumberChange = useCallback(
		(newPageNumber) =>
			setPagesInfos((prevValue) => ({
				...prevValue,
				pageNumber: newPageNumber,
			})),
		[]
	);

	const itemsRenderer = useMemo(
		() =>
			signatories?.map((signatory) => (
				<Signature
					key={signatory.id}
					signatory={signatory}
					dndItems={dndItems}
					onPageNumberChange={handlePageNumberChange}
				/>
			)),
		[dndItems, handlePageNumberChange, signatories]
	);

	const handleDrop = useCallback(
		({ id, signatory, position }) =>
			setDndItems((prevDnDItems) => {
				const currentPageNumber = pageNumberRef.current;

				return !id
					? [
							...prevDnDItems,
							{
								id: uuid(),
								signatory,
								position: {
									...position,
									height: 80 + 45,
									width: 184
								},
								pageNumber: currentPageNumber,
							},
					  ]
					: prevDnDItems.map((item) =>
							item.id === id
								? {
										...item,
										position,
										pageNumber: currentPageNumber,
								  }
								: item
					  );
			}),
		[]
	);

	const deleteSignature = useCallback(
		(id) =>
			setDndItems((prevValue) =>
				prevValue.filter(({ id: signatureId }) => id !== signatureId)
			),
		[]
	);

	const resizeSignature = useCallback((id, width, height) => {
		setDndItems((prevValue) => {
			const foundSignature = prevValue.find(
				({ id: signatureId }) => id === signatureId
			);
			const foundSignaturePosition = foundSignature?.position || {
				x: 0,
				y: 0,
			};

			return foundSignature
				? [
						...prevValue.filter(
							({ id: signatureId }) => id !== signatureId
						),
						{
							...foundSignature,
							position: {
								...foundSignaturePosition,
								width,
								height,
							},
						},
				  ]
				: prevValue;
		});
	}, []);

	const handleCloseDialog = useCallback(() => {
		setDndItems([])
		setPagesInfos({
			pageNumber: 0,
			numPages: 0,
			width: 0,
			height: 0
		})

		onClose()
	}, [onClose])

	const handleValidation = useCallback(() => {
		onValidation({
			document, 
			signatures: dndItems?.map(({ position: { x, y, height, width }, signatory: { id: signatoryId }, pageNumber }) => ({ 
				id_contact: signatoryId, 
				page: `${pageNumber}`, 
				coord: `${Math.round(x)},${Math.round(y + (mentionMeasure < Math.round(width) ? 24 : 45))},${Math.round(x + width)},${Math.round(y + height)}`,
				mentionCoord: `${Math.round(x)},${Math.round(y)},${Math.round(x + width)},${Math.round(y + (mentionMeasure < Math.round(width) ? 24 : 45))}`
			}))
		});
		handleCloseDialog();
	}, [dndItems, handleCloseDialog, onValidation, document, mentionMeasure]);

	useEffect(() => {
		if (signatures.length > 0) {
			setDndItems(signatures?.map(({ id_contact, page, coord }) => {
				const [x, y, x2, y2] = coord.split(",");
	
				return { 
					pageNumber: +page, 
					signatory: {
						id: id_contact,
						name: signatories.find(({id}) => id === id_contact)?.name || ''
					}, 
					id: uuid(),
					position: { 
						x: +x, 
						y: +y - (mentionMeasure < Math.round(+x2 - +x) ? 24 : 45), 
						width: +x2 - +x, 
						height: +y2 - +y + (mentionMeasure < Math.round(+x2 - +x) ? 24 : 45)
					}
				};
			}))
		}
	}, [signatories, signatures, mentionMeasure]);

	useEffect(() => {
		pageNumberRef.current = pagesInfos.pageNumber;
	}, [pagesInfos.pageNumber]);

	return (
		<DndProvider backend={HTML5Backend} context={window}>
			<FullHeightDialog open={open} onClose={handleCloseDialog} maxWidth="xl">
				<DialogHeader>
					<DialogTitle variant="subtitle1">{dialogTitle}</DialogTitle>

					<CloseIconButton onClick={handleCloseDialog}>
						<CloseIcon />
					</CloseIconButton>
				</DialogHeader>

				<DialogContent>
					<SignatureCanvasContainer>
						<SignatureCanvas
							document={blob}
							dndItems={dndItems}
							pagesInfos={pagesInfos}
							onPagesInfosChange={setPagesInfos}
							onSignatureDelete={deleteSignature}
							onDrop={handleDrop}
							onSignatureResize={resizeSignature}
						/>

						<Signatures>
							{signatories.length > 0 && (
								<>
									<SignatureTitle	variant="body1">
										Signatures à placer
									</SignatureTitle>

									<SignatureContainer>
										{itemsRenderer}
									</SignatureContainer>
								</>
							)}
						</Signatures>
					</SignatureCanvasContainer>
				</DialogContent>

				<StyledDialogActions>
					<Button variant="text" onClick={handleCloseDialog}>
						Annuler
					</Button>

					<Button
						variant="contained"
						startIcon={<CheckIcon />}
						onClick={handleValidation}
						color="primary"
					>
						Enregistrer le placement
					</Button>
				</StyledDialogActions>
			</FullHeightDialog>
		</DndProvider>
	);
};

export default PlaceSignaturesDialog;
