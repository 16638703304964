import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import { Paper, styled } from "@material-ui/core"

import { procedureOperations } from "../../modules/procedure"
import { userSelectors } from "../../modules/user"

import pages from "../../utils/pagesConstants"
import useStateWithLabel from "../../utils/useStateWithLabel"
import VirtualizedTable from "../../template/components/VirtualizedTable"

const columns = [
	{
		width: 100,
		label: "Dossier",
		dataKey: "id"
	},
	{
		width: 300,
		label: "Raison sociale",
		dataKey: "label"
	}
]

const SocietiesTable = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const societies = useSelector(state =>
		userSelectors.getSocieties(state.user)
	)

	const [sortBy, setSortBy] = useStateWithLabel(null, "sortBy")
	const [sortDirection, setSortDirection] = useStateWithLabel(
		"ASC",
		"sortDirection"
	)

	const Container = styled(Paper)({
		minHeight: "calc(47px * 9)",
		height: `calc(47px * ${societies.length})`,
		maxHeight: "73.5vh",
		width: columns.map(column => column.width).reduce((a, b) => a + b, 0)
	})

	// Fonction générant le rendu des cellules
	const cellRenderer = ({ cellData }) => <div>{cellData}</div>

	// Fonction permettant de naviguer vers la page des procédures
	const navigateToProcedurePages = ({ rowData }) => {
		dispatch(procedureOperations.setSociety(rowData))
		history.push(pages.PROCEDURE.endpoint)
	}

	// Fonction permettant de trier le tableau
	const sortTable = table =>
		!!sortBy
			? [...table].sort((a, b) =>
					sortDirection === "ASC"
						? a[sortBy].localeCompare(b[sortBy])
						: b[sortBy].localeCompare(a[sortBy])
			  )
			: table

	const getSocietyAtIndex = ({ index }) => sortTable(societies)[index]

	return (
		<Container>
			<VirtualizedTable
				columns={columns}
				rowCount={societies.length}
				rowGetter={getSocietyAtIndex}
				onRowClick={navigateToProcedurePages}
				cellRenderer={cellRenderer}
				sortBy={sortBy}
				setSortBy={setSortBy}
				sortDirection={sortDirection}
				setSortDirection={setSortDirection}
			/>
		</Container>
	)
}

export default SocietiesTable
