import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import {
	Dialog,
	DialogActions,
	Box,
	Button,
	styled,
	DialogTitle,
	Typography,
	Avatar,
	Chip,
	DialogContent,
	CircularProgress,
} from "@material-ui/core";

import { contactSelectors, contactOperations } from "../../modules/contact";
import useStateWithLabel from "../../utils/useStateWithLabel";
import { userSelectors } from "../../modules/user";
import { generalOperations, generalSelectors } from "../../modules/general";
import ViewContent from "./contactDialog/ViewContent";
import EditionContent from "./contactDialog/EditionContent";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

const DialogHeader = styled(DialogTitle)(({ theme }) => ({
	userSelect: "none",
	backgroundColor: theme.palette.background.default,
	zIndex: 10,
	boxShadow: theme.shadows[1],

	"& h6": {
		marginBottom: 0,
	},
}));

const DeleteButton = styled(Button)(({ theme }) => ({
	backgroundColor: "#F44336",
	color: theme.palette.getContrastText("#F44336"),

	"&:hover": {
		backgroundColor: "#D32F2F",
	},
}));

const HeaderInfosWrapper = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",

	"& > div:first-child": {
		marginRight: theme.spacing(1),
	},
}));

const PrivacyChip = styled(Chip)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.getContrastText(theme.palette.primary.main),
}));

const LoadingWrapper = styled("div")({
	backgroundColor: "rgba(0, 0, 0, .3)",

	position: "absolute",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: 9999,

	display: "flex",
	justifyContent: "center",
	alignItems: "center",
});

const renderPrivacyChip = ({ privacy }) => (
	<PrivacyChip label={privacy ? "Privé" : "Publique"} />
);

const renderDialogTitle = ({ mode }) => (
	<Typography variant="h6">
		{mode === "create" ? "Créer" : "Modifier"} un contact
	</Typography>
);

const ContactDialog = () => {
	const phoneUtil = PhoneNumberUtil.getInstance();

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const isOpen = useSelector((state) =>
		contactSelectors.isDialogOpen(state.contact)
	);
	const mode = useSelector((state) =>
		contactSelectors.getDialogMode(state.contact)
	);
	const contact = useSelector((state) =>
		contactSelectors.getContact(state.contact)
	);
	const societies = useSelector((state) =>
		userSelectors.getSocieties(state.user)
	);
	const socket = useSelector((state) =>
		generalSelectors.getSocket(state.general)
	);

	const [editedContact, setEditedContact] = useStateWithLabel(
		contact,
		"editedContact"
	);
	const [isValidationDisabled, setValidationDisabled] = useStateWithLabel(
		true,
		"isValidationDisabled"
	);
	const [isPhoneUnvalid, setIsPhoneUnalid] = useStateWithLabel(
		true,
		"isPhoneValid"
	);
	const [isLoading, setIsLoading] = useStateWithLabel(false, "isLoading");

	const setMode = (newMode) =>
		dispatch(contactOperations.setDialogMode(newMode));

	const closeDialog = () =>
		dispatch(
			generalOperations.openConfirmationDialog({
				content:
					"Êtes-vous sûr de vouloir quitter cette fiche contact ?",
				action: () => {
					dispatch(contactOperations.closeContactDialog());
					setEditedContact(null);
				},
			})
		);

	const deleteContact = () =>
		socket?.authenticated
			? dispatch(contactOperations.deleteContact(contact.id))
					.then(() => closeDialog())
					.catch((err) =>
						enqueueSnackbar(err, {
							variant: "error",
						})
					)
			: enqueueSnackbar("Problème de connexion au serveur", {
					variant: "error",
			  });

	const createContact = () => {
		if (socket?.authenticated) {
			dispatch(
				contactOperations.createContact({
					...editedContact,
					telephone: phoneUtil.format(
						phoneUtil.parse(editedContact.telephone, "FR"),
						PhoneNumberFormat.E164
					),
				})
			).then(() => {
				dispatch(contactOperations.closeContactDialog());
				dispatch(contactOperations.fetchContacts(societies));

				setIsLoading(false);
			});
		} else {
			setIsLoading(false);

			enqueueSnackbar("Problème de connexion au serveur", {
				variant: "error",
			});
		}
	};

	const handleCancelation = () => {
		setMode("view");
		setEditedContact(contact);
	};

	const renderLeftDialogAction = () => {
		if (mode === "create") {
			return <Button onClick={closeDialog}>Fermer</Button>;
		}

		return <Button onClick={handleCancelation}>Annuler</Button>;
	};

	const renderDialogContent = () => {
		if (mode === "view") {
			return <ViewContent contact={contact} />;
		}

		return (
			<EditionContent
				mode={mode}
				contact={editedContact}
				setContact={setEditedContact}
				onError={setIsPhoneUnalid}
			/>
		);
	};

	const handleDeletion = () =>
		dispatch(
			generalOperations.openConfirmationDialog({
				content: "Êtes-vous sûr de vouloir supprimer ce contact ?",
				action: deleteContact,
			})
		);

	const handleValidation = () => {
		setIsLoading(true);

		mode === "create" && createContact();
	};

	const renderDialogHeader = () => {
		if (mode !== "view") {
			return (
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					{renderDialogTitle({ mode })}
					{mode === "edit" && (
						<DeleteButton onClick={handleDeletion}>
							Supprimer
						</DeleteButton>
					)}
				</Box>
			);
		}

		return (
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<HeaderInfosWrapper>
					<Avatar>
						{contact.prenom.charAt(0)}
						{contact.nom.charAt(0)}
					</Avatar>
					<Typography variant="h6">
						{contact.prenom + " " + contact.nom}
					</Typography>
				</HeaderInfosWrapper>
				{renderPrivacyChip({ privacy: contact.prive })}
			</Box>
		);
	};

	useEffect(() => {
		setEditedContact(contact);
	}, [contact, setEditedContact]);

	useEffect(() => {
		setValidationDisabled(
			!(
				editedContact &&
				editedContact.nom &&
				editedContact.prenom &&
				editedContact.email &&
				editedContact.telephone &&
				!isPhoneUnvalid &&
				editedContact.prive &&
				editedContact.n_dossier
			)
		);
	}, [editedContact, setValidationDisabled, isPhoneUnvalid]);

	return (
		<Dialog open={isOpen} onClose={closeDialog} maxWidth="xs" fullWidth>
			<DialogHeader>{renderDialogHeader()}</DialogHeader>
			<DialogContent>
				{isLoading && (
					<LoadingWrapper
						onClick={(event) => event.stopPropagation()}
					>
						<CircularProgress size={60} />
					</LoadingWrapper>
				)}
				{renderDialogContent()}
			</DialogContent>
			<DialogActions>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					flex="1"
				>
					{mode === "view" && (
						<Fragment>
							<Button onClick={closeDialog}>Fermer</Button>
						</Fragment>
					)}
					{mode !== "view" && (
						<Fragment>
							{renderLeftDialogAction()}
							<Button
								variant="contained"
								color="primary"
								disabled={isValidationDisabled}
								onClick={handleValidation}
							>
								Valider
							</Button>
						</Fragment>
					)}
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default ContactDialog;
