/**
 * @author 	Romain Garcia
 * @version	2020/09/02
 * @see		"./types"
 */

import produce from 'immer'
import { combineReducers } from 'redux'

import types from './types'

const convertStringArrayToCamelCase = ({ stringArray }) => {
	let result = ''

	stringArray.forEach(part => {
		if (result.length === 0) {
			result += part.toLowerCase()
		} else {
			result += part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
		}
	})

	return result
}

// SOCIETE
const societyInitialState = {
	society: {},
	procedures: [],
	archivedProcedures: [],
	usedProcedures: [],
	recentlyUpdatedProcedures: [],
	currentYearSignatures: 0,
};

const societyReducer = (state = { ...societyInitialState }, { type, payload } = {}) => {
	const finalType = type.split('/')[2]

	if (!Object.prototype.hasOwnProperty.call(types, finalType)) {
		return state;
	}

	const typeParts = finalType.split('_');
	typeParts.shift();

	let convertedType = convertStringArrayToCamelCase({
		stringArray: typeParts,
	});

	if (convertedType !== 'society' && convertedType.charAt(convertedType.length - 1) !== 's') {
		convertedType += 's';
	}

	if (!(convertedType in state)) {
		return state;
	}

	if (type.includes('SET_')) {
		return produce(state, draft => {
			if (JSON.stringify(draft[convertedType]) !== JSON.stringify(payload)) {
				draft[convertedType] = payload;
			}
		});
	} else if (type.includes('ADD_')) {
		return produce(state, draft => {
			draft[convertedType].push(payload);
		});
	} else if (type.includes('REMOVE_')) {
		return produce(state, draft => {
			let itemIndex;

			if (!!draft[convertedType][0]?.id) {
				itemIndex = draft[convertedType].findIndex(item => item.id === +payload);
			} else {
				itemIndex = draft[convertedType].indexOf(payload);
			}

			draft[convertedType].splice(itemIndex, 1);
		});
	}

	// Case UPDATE
	return produce(state, draft => {
		let itemIndex

		itemIndex = draft[convertedType].findIndex(item => item.id === payload.id)
		draft[convertedType].splice(itemIndex, 1, payload)
	})
}

// PROCEDURE
const procedureInitialState = {
	isDialogOpen: false,
	dialogMode: '',
	procedure: null
}

const procedureReducer = (state = { ...procedureInitialState }, { type, payload } = {}) => {
	switch (type) {
		case types.OPEN_PROCEDURE_DIALOG:
			return produce(state, draft => {
				const { procedure, mode } = payload

				draft.isDialogOpen = true
				draft.dialogMode = mode
				draft.procedure = procedure
			})

		case types.CLOSE_PROCEDURE_DIALOG:
			return produce(state, draft => {
				draft.isDialogOpen = false
				draft.dialogMode = ''
				draft.procedure = null
			})

		case types.SET_DIALOG_MODE:
			return produce(state, draft => {
				draft.dialogMode = payload
			})

		case types.SET_SELECTED_PROCEDURE:
			return produce(state, draft => {
				draft.procedure = payload
			})

		default:
			return state
	}
}

// DEPOT DE FICHIER
const uploadDocumentInitialState = {
	isDialogOpen: false
}

const uploadDocumentReducer = (state = { ...uploadDocumentInitialState }, { type } = {}) => {
	switch (type) {
		case types.OPEN_UPLOAD_DOCUMENT_DIALOG:
			return produce(state, draft => {
				draft.isDialogOpen = true
			})

		case types.CLOSE_UPLOAD_DOCUMENT_DIALOG:
			return produce(state, draft => {
				draft.isDialogOpen = false
			})

		default:
			return state
	}
}

export default combineReducers({
	society: societyReducer,
	procedure: procedureReducer,
	uploadDocument: uploadDocumentReducer
})
