export default [
	{
		id: "ongoing",
		label: "En cours"
	},
	{
		id: "archived",
		label: "Archives"
	}
]
