import React, { Fragment } from 'react'

import { Box, Typography } from '@material-ui/core';

import Appbar from './components/Appbar';

import Contact from '../pages/Contact';
import Home from '../pages/Home'
import Procedure from '../pages/Procedure';
import ContactDialog from '../pages/contact/ContactDialog'
import ConfirmationDialog from './components/ConfirmationDialog'

const Layout = ({ page, withAppbar }) => {
	const renderContent = () => {
		switch (page) {
			case 'home':
				return (
					<Box display="flex" flexDirection="column" alignItems="center">
						<Typography variant="h3" color="secondary">
							Accueil
						</Typography>
						<Home />
					</Box>
				)

			case 'procedure':
				return (
					<Fragment>
						<Typography variant="h3" align="center" color="secondary">
							Procédures
						</Typography>
						<Procedure />
					</Fragment>
				)

			case 'contact':
				return (
					<Fragment>
						<Typography variant="h3" align="center" color="secondary">
							Contacts
						</Typography>
						<Contact />
					</Fragment>
				)

			default:
				break
		}
	}

	return (
		<Fragment>
			{withAppbar && <Appbar page={page} />}

			<main>{renderContent()}</main>

			{/* <SettingsDialog /> */}
			<ContactDialog />
			<ConfirmationDialog />
		</Fragment>
	)
}

export default Layout
