import React, {
	useCallback, useState,
	forwardRef,
	useEffect
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { styled, TextField, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentViewerWrapper = styled("div")({
	height:"100%",
	display:"flex",
	flexDirection:"column",
	justifyContent:"center",
	alignItems:"center"
});

const DocumentViewerContainer = styled("div")(({ theme: { palette, shape, shadows } }) => ({
	boxShadow: shadows[1],
	borderRadius: shape.borderRadius,
	border: `1px solid ${palette.divider}`,
	overflow: "auto",
	position: "relative",

	'& .react-pdf__Page.loadingPage':  {
		display: "none",
	}
}));

const IconButtonWithPadding = styled(IconButton)(({ theme: { spacing } }) => ({
	padding: spacing(1)
}));

const PagesNavigation = styled("div")(({ theme: { spacing } }) => ({
	display:"flex",
	justifyContent:"center",
	gap: spacing(1), 
	marginTop: spacing(1)
}));

const PagesNavigationTextField = styled("div")({
	display:"flex",
	alignItems:"center",
	justifyContent:"center",
	width: 60
});

const DocumentViewer = forwardRef(
	(
		{ document, onPagesInfosChange, children, pageNumber: propsPageNumber },
		ref
	) => {
		const [numPages, setNumPages] = useState(null);
		const [pageNumber, setPageNumber] = useState(1);
		const [renderedPageNumber, setRenderedPageNumber] = useState(null);
		const [inputPageNumber, setInputPageNumber] = useState(1);

		const onDocumentLoadSuccess = useCallback(
			({ numPages: documentNumPages }) => {
				setNumPages(documentNumPages);
				setInputPageNumber(documentNumPages > 0 ? 1 : 0);
				onPagesInfosChange((prevValue) => ({
					...prevValue,
					numPages: documentNumPages,
					pageNumber: documentNumPages > 0 ? 1 : 0,
				}));
			},
			[onPagesInfosChange]
		);

		const setRenderedPageNumberToPageNumber = useCallback(
			({ width, height }) => {
				onPagesInfosChange((prevValue) => ({
					...prevValue,
					width,
					height,
				}));
				setRenderedPageNumber(pageNumber);
			},
			[onPagesInfosChange, pageNumber]
		);

		const navigateToFirstPage = useCallback(() => {
			onPagesInfosChange((prevValue) => ({
				...prevValue,
				numPages,
				pageNumber: 1,
			}));
			setInputPageNumber(1);
			setPageNumber(1);
		}, [numPages, onPagesInfosChange]);

		const navigateToPreviousPage = useCallback(() => {
			onPagesInfosChange((prevValue) => ({
				...prevValue,
				numPages,
				pageNumber: pageNumber - 1,
			}));
			setInputPageNumber(pageNumber - 1);
			setPageNumber((prevValue) => prevValue - 1);
		}, [numPages, onPagesInfosChange, pageNumber]);

		const navigateToNextPage = useCallback(() => {
			onPagesInfosChange((prevValue) => ({
				...prevValue,
				numPages,
				pageNumber: pageNumber + 1,
			}));
			setInputPageNumber(pageNumber + 1);
			setPageNumber((prevValue) => prevValue + 1);
		}, [numPages, onPagesInfosChange, pageNumber]);

		const navigateToLastPage = useCallback(() => {
			onPagesInfosChange((prevValue) => ({
				...prevValue,
				numPages,
				pageNumber: numPages,
			}));
			setInputPageNumber(numPages);
			setPageNumber(numPages);
		}, [numPages, onPagesInfosChange]);

		const handlePageNumberChange = useCallback(
			({ target: { value } }) => setInputPageNumber(value),
			[]
		);

		const handlePageNumberBlur = useCallback(
			(event) => {
				const selectedPageNumber = +event.target.value || 0;

				if (selectedPageNumber <= 0) {
					onPagesInfosChange((prevValue) => ({
						...prevValue,
						numPages,
						pageNumber: 1,
					}));
					setPageNumber(1);
					setInputPageNumber(1);
				} else if (selectedPageNumber > numPages) {
					onPagesInfosChange((prevValue) => ({
						...prevValue,
						numPages,
						pageNumber: numPages,
					}));
					setPageNumber(numPages);
					setInputPageNumber(numPages);
				} else {
					onPagesInfosChange((prevValue) => ({
						...prevValue,
						numPages,
						pageNumber: selectedPageNumber,
					}));
					setPageNumber(selectedPageNumber);
				}
			},
			[numPages, onPagesInfosChange]
		);

		useEffect(() => {
			if (propsPageNumber > 0) {
				setInputPageNumber(propsPageNumber);
				setPageNumber(propsPageNumber);
			}
		}, [propsPageNumber]);

		useEffect(() => {
			if (ref && "current" in ref && ref.current) {
				ref.current.scrollTo(0, 0);
			}
		}, [pageNumber, ref]);

		return (
			<DocumentViewerWrapper>
				<DocumentViewerContainer ref={ref}>
					<Document
						file={document}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						{renderedPageNumber !== pageNumber && renderedPageNumber ? (
							<Page
								key={renderedPageNumber}
								pageNumber={renderedPageNumber}
								renderAnnotationLayer={false}
								renderTextLayer={false}
							/>
						) : null}

						<Page
							key={pageNumber}
							className={`${renderedPageNumber !== pageNumber ? 'loadingPage' : ''}`}
							pageNumber={pageNumber}
							onRenderSuccess={setRenderedPageNumberToPageNumber}
							renderAnnotationLayer={false}
							renderTextLayer={false}
						/>

						{children}
					</Document>
				</DocumentViewerContainer>

				<PagesNavigation>
					<IconButtonWithPadding onClick={navigateToFirstPage} disabled={pageNumber === 1}>
						<FirstPageIcon />
					</IconButtonWithPadding>
					<IconButtonWithPadding onClick={navigateToPreviousPage} disabled={pageNumber === 1}>
						<ChevronLeftIcon />
					</IconButtonWithPadding>

					<PagesNavigationTextField>
						<TextField
							value={inputPageNumber}
							onChange={handlePageNumberChange}
							onBlur={handlePageNumberBlur}
						/>
					</PagesNavigationTextField>

					<IconButtonWithPadding onClick={navigateToNextPage} disabled={pageNumber === numPages}>
						<ChevronRightIcon />
					</IconButtonWithPadding>
					<IconButtonWithPadding onClick={navigateToLastPage} disabled={pageNumber === numPages}>
						<LastPageIcon />
					</IconButtonWithPadding>
				</PagesNavigation>
			</DocumentViewerWrapper>
		);
	}
);

export default DocumentViewer;
