import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import {
  styled,
  Paper,
  Tooltip,
  Box,
  IconButton,
  SvgIcon,
  Table,
  TableFooter,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";

import { contactSelectors, contactOperations } from "../../modules/contact";
import { userSelectors } from "../../modules/user";

import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import DeleteIcon from "@material-ui/icons/Delete";

import VirtualizedTable from "../../template/components/VirtualizedTable";

import useStateWithLabel from "../../utils/useStateWithLabel";
import { generalOperations, generalSelectors } from "../../modules/general";

const columns = [
  {
    width: 40,
    label: "",
    dataKey: "privacy",
  },
  {
    width: 100,
    label: "Dossier",
    dataKey: "societyId",
  },
  {
    width: 150,
    label: "Nom",
    dataKey: "lastname",
  },
  {
    width: 150,
    label: "Prénom",
    dataKey: "firstname",
  },
  {
    width: 250,
    label: "Adresse mail",
    dataKey: "mail",
    disableSort: true,
  },
  {
    width: 170,
    label: "Numéro de téléphone",
    dataKey: "phoneNumber",
    disableSort: true,
  },
  {
    width: 90,
    label: "Actions",
    dataKey: "actions",
    disableSort: true,
    centered: true,
  },
];

const CenteredColumn = styled("div")({
  paddingLeft: "0 !important",
  justifyContent: "center",
});

const PrivacyIcon = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: 50,
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.getContrastText(theme.palette.grey[300]),
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(0.25),

  "& svg": {
    fill: "#fff",
    fontSize: "18px",
  },
}));

const DeleteButton = styled(ActionButton)({
  backgroundColor: "#F44336",

  "&:hover": {
    backgroundColor: "#D32F2F",
  },
});

const TableFooterContainer = styled(Table)(({ theme }) => ({
  position: "sticky",
  left: 0,
  right: 0,
  bottom: 0,

  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,

  backgroundColor: theme.palette.background.paper,
  boxShadow:
    "rgb(0 0 0 / 20%) 0px -2px 1px -1px, rgb(0 0 0 / 14%) 0px -1px 1px 0px, rgb(0 0 0 / 12%) 0px -1px 3px 0px",

  padding: 0,
  margin: 0,

  "& td": {
    height: 52,
    border: 0,

    padding: theme.spacing(0, 0, 0, 1),
  },
}));

const ContactTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const socket = useSelector((state) =>
    generalSelectors.getSocket(state.general)
  );
  const contacts = useSelector((state) =>
    contactSelectors.getContacts(state.contact)
  );
  const societies = useSelector((state) =>
    userSelectors.getSocieties(state.user)
  );

  const [sortBy, setSortBy] = useStateWithLabel(null, "sortBy");
  const [sortDirection, setSortDirection] = useStateWithLabel(
    "ASC",
    "sortDirection"
  );
  const [isLoading, setIsLoading] = useStateWithLabel(false, "isLoading");

  const Container = styled(Paper)({
    marginLeft: "16px",
    width: columns.map((column) => column.width).reduce((a, b) => a + b, 0),
  });

  const TableWrapper = styled("div")({
    minHeight: "calc(47px * 9)",
    height: `calc(47px * ${contacts.length})`,
    maxHeight: "68vh",
    width: "100%",
  });

  const deleteContact = (contactId) =>
    socket?.authenticated
      ? dispatch(contactOperations.deleteContact(contactId))
      : enqueueSnackbar("Problème de connexion au serveur", {
          variant: "error",
        });

  const openConfirmationDialog = (props) =>
    dispatch(generalOperations.openConfirmationDialog(props));

  const cellRenderer = ({ rowData, columnIndex }) => {
    switch (columnIndex) {
      case 0:
        return (
          <CenteredColumn>
            <Tooltip title={rowData.prive ? "Privé" : "Publique"}>
              <PrivacyIcon
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <SvgIcon
                  component={rowData.prive ? LockIcon : PublicIcon}
                  fontSize="small"
                />
              </PrivacyIcon>
            </Tooltip>
          </CenteredColumn>
        );

      case 1:
        return <div>{rowData.n_dossier}</div>;

      case 2:
        return <div>{rowData.nom}</div>;

      case 3:
        return <div>{rowData.prenom}</div>;

      case 4:
        return <div>{rowData.email}</div>;

      case 5:
        return <div>{rowData.telephone.replace(/(.{2})/g, "$1 ")}</div>;

      case 6:
        return (
          <CenteredColumn>
            <Tooltip title="Supprimer">
              <DeleteButton
                onClick={(event) => {
                  event.stopPropagation();

                  openConfirmationDialog({
                    content: "Êtes-vous sûr de vouloir supprimer ce contact ?",
                    action: () => {
                      deleteContact(rowData.id);
                    },
                  });
                }}
              >
                <DeleteIcon />
              </DeleteButton>
            </Tooltip>
          </CenteredColumn>
        );

      default:
        break;
    }
  };

  const sortTable = (table) => {
    const sortByHasValue = !!sortBy;

    if (!sortByHasValue) {
      return table;
    }

    let tmpContacts = [...table];

    const sortByMapper = {
      privacy: "prive",
      societyId: "n_dossier",
      lastname: "nom",
      firstname: "prenom",
    };

    if (sortBy === "privacy") {
      return tmpContacts.sort((a, b) =>
        sortDirection === "ASC"
          ? a[sortByMapper[sortBy]] - b[sortByMapper[sortBy]]
          : b[sortByMapper[sortBy]] - a[sortByMapper[sortBy]]
      );
    }

    return tmpContacts.sort((a, b) =>
      sortDirection === "ASC"
        ? a[sortByMapper[sortBy]].localeCompare(b[sortByMapper[sortBy]])
        : b[sortByMapper[sortBy]].localeCompare(a[sortByMapper[sortBy]])
    );
  };

  const handleCreation = () =>
    dispatch(
      contactOperations.openContactDialog({
        contact: {
          nom: "",
          prenom: "",
          prive: "0",
          email: "",
          telephone: "",
          n_dossier: "",
        },
        mode: "create",
        creationMode: "general",
      })
    );

  const handleRowClick = ({ rowData }) =>
    dispatch(
      contactOperations.openContactDialog({
        contact: rowData,
        mode: "view",
      })
    );

  const getContactAtIndex = ({ index }) => sortTable(contacts)[index];

  useEffect(() => {
    if (socket?.authenticated) {
      setIsLoading(true);

      dispatch(contactOperations.fetchContacts(societies))
        .then(() => setIsLoading(false))
        .catch((err) => {
          setIsLoading(false);
          enqueueSnackbar(
            typeof err === "string"
              ? err
              : "Erreur lors de la récupération des contacts",
            {
              variant: "error",
            }
          );
        });
    }
  }, [dispatch, enqueueSnackbar, socket, societies, setIsLoading]);

  return (
    <Container>
      <TableWrapper>
        <VirtualizedTable
          isLoading={isLoading}
          columns={columns}
          rowCount={contacts.length}
          rowGetter={getContactAtIndex}
          onRowClick={handleRowClick}
          cellRenderer={cellRenderer}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </TableWrapper>
      <TableFooterContainer>
        <TableFooter>
          <TableRow>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreation}
              >
                Créer un contact
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </TableFooterContainer>
    </Container>
  );
};

export default ContactTable;
