/**
 * @author	Romain Garcia
 * @version 2020/08/13
 * @see		"./actions"
 */

import { default as socketActions } from "../../utils/socket";
import actions from "./actions";

// LISTE DE CONTACTS
/**
 * Socket: Récupère les contacts appartenant aux dossiers renseignés
 *
 * @param {array} societies => liste des dossiers pour lesquels il faut récupérer les contacts
 */
const fetchContacts = (societies) => (dispatch) =>
  new Promise((resolve, reject) =>
    socketActions
      .request("getContacts", {
        n_dossiers: societies.map((society) => society.id),
      })
      .then((res) => {
        let contactIndex = 0;

        dispatch(
          actions.setContacts(
            res.map((contact) => ({
              ...contact,
              contact_id: contactIndex++,
            }))
          )
        );

        resolve();
      })
      .catch(reject)
  );
/**
 * Socket: Supprime le contact renseigné
 *
 * @param {string} contactId => identifiant du contact à supprimer
 *
 * @return {Promise}	=>
 * 						(resolve)
 * 						(reject)	=> l'erreur renvoyée par la socket
 */
const deleteContact = (contactId) => (dispatch) =>
  new Promise((resolve, reject) =>
    socketActions
      .request("deleteContact", {
        id: contactId,
      })
      .then(() => {
        dispatch(actions.removeContact(contactId));
        resolve();
      })
      .catch(reject)
  );

// CONTACT
/**
 * Socket: Créé le contact renseigné
 *
 * @param {object}	contact => le contact à créer
 *
 * @return {Promise}	=>
 * 						(resolve)
 * 						(reject)	=> l'erreur renvoyée par la socket
 */
const createContact = (contact) => () =>
  new Promise((resolve, reject) =>
    socketActions
      .request("addContact", {
        prenom: contact.prenom,
        nom: contact.nom,
        email: contact.email,
        telephone: contact.telephone,
        prive: contact.prive,
        n_dossier: contact.n_dossier,
        id_procedure: contact.id_procedure,
      })
      .then(resolve)
      .catch(reject)
  );
/**
 * Appelle l'action d'ouverture de la dialog de visualisation/modification de contact
 *
 * @param {object}	props =>
 * 						{object}	contact : contact à visualiser/modifier,
 *		 				{string}	mode: mode de la dialog ("create", "edit", "view")
 */
const openContactDialog = (props) => (dispatch) =>
  dispatch(actions.openContactDialog(props));
/**
 * Appelle l'action de fermeture de la dialog de visualisation/modification de contact
 */
const closeContactDialog = () => (dispatch) =>
  dispatch(actions.closeContactDialog());
/**
 * Appelle l'action appliquant un mode à la dialog de visualisation/modification de contact
 *
 * @param {string}	mode => mode à appliquer ("create", "edit", "view")
 */
const setDialogMode = (mode) => (dispatch) =>
  dispatch(actions.setDialogMode(mode));

export default {
  fetchContacts,
  deleteContact,

  createContact,
  openContactDialog,
  closeContactDialog,
  setDialogMode,
};
