/**
 * @author	Romain Garcia
 * @version	2020/08/13
 */

// CONNEXION
const SET_KEYCLOAK_INSTANCE = "app/user/SET_KEYCLOAK_INSTANCE"

// UTILISATEUR
const SET_LASTNAME = "app/user/SET_LASTNAME"
const SET_FIRSTNAME = "app/user/SET_FIRSTNAME"
const SET_SOCIETIES = "app/user/SET_SOCIETIES"

export default {
	SET_KEYCLOAK_INSTANCE,

	SET_LASTNAME,
	SET_FIRSTNAME,
	SET_SOCIETIES
}