/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 * @see		"./types"
 */

import produce from "immer"
import { combineReducers } from "redux"

import types from "./types"

// THEME
const themeInitialState = {
	selected: "light"
}

const themeReducer = (
	state = { ...themeInitialState },
	{ type, payload } = {}
) =>
	type === types.CHANGE_THEME
		? produce(state, draft => {
				draft.selected = payload
		  })
		: state

// MENU DE PROFIL
const profileMenuInitialState = {
	isOpen: false,
	anchorEl: null
}

const profileMenuReducer = (
	state = { ...profileMenuInitialState },
	{ type, payload } = {}
) =>
	type === types.TOGGLE_PROFILE_MENU
		? produce(state, draft => {
				draft.isOpen = !state.isOpen
				draft.anchorEl = payload
		  })
		: state

// DIALOG DE PARAMETRES
const settingsDialogInitialState = {
	isOpen: false
}

const settingsDialogReducer = (
	state = { ...settingsDialogInitialState },
	{ type } = {}
) =>
	type === types.TOGGLE_SETTINGS_DIALOG
		? produce(state, draft => {
				draft.isOpen = !state.isOpen
		  })
		: state

// DIALOG DE CONFIRMATION
const confirmationDialogInitialState = {
	isOpen: false,
	content: "",
	action: null
}

const confirmationDialogReducer = (
	state = { ...confirmationDialogInitialState },
	{ type, payload } = {}
) => {
	if (type === types.OPEN_CONFIRMATION_DIALOG) {
		return produce(state, draft => {
			draft.isOpen = true
			draft.content = payload.content
			draft.action = payload.action
		})
	}

	if (type === types.CLOSE_CONFIRMATION_DIALOG) {
		return produce(state, draft => {
			draft.isOpen = confirmationDialogInitialState.isOpen
			draft.content = confirmationDialogInitialState.content
			draft.action = confirmationDialogInitialState.action
		})
	}

	return state
}

// SOCKET
const socketInitialState = {
	socket: undefined
}

const socketReducer = (
	state = { ...socketInitialState },
	{ type, payload } = {}
) =>
	type === types.SET_SOCKET
		? produce(state, draft => {
				draft.socket = payload
		  })
		: state

export default combineReducers({
	theme: themeReducer,
	profileMenu: profileMenuReducer,
	settingsDialog: settingsDialogReducer,
	confirmationDialog: confirmationDialogReducer,
	socket: socketReducer
})
