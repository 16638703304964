export default {
	HOME: {
		endpoint: "/accueil",
		name: "home"
	},
	PROCEDURE: {
		endpoint: "/procedures",
		name: "procedure"
	},
	CONTACT: {
		endpoint: "/contacts",
		name: "contact"
	}
}
