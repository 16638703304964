/**
 * @author 	Romain Garcia
 * @version 2020/08/13
 */

// THEME
const CHANGE_THEME = "app/general/CHANGE_THEME"

// MENU DE PROFIL
const TOGGLE_PROFILE_MENU = "app/general/TOGGLE_PROFILE_MENU"

// DIALOG DE PARAMETRES
const TOGGLE_SETTINGS_DIALOG = "app/general/TOGGLE_SETTINGS_DIALOG"

// DIALOG DE CONFIRMATION
const OPEN_CONFIRMATION_DIALOG = "app/general/OPEN_CONFIRMATION_DIALOG"
const CLOSE_CONFIRMATION_DIALOG = "app/general/CLOSE_CONFIRMATION_DIALOG"

// SOCKET
const SET_SOCKET = "app/general/SET_SOCKET"

export default {
	CHANGE_THEME,

	TOGGLE_PROFILE_MENU,

	TOGGLE_SETTINGS_DIALOG,

	OPEN_CONFIRMATION_DIALOG,
	CLOSE_CONFIRMATION_DIALOG,

	SET_SOCKET
}
